// $primaryColor: #0CBCED;
// $secondaryColor: #002631;

// $secondaryOrange: #FF7042;

// $successColor: #00bf61;
// $errorColor: #D50000;

// $scaleColor1000: #00222b;
// $scaleColor900: #1a3840;
// $scaleColor800: #334e55;
// $scaleColor700: #4d646b;
// $scaleColor600: #667a80;
// $scaleColor500: #809195;
// $scaleColor400: #99a7aa;
// $scaleColor300: #b3bdbf;
// $scaleColor200: #ccd3d5;
// $scaleColor100: #e6e9ea;






// // 1
// $primaryColor: #0CBCED;

// // background-image: linear-gradient(to bottom, #0c090a, #0e0a0b, #0f0c0d, #110d0e, #120e0f); //HSV, x - 3, y + 2
// // $secondaryColor: linear-gradient(to bottom, #0c090a, #131111, #191617, #1e1b1c, #242021);
// // $secondaryColor: linear-gradient(to bottom, #0e0c0a, #100d0b, #110f0d, #13100e, #14110f);
// // $secondaryColor: linear-gradient(to bottom, #000105, #000208, #00020a, #00030d, #00030f);
// // $secondaryColor: linear-gradient(to bottom, #04080f, #040911, #050b13, #050c15, #060d17);
// // $secondaryColor: linear-gradient(to bottom, #04080f, #040910, #040911, #050a11, #050a12);
// $secondaryColor: linear-gradient(to bottom, #050a12, #050b13, #050c15, #060c16, #060d17);

// $secondaryOrange: #FF7042;

// $successColor: #00bf61;
// $errorColor: #D50000;

// // $scaleColor1000: #08101c;
// $scaleColor1000: #0a1321;

// $scaleColor900: #1a3840;
// $scaleColor800: #334e55;
// $scaleColor700: #4d646b;
// $scaleColor600: #667a80;
// $scaleColor500: #809195;
// $scaleColor400: #99a7aa;
// $scaleColor300: #b3bdbf;
// $scaleColor200: #ccd3d5;
// $scaleColor100: #e6e9ea;





// 2
// $primaryColor: #0CBCED;
// $primaryColor: #2b2d42;
// $primaryColor: #182184;
// $primaryColor: #ff9b42;
// $primaryColor: #a26769;
// $primaryColor: #4daa57;
// $primaryColor: #923bdf;
// $primaryColor: #3936de;


// $secondaryColor: linear-gradient(to bottom, #ffffff, #fdfdfd, #fbfcfb, #fafafa, #f8f8f8);
// $secondaryColor: linear-gradient(to bottom, #f8f8f8, #fafafa, #fbfcfb, #fdfdfd, #ffffff);

// $scaleColor1000: #F8F8F8;
// // $scaleColor1000: linear-gradient(to bottom, #f5f5f5, #f2f2f2, #eeeeee, #ebebeb, #e8e8e8);
// // $scaleColor900: linear-gradient(to right bottom, #030300, #0b0908, #110f0f, #151414, #181818);
// // $scaleColor900: linear-gradient(to right bottom, #101010, #121212, #141414, #161616, #181818);
// $scaleColor900: linear-gradient(to right bottom, #181818, #1a1a1a, #1c1c1c, #1e1e1e, #202020);
// $scaleColor800: #FCFCFC;
// $scaleColor700: #FBFBFB;
// $scaleColor600: #FAFAFA;
// $scaleColor500: #F9F9F9;
// $scaleColor400: #BBBBBB;
// $scaleColor300: #7D7D7D;
// $scaleColor200: #404040;
// $scaleColor100: #030303;


// $scaleColor1000: #F8F8F8;
// // $scaleColor1000: linear-gradient(to bottom, #f5f5f5, #f2f2f2, #eeeeee, #ebebeb, #e8e8e8);
// // $scaleColor900: linear-gradient(to right bottom, #030300, #0b0908, #110f0f, #151414, #181818);
// // $scaleColor900: linear-gradient(to right bottom, #101010, #121212, #141414, #161616, #181818);
// $scaleColor900: linear-gradient(to right bottom, #181818, #1a1a1a, #1c1c1c, #1e1e1e, #202020);
// $scaleColor800: #FCFCFC;
// $scaleColor700: #FBFBFB;
// $scaleColor600: #FAFAFA;
// $scaleColor500: #F9F9F9;
// $scaleColor400: #BBBBBB;
// $scaleColor300: #7D7D7D;
// $scaleColor200: #404040;
// $scaleColor100: #030303;

// $scaleColor1000: #030303;
// $scaleColor1000: linear-gradient(to right bottom, #030303, #0d0d0d, #141414, #1a1a1a, #202020);
// $scaleColor900: linear-gradient(to right bottom, #101010, #151515, #181818, #1c1c1c, #202020);

// $scaleColor900: linear-gradient(to right bottom, #181818, #1c1c1c, #202020, #242424, #282828);
// $scaleColor900: linear-gradient(to right bottom, #101010, #181818, #202020, #282828, #303030);

// $scaleColor800: #B8B8B8;




// $primaryColor: #3936de;
// $primaryColor: #444E7C;
// $primaryColor: #5098e8;
// $primaryColor: #6A92CA;
// $primaryColor: #5D62BF;
// $primaryColor: #253363;
// $primaryColor: #17bebb;
$primaryColor: #0c57ed;

$secondaryColor: linear-gradient(to bottom, #f8f8f8, #fafafa, #fbfcfb, #fdfdfd, #ffffff);

$scaleColor1000: linear-gradient(to right bottom, #030303, #0d0d0d, #141414, #1a1a1a, #202020);
$scaleColor900: #101010;
$scaleColor800: #282828;
$scaleColor700: #F5F5F5;
$scaleColor600: #F7F7F7;
$scaleColor500: #F8F8F8;
$scaleColor400: #FAFAFA;
$scaleColor300: #FCFCFC;
$scaleColor200: #FDFDFD;
$scaleColor100: #FFFFFF;
// a


$secondaryOrange: #FF7042;

$successColor: #00bf61;
$errorColor: #D50000;

// $backdropGradient: linear-gradient(to bottom, #f5f5f5, #f2f2f2, #eeeeee, #ebebeb, #e8e8e8);
// $backdropGradient: linear-gradient(180deg, #0a4fa1, #05244a);

// $backdropGradient: #231955;
// $backdropGradient: #0a4fa1;
// $backdropGradient: #253363;
$backdropGradient: #05244a;
// $backdropGradient: linear-gradient(180deg, #05244a, #253363);

// gradient colors
// #073063
// #083873
// #093d7d

$gradientColor900: linear-gradient(to right bottom, #101010, #171717, #1c1c1c, #222222, #282828);
// $scaleColor900: #101010;

// $entityBlock: #24262b;
$entityBlock: #141216;

// $secondaryTextColor: #101010;
$secondaryTextColor: #fff;








// 3