@import '../../assets/scss/colors.scss';
@import '../../assets/scss/fonts.scss';

.info-page-container{
  width: 100vw;
  height: 100vh;

  min-width: 400px;
  min-height: 800px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // background-image: url('../../assets/images/bg1.jpg');
  background: $secondaryColor;
  background-size: cover;
}

.info-page{
  width: 75%;
  height: 100%;

  padding-top: 130px;
  padding-bottom: 50px;

  @media(max-width: 600px){
    width: 80%;
  }

  .info-page-header{
    height: 10%;
    min-width: 350px;

    display: flex;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    padding: 0px 30px;

    // background: $scaleColor900;
    background: #124452;
    border: 1px solid $primaryColor;

    // box-shadow: 0 0 10px 10px $secondaryColor;
    box-shadow: 0 0 8px 1px black;
    
    border-radius: 5px;

    h2{
      color: $scaleColor100;
      text-align: center;
      margin: 0;

      // font-family: 'DINPro-Regular';
      font-family: 'DINPro Bold';
    }

  }

  p{
    font-family: 'DINPro-Regular';
  }

  li{
    font-family: 'DINPro-Regular';
  }


  a{
    color: #00BFFF;
    margin-left: 8px;
    text-decoration: underline;
  }
  
}

.info-page-body-wrapper{
  width: 100%;
  height: 90%;
  min-width: 350px;

  box-sizing: border-box;
  padding: 15px 0px;
  overflow: none;

  // margin-top: 20px;

  // background: #124452;
  // border-radius: 10px;

  // border-left: 1px solid $primaryColor;
  // border-right: 1px solid $primaryColor;
  // border-bottom: 1px solid $primaryColor;
  // border-top: 1px solid $primaryColor;

  // box-shadow: 0 0 8px 1px black;
}

.info-page-body{

  position: relative;

  width: 100%;
  height: 90%;

  min-width: 350px;
  padding: 40px 30px 40px 30px;

  margin-top: 20px;

  background: #124452;
  border-radius: 3px;

  border-left: 1px solid $primaryColor;
  border-right: 1px solid $primaryColor;
  border-bottom: 1px solid $primaryColor;
  border-top: 1px solid $primaryColor;

  box-shadow: 0 0 8px 1px black;

  overflow-y: scroll;


  // -webkit-mask: 
  //   linear-gradient(to top,  transparent 2%, $scaleColor900 2% 98%, transparent 98%);
  // -webkit-mask-size:110% 110%;
  // -webkit-mask-position:center;
  // -webkit-mask-repeat:no-repeat;
  // -webkit-mask-composite: source-in;

  // mask: 
  //   linear-gradient(to top,  transparent 2%, $scaleColor900 4% 96%, transparent 96%);
  // mask-size: 110% 110%;
  // mask-position: center;
  // mask-repeat:no-repeat;
  // mask-composite: intersect;

  .top-scollbar-cover{
    position: absolute;

    width: 20px;
    height: 20px;

    top: 0px;
    right: -5px;
    
    border-right: 1px solid $primaryColor;
    border-top: 1px solid $primaryColor;
    border-top-right-radius: 10px;

    background: #124452;
  }
  .bottom-scollbar-cover{
    position: absolute;

    width: 20px;
    height: 20px;

    bottom: 0px;
    right: -5px;
    
    border-right: 1px solid $primaryColor;
    border-bottom: 1px solid $primaryColor;
    border-bottom-right-radius: 10px;
    
    background: #124452;
  }


  p{
    color: $scaleColor100;
    // text-indent: 30px;
    text-indent: 15px;
    font-size: 12px;
    margin-bottom: 30px;
  }

  h2, h3, strong, li{
    color: white;
  }

  ul{
    padding-left: 80px;
    margin-bottom: 40px;
    li{
      font-size: 12px;
    }
  }
}

.info-page-body::-webkit-scrollbar-thumb{
  background: gray;
  border-radius: 10px;
  width: 5px;
}

.info-page-body::-webkit-scrollbar{
  width: 5px;
  background: transparent;
}
