@import '../../assets/scss/colors';
.tab-container.entrants {
    .actions {
        overflow: hidden;
        border-bottom: 1px solid $scaleColor900;
        margin: 0 0 24px;
        @media (max-width: 640px) {
            border: none;
            margin: 0 0 10px;
        }
        > button {
            background: transparent;
            padding: 18px 26px 18px 0;
            color: $secondaryTextColor;
            background-position: left center;
            background-repeat: no-repeat;
            cursor: pointer;
            text-align: left;
            white-space: nowrap;
            // border-bottom: 2px solid $scaleColor900;
            padding-right: 26px;
            margin-right: 14px;
            @media (max-width: 640px) {
                margin-right: 0;
                margin-bottom: 16px;
            }
            &.active {
                border-bottom: 2px solid $primaryColor;
                color: $secondaryTextColor;
            }
        }
    }
    .contest-entrants-listing {
        margin: 0 0 16px;
        .head {
            height: 36px;
            border-radius: 8px;
            background: $scaleColor900;
            padding: 0 16px;
            margin: 0 0 8px;
            @media (max-width: 640px) {
                display: none;
            }
            span {
                width: calc(50% - (132px / 2));
                float: left;
                line-height: 36px;
                text-transform: uppercase;
                color: #fff;
                font-size: 12px;
                font-weight: 400;
                &:last-child {
                    width: 132px;
                }
            }
        }
        .listing {
            margin: 0;
            padding: 0;
            display: block;
            li {
                display: block;
                margin: 0 0 8px;
                background: $scaleColor900;
                padding: 16px;
                border-radius: 12px;
                overflow: hidden;
                position: relative;
                @media (max-width: 640px) {
                    margin: 0 0 16px;
                }
                .entrant {
                    float: left;
                    width: calc(50% - (132px / 2));
                    overflow: hidden;
                    @media (max-width: 640px) {
                        width: 70%;
                        margin-bottom: 12px;
                    }
                    img {
                        max-height: 40px;
                        max-width: 40px;
                        border-radius: 8px;
                        float: left;
                        margin-right: 24px;
                        @media (max-width: 640px) {
                            margin-right: 10px;
                        }
                    }
                    h3 {
                        margin: 12px 0 0;
                        font-size: 16px;
                        line-height: 17px;
                        font-weight: 700;
                        color: #FFF;

                    }
                }
                .entries {
                    float: left;
                    width: calc(50% - (132px / 2));
                    @media (max-width: 640px) {
                        width: 30%;
                      margin: 3px 0;
                        overflow: hidden;
                        text-align: center;
                    }
                    .mobile {
                        display: none;
                        @media (max-width: 640px) {
                            display: block;
                            width: 100%;
                            color: #fff;
                            font-size: 16px;
                            line-height: 17px;
                            font-weight: 400;
                        }
                    }
                    span {
                        height: 40px;
                        line-height: 40px;
                        border-radius: 8px;
                        background: $scaleColor800;
                        display: block;
                        width: 70px;
                        text-align: center;
                        font-size: 14px;
                        font-weight: 600;
                        color: #fff;
                        @media (max-width: 640px) {
                            display: block;
                            width: 100%;
                            height: auto;
                            background: transparent;
                            font-size: 16px;
                            line-height: 17px;
                        }
                    }
                }
                .following {
                    float: left;
                    width: 132px;
                    @media (max-width: 640px) {
                        float: none;
                        width: 100%;
                    }
                    button {
                        display: block;
                        height: 32px;
                        width: 100%;
                        border-radius: 8px;
                        background: transparent;
                        border: 1px solid $scaleColor700;
                        cursor: pointer;
                        color: #fff;
                        font-weight: 600;
                        font-size: 13px;
                        margin-top: 4px;
                        @media (max-width: 640px) {
                            height: 36px;
                            font-size: 15px;
                        }
                        &.active,
                        &:hover {
                            background: $primaryColor;
                            border: 1px solid $primaryColor;
                        }

                    }
                }
            }
        }
    }
}

