@import '../../assets/scss/colors';

.settings-pages {
    background: $backdropGradient;
    background-image: url(../../assets/images/gradient.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top center;
    min-height: 100vh;
    > .inner {
        margin: 0 auto;
        width: 100%;
        max-width: 864px;
        padding: 32px;
        min-height: 60vh;
        h1 {
            font-size: 24px;
            line-height: 32px;
            font-family: "DINPro Bold";
            font-weight: 400;
            color: $secondaryTextColor;
            text-align: center;
            margin: 0 0 36px;
        }
        ul {
            margin: 0;
            padding: 0;
            display: block;
            // border: 1px solid $scaleColor800;
            li {
                display: block;
                height: 50px;
                // border-bottom: 1px solid $scaleColor800;
                &:last-child {
                    border-bottom: 0;
                }
                button {
                    border: 1px solid $scaleColor800;
                    border-radius: 5px;
                    display: block;
                    height: 49px;
                    background: $scaleColor800;
                    color: #fff;
                    cursor: pointer;
                    width: 100%;
                    text-align: left;
                    padding: 0 24px;
                    &:hover {
                        // background: $primaryColor;
                        background: $scaleColor900;
                    }

                }
            }
        }
    }
    .change-password-popup {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 100;
        background: rgba(0, 34, 43, 0.6);
        display: none;
        align-items: center;
        text-align: center;
        &.show {
            display: flex;
        }
        .inner {
            margin: 0 auto;
            padding-bottom: 24px;
            background: $scaleColor800;
            border-radius: 12px;
            border: 1px solid $scaleColor700;
            position: relative;
            width: 100%;
            max-width: 360px;
            h2 {
                margin: 0 0 20px;
                border-bottom: 1px solid $scaleColor700;
                font-size: 18px;
                font-weight: 400;
                color: #fff;
                padding: 20px 24px;
                text-align: left;
            }
            .close-enter-password-modal {
                position: absolute;
                top: 20px;
                right: 24px;
                height: 18px;
                width: 18px;
                background: transparent;
                background-image: url(../../assets/images/svg/close.svg);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                cursor: pointer;
            }
            ul {
                margin: 0;
                padding: 0 24px;
                display: block;
                li {
                    display: block;
                    margin: 0 0 16px;
                    input {
                        display: block;
                        width: 100%;
                        background: transparent;
                        height: 35px;
                        padding: 0 10px;
                        color: #fff;
                        border: 1px solid $scaleColor700;
                    }
                    input::placeholder {
                        opacity: 1;
                        color: #fff;
                    }
                    input.error {
                        border-color: $errorColor;
                    }
                    span.error {
                        color: $errorColor;
                        font-size: 12px;
                        margin-top: 5px;
                        left: 0;
                        display: block;
                        width: 100%;
                    }
                }
            }
            button {

                width: 160px;
                height: 30px;
                border-radius: 8px;
                line-height: 30px;
                background: $primaryColor;
                color: #fff;
                font-size: 12px;
                font-weight: 600;
                padding: 0 12px;
                cursor: pointer;
                text-transform: uppercase;
                &:hover {
                    background: #fff;
                    color: $primaryColor;
                }
            }
        }
    }
}
