@import "../../assets/scss/colors";
.landing-footer {
  padding: 60px 0 0;
  @media (max-width: 765px) {
    padding: 50px 0 0;
  }
  @media (max-width: 564px) {
    padding: 0;
  }
  .inner {
    width: 100%;
    max-width: 1170px;
    padding: 0 15px;
    margin: 0 auto;
    .top {
      overflow: hidden;
      padding-bottom: 30px;
      border-bottom: 1px solid $scaleColor900;

      
      .left {
          float: left;
          @media (max-width: 765px) {
              float: none;
              display: block;
              width: 100%;
              text-align: center;
            }
        img {
            width: 220px;
        }
        p {
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 24px;
          align-items: center;
          text-align: center;
          margin: 0;
          color: $secondaryTextColor;
          @media (max-width: 765px) {
            display: inline-block;
            margin: 0 10px;
          }
          @media (max-width: 564px) {
            display: block;
            padding: 30px 0 0;
          }
        }
        @media (max-width: 765px) {
          a {
            display: inline-block;
            margin: 0 10px;
          }
        }
        @media (max-width: 564px) {
          a {
            display: block;
          }
        }
      }
      .right {
        float: right;
        @media (max-width: 765px) {
          float: none;
          display: block;
          width: 100%;
          text-align: center;
          padding-top: 20px;
        }
        ul {
          overflow: hidden;
          display: block;
          li {
            float: left;
            margin-left: 24px;
            display: inline;
            @media (max-width: 765px) {
              float: none;
              display: inline-block;
            }
            @media (max-width: 564px) {
              display: block;
              border-top: 1px solid #d3d3d326;
              padding: 15px 0;
              margin-left: 0;
              &:last-child {
                border: none;
              }
            }
            > a {
              background: transparent;
              font-style: normal;
              font-weight: normal;
              font-size: 15px;
              line-height: 24px;
              display: flex;
              align-items: center;
              text-align: center;
              text-decoration: underline;
              color: $secondaryTextColor;
              margin-top: 8px;
              cursor: pointer;
              &:hover {
                color: $primaryColor;
              }
              @media (max-width: 564px) {
                display: block;
                text-align: center;
                margin: 0;
              }
            }
            > button {
              background: transparent;
              font-style: normal;
              font-weight: normal;
              font-size: 15px;
              line-height: 24px;
              display: flex;
              align-items: center;
              text-align: center;
              text-decoration: underline;
              color: $scaleColor300;
              margin-top: 8px;
              cursor: pointer;
              &:hover {
                color: $primaryColor;
              }
              @media (max-width: 564px) {
                width: 100%;
                display: block;
                text-align: center;
                margin: 0;
              }
            }
            .socialLinks {
              display: flex;
              align-items: center;
              justify-content: center;
              @media (max-width: 765px) {
                margin-top: 10px;
              }
              a {
                margin-right: 8px;
                display: inline-block;
              }
            }
          }
        }
      }
    }
    .bottom {
      padding: 32px 0;
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: $secondaryTextColor;
        margin: 0;
        a {
          color: $secondaryTextColor;
        }
      }
    }
  }
}
.pdf-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(0, 34, 43, 0.6);
  display: none;
  align-items: center;
  &.show {
    display: flex;
  }
  .inner {
    margin: 0 auto;
    background: $scaleColor800;
    border-radius: 12px;
    border: 1px solid $scaleColor700;
    position: relative;
    width: 100%;
    height: 80vh;
    max-width: 1000px;
    padding: 32px;
    .close-pdf-modal {
      position: absolute;
      top: 10px;
      right: 10px;
      height: 18px;
      width: 18px;
      background: transparent;
      background-image: url(../../assets/images/svg/close.svg);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
      color: transparent;
    }
    iframe {
      width: 100%;
      height: 100%;
      background: #fff;
    }
  }
}
