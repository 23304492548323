@import '../../../../assets/scss/colors.scss';

.landing-page {
    // background: $secondaryColor;
    background: $backdropGradient;
    // background: url("../../assets/images/bg1.jpg") no-repeat;
    // background: url("../../../../assets/images/gradient.jpg") no-repeat;
    background-image: url("../../../../assets/images/gradient.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top center;
    
    .landing-title {
        position:absolute;
        top: 12vh;

        width:100%;
        text-align: center;
        display: flex;
        justify-content: center;
    }
    .promo-vert-logos{
        display: block;
        max-width: 600px;
        // min-width: 600px;
        // min-height: 460px;

        width: 35vw;
        height: 45vh;
        background-image: url('../../../../assets/images/promo-vert-logos.png');
        background-size: contain;
        background-repeat: no-repeat;

        @media(max-width: 1050px){
            display: block;
            width: 500px;
            height: 400px;
        }
        @media(max-width: 1300px){
            margin-top: 15px;
        }
        @media(max-width: 1160px){
            min-width: 500px;
            margin-top: 120px;
            margin-right: 50px;
        }
        @media (max-width: 500px) {
            margin-top: 30px;
        }
        @media (max-height: 500px) {
            margin-top: 10px;
        }
    }
    .promo-vert-logos-mini{
        display: none;
        background-image: url('../../../../assets/images/promo-vert-logos.png');
        background-size: contain;
        background-repeat: no-repeat;
        @media(max-width: 1050px){
            display: block;
            margin-top: 30px;
            height: 250px;
            width: 350px;
        }
        @media(max-width: 500px){
            margin-top: 80px;
            margin-left: 20px;
            height: 200px;
            width: 300px;
        }
        @media(max-height: 500px){
            margin-top: 80px;
            margin-left: 20px;
            height: 200px;
            width: 300px;
        }
    }
    .collab{
        position: relative;
        width: 100%;
        height: 18vh;
        min-height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media(max-width: 1600px){
            margin-top: 20px;
        }
        @media(max-width: 1500px){
            margin-top: 30px;
        }
        @media(max-width: 1400px){
            margin-top: 40px;
        }
        @media(max-width: 1050px){
            display: none;

        }
    }
    .landing-title .collab img {
        margin:auto;
        width:700px;
        height:115px;
        margin-top:30px;
        @media(max-width: 800px){
            margin-top:30px;
            height:65px;
            width:400px;
        }
    }
    .landing-section-1 {
        // height: 100vh;
        // width: 100vw;

        height: 100%;
        width: 100%;

        min-height: 1000px;
        min-width: 400px;

        // background: url("../../../../assets/images/bg1.jpg") no-repeat;
        // background: $secondaryColor;
        background-size: 100% calc(100% + 45px);
        background-position: top -100px center;
        padding-top: 200px;
        overflow: hidden;
        .inner {
            width: 50%;
            min-height: 620px;

            position: relative;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            // padding: 13vh 50px 195px 50px;
            padding: 0 10px 195px 10px;
            margin: 0 auto;
            float: left;
            min-width: 50%;
            max-width: 50%;
            @media (max-width: 1050px) {
                display: block;
                float: none;
                padding: 80px 50px 50px 50px;
                width: 100%;
                max-width: 640px;
                margin-top: 10vh;
                margin-bottom: 100px;
            }
            @media (max-height: 500px) {
                padding-top: 130px;
                margin-top: 0px;
            }

            .subheader{
                width: 100%;
                min-height: 64px;

                top: 0;
                left: 0px;

                margin-bottom: 40px;
                p{
                    color: $primaryColor;
                    font-size: 28px;
                    text-align: center;
                    margin: 0 auto;
                    @media(max-width: 575px){
                        max-width: 300px;
                    }
                }
                @media (max-width: 1050px){
                    min-height: 10px;
                    margin-bottom: 20px;
                }
            }
            h1 {
                max-width: 600px;
                font-size: 56px;
                line-height: 68px;
                text-align: center;
                letter-spacing: -0.03em;
                font-weight: 400;
                font-family: 'DINPro Bold';
                color: #fff;
                margin: 0 auto;
                margin-bottom: 20px;
                @media(max-width: 1300px){
                    font-size: 52px;
                    line-height: 64px;
                }
                @media(max-width: 1220px){
                    font-size: 50px;
                }
                @media (max-width: 600px) {
                    font-size: 48px;
                    line-height: 60px;
                }

            }
            p {
                font-style: normal;
                font-size: 30px;
                line-height: 34px;
                color: #fff;
                font-weight: 700;
                padding-top:10px;
                margin: 10px 0;
                // text-align: center;
            }
            .btns{
                width: 100%;
            }
            .btns ul {
                display: block;
                margin: 0 auto;
                padding: 0;
                text-align: center;
                max-width: 550px;
                overflow: hidden;
                li {
                    padding-right: 5px;
                    padding-left: 5px;
                    float: left;
                    display: inherit;
                    margin-bottom: 15px;
                    width: 50%;
                    padding-top:10px;
                    &:first-child {
                        width: 100%;
                        a {
                            background: $primaryColor;
                            &:hover {
                                background: transparent;
                            }
                        }
                    }
                    a {
                        width: 100%;
                        border: 1px solid $scaleColor800;
                        box-sizing: border-box;
                        border-radius: 12px;
                        display: flex;
                        min-height: 74px;
                        align-items: center;
                        text-align: center;
                        color: #fff;
                        justify-content: center;
                        &:hover {
                            text-decoration: none;
                            background: $primaryColor;
                        }
                        span {
                            margin-left: 10px;
                            text-align: left;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 20px;
                            small {
                                display: block;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 12px;
                                color: $scaleColor200;
                            }
                        }
                    }
                }
            }
        }
        .landing-slider {
            max-width: 1170px;
            // height: 70vh;
            height: 50vh;


            // margin: 0px auto 100px auto;
            // margin-top: 60px;
            // padding: 1vh 0px 4vh 30px;
            padding: 60px 0 0 30px;
            box-sizing: border-box;
            
            float: left;
            min-height: 620px;
            
            width: 50%;
            min-width: 50%;
            max-width: 50%;

            display: flex;
            justify-content: center;
            align-items: center;
            // @media (min-width: 1500px) {
            //     padding: 1vh 0px calc(4vh + 20px) 30px;
            // }
            // @media (min-width: 1650px) {
            //     padding: 1vh 0px calc(4vh + 40px) 30px;
            // }
            // @media (min-width: 1750px) {
            //     padding: 1vh 0px calc(4vh + 60px) 30px;
            // }
            @media (max-width: 1050px) {
                display: none;
            }
            // @media (max-width: 765px) {
            // }
            > div {
                width: 100%;
                height: 100%;
                margin: 0 auto;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

               
                ul {
                    display: block;
                    position: relative;
                    margin: 0 auto;
                    padding: 10px 0px 0px 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    @media (max-width:1200px){
                        padding-top:0px;
                        width: 100%;
                        height: 100%;
                    }
                    .esports-background-image{
                        // width: 800px;
                        width: 100%;
                        height: 100%;
                        background-image: url('../../../../assets/images/esports-3.jpg');
                        // object-fit: contain;
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                    img{
                        // width: auto;
                        // height: 100%;
                        width: 100%;
                        height: 100%;
                        border-radius: 5px;
                    @media (max-width:1200px){
                        width: auto;
                        height: 100%;
                    }
                    }
                    li {
                        position: absolute;
                        display: block;
                        width: 524px;
                        text-align: center;
                        transition: 0.2s all linear;
                        @media (max-width: 765px) {
                            width: 424px;
                        }
                        @media (max-width: 564px) {
                            width: 360px;
                        }
                        @media (max-width: 400px) {
                            width: 300px;
                        }
                        &.active {
                            left: 0;
                            transition: 0.2s all linear;
                        }
                        &.next {
                            left: 100%;
                            transition: 0.2s all linear;
                        }
                        &.prev {
                            left: -100%;
                            transition: 0.2s all linear;
                        }
                        @media (max-width: 400px) {
                            img {
                                display: block;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        > img {
            display: block;
            max-width: 100%;
            height: auto;
        }
        .nfl {
            position: absolute;
            top: 20%;
            right: 15%;
            @media (max-width: 564px) {
                width: 56px;
                right: auto;
                top: 0;
                left: -5px;
            }
        }
        .nba {
            position: absolute;
            left: 0;
            top :40%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            @media (max-width: 765px) {
                top: 30%;
            }
            @media (max-width: 564px) {
                width: 64px;
                left: auto;
                right: 10px;
                top: auto;
                bottom: 90px;
            }
        }
        .dota {
            position: absolute;
            bottom: 20%;
            right: 0;
            @media (max-width: 564px) {
                width: 48px;
                right: auto;
                bottom: 0;
                left: -4px;
            }
        }
    }
    .landing-section-2 {
        padding: 0 0 60px;
        clear: both;
        .inner {
            width: 100%;
            max-width: 1170px;
            padding: 0 15px;
            margin: 0 auto;
            text-align: center;
            h2 {
                font-family: 'DINPro Bold';
                font-style: normal;
                font-weight: 400;
                font-size: 40px;
                line-height: 52px;
                letter-spacing: -0.02em;
                color: #FFFFFF;
            }
            ul {
                overflow: hidden;
                display: block;
                margin: 0;
                padding: 50px 0 100px;
                @media (max-width: 765px) {
                    padding-top: 0;
                }
                li {
                    float: left;
                    width: 33.33333%;
                    display: inline;
                    padding: 0 45px;
                    @media (max-width: 765px) {
                        float: none;
                        width: 100%;
                        display: block;
                        margin: 0 0 20px;
                    }
                    div {
                        min-height: 153px;
                        display: flex;
                        justify-content: center;
                        align-items: flex-end;
                        margin-bottom: 30px;
                        img {
                            display: block;
                        }
                    }
                    h3 {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 32px;
                        text-align: center;
                        color: $secondaryTextColor;
                        margin-top: 15px;
                        font-family: 'DINPro Bold';
                    }
                    p {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 26px;
                        text-align: center;
                        color: $secondaryTextColor;
                        margin-top: 15px;
                    }
                }
            }
            > p {
                font-size: 18px;
                color: #fff;
                line-height: 1.5;
                font-style: italic;
            }
        }
    }
    .landing-section-3 {
        // background: url(../../../../assets/images/bg2.png) no-repeat top;
        background: $secondaryColor;
        background-size: 100% 100%;
        padding: 50px 0 100px;
        @media (max-width: 765px) {
            padding-top: 0;
            height: auto;
        }
        @media (max-width: 564px) {
            overflow: hidden;
            padding-bottom: 100px;
        }
        .inner {
            width: 100%;
            max-width: 1170px;
            padding: 0 15px;
            margin: 0 auto;
            text-align: center;
            @media (max-width: 564px) {
                padding: 0;
            }
            h2 {
                font-style: normal;
                font-weight: 400;
                font-size: 40px;
                line-height: 52px;
                align-items: center;
                letter-spacing: -0.02em;
                color: #FFFFFF;
                font-family: 'DINPro Bold';
            }
            p {
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 30px;
                text-align: center;
                color: #FFFFFF;
                opacity: 0.8;
                margin: 0px 0px 15px 0px;

                @media (max-width: 564px) {
                    padding:  0 15px;
                }
            }
            .btns ul {
                display: block;
                margin: 0;
                padding: 0;
                text-align: center;
                @media (max-width: 564px) {
                    padding:  0 15px;
                }
                li {
                    display: inline-block;
                    padding-right: 5px;
                    padding-left: 5px;
                    @media (max-width: 564px) {
                        margin-bottom: 15px;
                    }
                    a {
                        border: 1px solid $scaleColor800;
                        box-sizing: border-box;
                        border-radius: 12px;
                        display: flex;
                        min-height: 64px;
                        align-items: center;
                        text-align: center;
                        width: 165px;
                        color: #fff;
                        justify-content: center;
                        &:hover {
                            text-decoration: none;
                            background: $primaryColor;
                        }
                        span {
                            margin-left: 10px;
                            text-align: left;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 15px;
                            small {
                                display: block;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 12px;
                                color: $scaleColor200;
                            }
                        }
                    }
                }
            }
            .img {
                padding-top: 70px;
                @media (max-width: 564px) {
                    padding-left: 30px;
                }
                img {
                    display: block;
                    max-width: 100%;
                    margin: 0 auto;
                    &.showOnMobile {
                        display: none;
                    }
                    @media (max-width: 564px) {
                        &.hideOnMobile {
                            display: none;
                        }
                        &.showOnMobile {
                            display: block;
                            width: 100%;

                        }
                    }
                }
            }
        }
    }
}
