@import "../../../../assets/scss/colors.scss";

.sweepstakes-page {
  background-image: url("../../../../assets/images/aria-exterior.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: relative;

  .wrapper {
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    min-height: var(--viewport-height);
    position: relative;
    z-index: 2;
    overflow: hidden;
    padding: 0 4.125rem 0 0;
  }
  .outer-container {
    display: flex;
    // position: relative;
    width: 80%;
    margin-left: 11%;
    z-index: 1;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    text-align: left;
    background: rgba(97, 187, 237, 0.91);
    transition: opacity 1s ease 0s, transform 1s ease 0s;
    @media screen and (max-height: 1000px) and (max-width: 500px) {
      width: 95%;
    }
    @media screen and (max-width: 1100px) and (min-height: 750px) {
      width: 85%;
    }
    @media screen and (min-height: 900px) and (max-width: 500px) {
      width: 95%;
    }
    @media screen and (min-width: 1000px) and (max-height: 700px) {
      width: 85%;
    }
    @media screen and (max-width: 1000px) and (max-height: 500px) {
      width: 87%;
    }
    @media screen and (max-width: 800px) and (max-height: 500px) {
      width: 90%;
    }
    @media screen and (max-width: 750px) and (max-height: 450px) {
      width: 88%;
    }
    @media screen and (max-width: 700px) and (max-height: 400px) {
      width: 90%;
    }
    @media screen and (max-width: 420px) and (max-height: 980px) {
      width: 95%;
    }
    @media screen and (min-width: 1200px) and (max-height: 800px) {
      width: 82%;
    }
    @media screen and (min-width: 1300px) and (max-height: 1100px) {
      width: 83%;
    }
    @media screen and (height: 1024px) and (width: 768px) {
      width: 88%;
    }
    @media screen and (width: 540px) and (height: 720px) {
      width: 90%;
    }
    @media screen and (width: 720px) and (height: 540px) {
      width: 88%;
    }
    @media screen and (width: 1114px) and (height: 720px) {
      width: 84%;
    }
    @media screen and (height: 1114px) and (width: 720px) {
      width: 88%;
    }
    @media screen and (width: 512px) and (height: 717px) {
      width: 90%;
    }
    @media screen and (width: 717px) and (height: 512px) {
      width: 88%;
    }
    .inner-container {
      position: relative;
      z-index: 1;
      border-radius: inherit;
      padding: 5.5rem 4.375rem;
      max-width: 100%;
      width: 53rem;
      @media screen and (max-height: 1000px) and (max-width: 500px) {
        margin-left: 15%;
      }
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        @media screen and (max-height: 1000px) and (max-width: 500px) {
          margin-left: -15%;
        }
      }
    }
    h1 {
      text-align: center;
      text-transform: uppercase;
      color: $secondaryColor;
      position: relative;
      font-family: "Public Sans", sans-serif;
      letter-spacing: 0.5rem;
      width: calc(100% + 0.5rem);
      font-size: 2.85em;
      line-height: 1;
      font-weight: 500;
      text-shadow: 0rem 0rem 1.3125rem #ffffff;
      margin-top: -4%;
      margin-bottom: 7%;
      @media screen and (min-width: 500px) {
        font-size: 25px;
      }
      @media screen and (width: 540px) and (height: 720px) {
        font-size: 14px;
      }
      @media screen and (width: 568px) and (height: 320px) {
        font-size: 11px;
      }
      @media screen and (width: 640px) and (height: 360px) {
        margin-left: 0%;
        font-size: 10px;
      }
      @media screen and (width: 653px) and (height: 280px) {
        font-size: 15px;
      }
      @media screen and (width: 667px) and (height: 375px) {
        font-size: 11px;
      }
      @media screen and (width: 720px) and (height: 540px) {
        font-size: 15px;
      }
      @media screen and (width: 731px) and (height: 411px) {
        font-size: 20px;
      }
      @media screen and (width: 736px) and (height: 414px) {
        font-size: 20px;
      }
      @media screen and (height: 1024px) and (width: 768px) {
        font-size: 32px;
      }
      @media screen and (width: 812px) and (height: 375px) {
        font-size: 20px;
      }
      @media screen and (width: 823px) and (height: 411px) {
        font-size: 20px;
      }
      @media screen and (width: 926px) and (height: 428px) {
        margin-left: 0%;
        font-size: 25px;
      }
      @media screen and (width: 1024px) and (height: 600px) {
        font-size: 30px;
      }
      @media screen and (width: 1024px) and (height: 768px) {
        font-size: 40px;
      }
      @media screen and (width: 1114px) and (height: 720px) {
        font-size: 30px;
      }
      @media screen and (max-height: 1000px) and (max-width: 500px) {
        font-size: 10px;
        margin-left: -110%;
        width: 300%;
      }
      @media screen and (width: 512px) and (height: 717px) {
        font-size: 20px;
      }
      @media screen and (width: 717px) and (height: 512px) {
        font-size: 20px;
      }
    }

    h3 {
      position: relative;
      @media screen and (min-width: 500px) {
        font-size: 20px;
      }
      @media screen and (width: 540px) and (height: 720px) {
        font-size: 13px;
      }
      @media screen and (width: 568px) and (height: 320px) {
        font-size: 11px;
      }
      @media screen and (width: 640px) and (height: 360px) {
        margin-left: 0%;
        font-size: 10px;
      }
      @media screen and (width: 653px) and (height: 280px) {
        font-size: 12px;
      }
      @media screen and (width: 667px) and (height: 375px) {
        font-size: 11px;
      }
      @media screen and (width: 720px) and (height: 540px) {
        font-size: 15px;
      }
      @media screen and (width: 731px) and (height: 411px) {
        font-size: 20px;
      }
      @media screen and (width: 736px) and (height: 414px) {
        font-size: 18px;
      }
      @media screen and (width: 812px) and (height: 375px) {
        font-size: 20px;
      }
      @media screen and (width: 823px) and (height: 411px) {
        font-size: 20px;
      }
      @media screen and (width: 926px) and (height: 428px) {
        margin-left: 0%;
      }
      @media screen and (width: 1024px) and (height: 768px) {
        font-size: 35px;
      }
      @media screen and (max-height: 1000px) and (max-width: 500px) {
        font-size: 14px;
        margin-left: -40%;
      }
      @media screen and (width: 512px) and (height: 717px) {
        margin-left: -25%;
        font-size: 15px;
      }
      @media screen and (width: 717px) and (height: 512px) {
        font-size: 18px;
      }
    }

    h5 {
      position: relative;
      margin-left: 5%;
      margin-bottom: 2%;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.612);
      font-family: "Roboto", sans-serif;
      font-weight: bold;
      letter-spacing: -0.025rem;
      font-size: 0.875em;
      line-height: 1;
      font-weight: 300;
      @media screen and (min-width: 500px) {
        font-size: 12px;
      }
      @media screen and (width: 540px) and (height: 720px) {
        font-size: 12px;
      }
      @media screen and (width: 568px) and (height: 320px) {
        margin-left: 10%;
        font-size: 8px;
      }
      @media screen and (width: 640px) and (height: 360px) {
        margin-left: 10%;
        font-size: 9px;
      }
      @media screen and (width: 653px) and (height: 280px) {
        margin-left: 10%;
        font-size: 8px;
      }
      @media screen and (width: 667px) and (height: 375px) {
        margin-left: 10%;
        font-size: 10px;
      }
      @media screen and (width: 720px) and (height: 540px) {
        margin-left: 10%;
        font-size: 10px;
      }
      @media screen and (width: 731px) and (height: 411px) {
        margin-left: 10%;
        font-size: 12px;
      }
      @media screen and (width: 736px) and (height: 414px) {
        margin-left: 10%;
        font-size: 12px;
      }
      @media screen and (height: 1024px) and (width: 768px) {
        margin-left: 10%;
        font-size: 12px;
      }
      @media screen and (width: 812px) and (height: 375px) {
        margin-left: 10%;
        font-size: 12px;
      }
      @media screen and (width: 823px) and (height: 411px) {
        margin-left: 5%;
        font-size: 14px;
      }
      @media screen and (width: 926px) and (height: 428px) {
        margin-left: 10%;
        font-size: 12px;
      }
      @media screen and (width: 1024px) and (height: 768px) {
        margin-left: 10%;
        font-size: 15px;
      }
      @media screen and (max-height: 1000px) and (max-width: 500px) {
        margin-left: -20%;
        font-size: 12px;
      }
      @media screen and (min-height: 1300px) and (max-width: 1100px) {
        font-size: 16px;
      }
      @media screen and (min-width: 1300px) and (max-height: 1100px) {
        font-size: 16px;
      }
      @media screen and (width: 512px) and (height: 717px) {
        font-size: 11px;
      }
      @media screen and (width: 717px) and (height: 512px) {
        font-size: 12px;
      }
    }
  }
  li {
    position: relative;
    font-size: 20px;
    @media screen and (min-width: 500px) {
      font-size: 16px;
    }
    @media screen and (width: 540px) and (height: 720px) {
      font-size: 14px;
    }
    @media screen and (width: 568px) and (height: 320px) {
      margin-left: 0%;
      font-size: 8px;
    }
    @media screen and (width: 640px) and (height: 360px) {
      margin-left: 0%;
      font-size: 9px;
    }
    @media screen and (width: 653px) and (height: 280px) {
      margin-left: 0%;
      font-size: 10px;
    }
    @media screen and (width: 667px) and (height: 375px) {
      margin-left: 0%;
      font-size: 11px;
    }
    @media screen and (width: 720px) and (height: 540px) {
      margin-left: 0%;
      font-size: 10px;
    }
    @media screen and (min-height: 700px) and (max-height: 420px) {
      margin-left: 0%;
      font-size: 10px;
    }
    @media screen and (width: 926px) and (height: 428px) {
      margin-left: 0%;
      font-size: 15px;
    }
    @media screen and (width: 1024px) and (height: 600px) {
      font-size: 15px;
    }
    @media screen and (width: 1024px) and (height: 768px) {
      margin-left: 0%;
      font-size: 18px;
    }
    @media screen and (max-height: 1000px) and (max-width: 500px) {
      font-size: 15px;
      margin-left: -30%;
    }
    @media screen and (height: 1024px) and (width: 768px) {
      font-size: 14px;
    }
    @media screen and (width: 512px) and (height: 717px) {
      font-size: 14px;
    }
    @media screen and (width: 717px) and (height: 512px) {
      font-size: 12px;
    }
  }
  .socials {
    position: relative;
    margin-left: 21%;
    @media screen and (max-height: 1000px) and (max-width: 500px) {
      width: 200%;
    }
    @media screen and (min-height: 1000px) and (max-width: 1400px) {
      margin-left: 20%;
    }
    @media screen and (width: 540px) and (height: 720px) {
      margin-left: 10%;
      width: 100%;
    }
    button {
      position: relative;
      background: transparent;
      color: white;
      width: 40px;
      height: 40px;
      border-radius: 100px;
      display: inline-block;
      margin: 5%;
      font-size: 16px;
      @media screen and (min-width: 500px) {
        height: 35px;
        width: 35px;
      }
      @media screen and (width: 540px) and (height: 720px) {
        margin-left: -8%;
        margin-right: 17%;
        height: 35px;
        width: 35px;
      }
      @media screen and (width: 568px) and (height: 320px) {
        margin-left: 0%;
        height: 25px;
        width: 25px;
      }
      @media screen and (width: 640px) and (height: 360px) {
        width: 15%;
        margin: 0 auto;
        margin-left: 1%;
      }
      @media screen and (width: 653px) and (height: 280px) {
        margin-left: 2%;
        height: 25px;
        width: 25px;
      }
      @media screen and (width: 667px) and (height: 375px) {
        margin-left: 3%;
        height: 25px;
        width: 25px;
      }
      @media screen and (width: 720px) and (height: 540px) {
        margin-left: -1%;
      }
      @media screen and (width: 731px) and (height: 411px) {
        margin-left: -1%;
      }
      @media screen and (width: 736px) and (height: 414px) {
        margin-left: -1%;
      }
      @media screen and (height: 1024px) and (width: 768px) {
        margin-left: 1%;
      }
      @media screen and (width: 812px) and (height: 375px) {
        margin-left: 1%;
      }
      @media screen and (width: 823px) and (height: 411px) {
        margin-left: 1%;
      }
      @media screen and (width: 926px) and (height: 428px) {
        margin-left: 2%;
      }
      @media screen and (width: 1024px) and (height: 768px) {
        margin-left: 3%;
      }
      @media screen and (max-height: 1000px) and (max-width: 500px) {
        margin-left: -23%;
        margin-right: 35%;
        height: 20px;
        width: 20px;
      }
      @media screen and (height: 1114px) and (width: 720px) {
        width: 12%;
        margin-left: 6%;
        margin-right: -3%;
      }
      @media screen and (width: 512px) and (height: 717px) {
        height: 30px;
        width: 30px;
        margin-left: -15%;
        margin-right: 22%;
      }
      @media screen and (width: 717px) and (height: 512px) {
        height: 35px;
        width: 35px;
        margin-left: -9%;
        margin-right: 22%;
      }
    }
  }
  .sweepstakes {
    position: relative;
    margin-left: 10%;
    @media screen and (min-width: 500px) {
      width: 100%;
      margin-left: 5%;
    }
    @media screen and (max-height: 1000px) and (max-width: 500px) {
      width: 200%;
    }
    button {
      display: relative;
      background: #045269;
      color: #ffffff;
      border-radius: 15px;
      width: 40%;
      height: 40px;
      margin: 10px;
      font-family: "Allerta Stencil", sans-serif;
      text-transform: uppercase;
      @media screen and (width: 540px) and (height: 720px) {
        font-size: 8px;
        height: 30px;
        margin-left: -3%;
      }
      @media screen and (width: 568px) and (height: 320px) {
        margin-left: -1%;
        font-size: 8px;
        height: 30px;
      }
      @media screen and (width: 640px) and (height: 360px) {
        margin-left: 0%;
        font-size: 10px;
      }
      @media screen and (width: 653px) and (height: 280px) {
        font-size: 10px;
        height: 30px;
        margin-left: -1%;
      }
      @media screen and (width: 667px) and (height: 375px) {
        margin-left: 0%;
        font-size: 8px;
        height: 30px;
      }
      @media screen and (width: 720px) and (height: 540px) {
        font-size: 10px;
        height: 30px;
        margin-left: -1%;
      }
      @media screen and (width: 731px) and (height: 411px) {
        margin-left: 0%;
        font-size: 12px;
      }
      @media screen and (width: 736px) and (height: 414px) {
        margin-left: 0%;
        font-size: 12px;
      }
      @media screen and (width: 812px) and (height: 375px) {
        margin-left: 0%;
        font-size: 12px;
      }
      @media screen and (width: 823px) and (height: 411px) {
        margin-left: 0%;
        font-size: 12px;
      }
      @media screen and (width: 926px) and (height: 428px) {
        margin-left: 0%;
      }
      @media screen and (max-height: 1000px) and (max-width: 500px) {
        font-size: 6px;
        height: 30px;
        width: 90px;
        margin-left: -14%;
        margin-right: 20%;
      }
      @media screen and (width: 1024px) and (height: 768px) {
        margin-left: 0%;
      }
      @media screen and (min-height: 1000px) and (max-width: 800px) {
        margin-left: 0%;
      }
      @media screen and (width: 512px) and (height: 717px) {
        margin-left: -1%;
        font-size: 8px;
        height: 25px;
      }
      @media screen and (width: 717px) and (height: 512px) {
        margin-left: 1%;
      }
    }
  }
  .play {
    background-image: url(../../../../../src/assets/images/playIcon.png);
    background-size: 90%;
    background-position: center;
    background-repeat: no-repeat;
  }
  .instagram {
    background-image: url(../../../../../src/assets/images/IG.png);
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
  }
  .tiktok {
    background-image: url(../../../../../src/assets/images/tiktokIcon.png);
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
  .twitter {
    background-image: url(../../../../../src/assets/images/twitterIcon.png);
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
  }

  .container {
    position: relative;
    margin: 0 auto;
    text-align: center;
    font-family: "Public Sans", sans-serif;
    color: white;
    margin-left: 2%;
    @media screen and (max-width: 1000px) {
      width: 200%;
      margin-left: 7%;
    }
    @media screen and (max-width: 800px) {
      width: 200%;
      margin-left: -22%;
    }
    @media screen and (min-width: 500px) {
      width: 100%;
    }
    @media screen and (max-width: 1300px) {
      margin-left: 0%;
    }
    @media screen and (max-height: 1000px) and (max-width: 500px) {
      width: 200%;
      margin-left: 2%;
    }
    @media screen and (min-height: 1000px) and (max-width: 1400px) {
      width: 200%;
      margin-left: 7%;
    }
    @media screen and (min-height: 900px) and (max-width: 450px) {
      width: 200%;
    }
    @media screen and (min-width: 1000px) and (max-height: 800px) {
      width: 200%;
      margin-left: 0%;
    }
    @media screen and (min-width: 1000px) and (max-height: 1400px) {
      width: 200%;
      margin-left: 6%;
    }
    @media screen and (min-width: 1000px) and (max-height: 800px) {
      width: 200%;
      margin-left: 0%;
    }
    @media screen and (min-width: 1000px) and (max-height: 600px) {
      width: 100%;
      margin-left: 1%;
    }
    @media screen and (min-width: 1200px) and (max-height: 800px) {
      width: 100%;
      margin-left: 0%;
    }
    @media screen and (width: 540px) and (height: 720px) {
      width: 100%;
      margin-left: 0%;
    }
    @media screen and (width: 1114px) and (height: 720px) {
      width: 200%;
      margin-left: 4%;
    }
    @media screen and (height: 1114px) and (width: 720px) {
      width: 100%;
    }
    @media screen and (width: 512px) and (height: 717px) {
      width: 200%;
    }
    @media screen and (width: 717px) and (height: 512px) {
      width: 200%;
    }
    @media screen and (width: 1024px) and (height: 1366px) {
      margin-left: 15%;
    }
    @media screen and (width: 1366px) and (height: 1024px) {
      margin-left: 15%;
    }

    #headline {
      margin-top: 10%;
      margin-bottom: -2%;
      color: white;
      white-space: nowrap;
      position: relative;
      margin-left: -2%;
      // @media screen and (min-width: 500px) {
      //   font-size: 20px;
      //   margin-left: 10%;
      // }
      // @media screen and (max-width: 1000px) {
      //   margin-left: -18%;
      // }
      @media screen and (min-width: 700px) and (max-height: 550px) {
        font-size: 14px;
      }
      @media screen and (min-width: 800px) and (max-height: 430px) {
        font-size: 15px;
      }
      @media screen and (max-height: 750px) and (max-width: 430px) {
        font-size: 13px;
        margin-left: -90%;
      }
      @media screen and (max-height: 850px) and (max-width: 400px) {
        margin-left: -20%;
      }
      @media screen and (max-height: 900px) and (max-width: 450px) {
        margin-left: -90%;
        font-size: 13px;
      }
      @media screen and (max-height: 910px) and (max-width: 420px) {
        margin-left: -91%;
      }
      @media screen and (min-height: 920px) and (max-width: 500px) {
        margin-left: -86%;
        font-size: 10px;
      }
      @media screen and (min-height: 1000px) and (max-width: 1400px) {
        margin-left: -15%;
      }
      @media screen and (min-width: 1000px) and (max-height: 1000px) {
        margin-left: 10%;
      }
      @media screen and (min-width: 1000px) and (max-height: 800px) {
        margin-left: -7%;
      }
      @media screen and (min-width: 1000px) and (max-height: 600px) {
        margin-left: 0%;
      }
      @media screen and (min-width: 1050px) {
        margin-left: 14%;
      }
      @media screen and (min-width: 1200px) and (max-height: 800px) {
        margin-left: 2%;
      }
      @media screen and (min-width: 1200px) and (max-height: 1000px) {
        margin-left: -23%;
      }
      @media screen and (min-width: 1200px) and (max-height: 950px) {
        margin-left: 10%;
      }
      @media screen and (min-width: 1300px) and (max-height: 1100px) {
        margin-left: -28%;
      }
      @media screen and (max-width: 420px) and (max-height: 980px) {
        margin-left: -90%;
      }
      @media screen and (width: 540px) and (height: 720px) {
        font-size: 10px;
      }
      @media screen and (width: 568px) and (height: 320px) {
        white-space: nowrap;
        font-size: 12px;
      }
      @media screen and (width: 640px) and (height: 360px) {
        white-space: nowrap;
        font-size: 9px;
      }
      @media screen and (width: 653px) and (height: 280px) {
        white-space: nowrap;
        font-size: 15px;
      }
      @media screen and (width: 667px) and (height: 375px) {
        white-space: nowrap;
        font-size: 10px;
      }
      @media screen and (height: 1024px) and (width: 768px) {
        white-space: nowrap;
        margin-left: -20%;
        font-size: 30px;
      }
      @media screen and (width: 1114px) and (height: 720px) {
        margin-left: 8%;
      }
      @media screen and (height: 1114px) and (width: 720px) {
        margin-left: -4%;
        font-size: 20px;
      }
      @media screen and (width: 512px) and (height: 717px) {
        margin-left: -26%;
        font-size: 15px;
      }
      @media screen and (width: 717px) and (height: 512px) {
        font-size: 15px;
        margin-left: -10%;
      }
    }

    li {
      position: relative;
      display: inline-block;
      font-size: 1.5em;
      list-style-type: none;
      padding: 1.5em;
      text-transform: uppercase;
      // @media screen and (min-width: 500px) {
      //   width: 25%;
      //   font-size: 15px;
      //   margin-left: -7%;
      // }
      @media screen and (max-width: 1000px) {
        width: 25%;
        font-size: 20px;
      }
      @media screen and (min-height: 1000px) and (max-width: 1400px) {
        margin-left: -10%;
        width: 25%;
        margin-right: 1%;
      }
      @media screen and (max-height: 1000px) and (max-width: 500px) {
        width: 30%;
        font-size: 10px;
        margin: 0 auto;
        margin-left: -20%;
        margin-right: 5%;
      }
      @media screen and (width: 540px) and (height: 720px) {
        width: 25%;
        font-size: 10px;
      }
      @media screen and (width: 568px) and (height: 320px) {
        width: 25%;
        font-size: 12px;
      }
      @media screen and (width: 640px) and (height: 360px) {
        width: 25%;
        font-size: 15px;
      }
      @media screen and (width: 653px) and (height: 280px) {
        width: 25%;
        font-size: 12px;
      }
      @media screen and (width: 667px) and (height: 375px) {
        width: 25%;
        font-size: 10px;
      }
      @media screen and (width: 720px) and (height: 540px) {
        width: 25%;
        font-size: 15px;
      }
      @media screen and (width: 731px) and (height: 411px) {
        width: 25%;
        font-size: 15px;
      }
      @media screen and (width: 736px) and (height: 414px) {
        width: 25%;
        font-size: 10px;
      }
      @media screen and (width: 812px) and (height: 375px) {
        width: 25%;
        font-size: 15px;
      }
      @media screen and (width: 823px) and (height: 411px) {
        width: 25%;
        font-size: 15px;
      }
      @media screen and (width: 926px) and (height: 428px) {
        width: 25%;
        font-size: 20px;
      }
      @media screen and (width: 1024px) and (height: 600px) {
        width: 25%;
        font-size: 20px;
      }
      @media screen and (width: 1280px) and (height: 800px) {
        width: 25%;
        font-size: 30px;
      }
      @media screen and (height: 1024px) and (width: 768px) {
        width: 25%;
        font-size: 20px;
        margin-left: -9%;
      }
      @media screen and (height: 1114px) and (width: 720px) {
        width: 30%;
        font-size: 15px;
        margin-left: -6%;
      }
      @media screen and (width: 512px) and (height: 717px) {
        width: 35%;
        font-size: 10px;
        margin-left: -19%;
        // margin-right: 6%;
      }
      @media screen and (width: 717px) and (height: 512px) {
        width: 25%;
        font-size: 15px;
        margin-left: -5%;
        margin-right: 2%;
      }
    }
    .nav {
      margin-left: -2%;
      @media screen and (max-height: 1000px) and (max-width: 500px) {
        width: 100%;
        margin-left: 0%;
      }
    }
    li span {
      display: block;
      position: relative;
      font-size: 4.5rem;
      @media screen and (max-width: 1000px) {
        font-size: 25px;
      }
      @media screen and (max-height: 1000px) and (max-width: 500px) {
        width: 50%;
        font-size: 12px;
        margin-left: 20%;
      }
      @media screen and (width: 540px) and (height: 720px) {
        font-size: 14px;
        margin-left: 20%;
      }
      @media screen and (width: 568px) and (height: 320px) {
        width: 25%;
        font-size: 20px;
        margin-left: 20%;
      }
      @media screen and (width: 640px) and (height: 360px) {
        width: 25%;
        font-size: 25px;
        margin-left: 20%;
        margin-bottom: 10%;
      }
      @media screen and (width: 653px) and (height: 280px) {
        width: 25%;
        font-size: 20px;
        margin-left: 20%;
      }
      @media screen and (width: 667px) and (height: 375px) {
        width: 25%;
        font-size: 20px;
        margin-left: 20%;
      }
      @media screen and (width: 720px) and (height: 540px) {
        width: 25%;
        font-size: 15px;
        margin-left: 20%;
      }
      @media screen and (width: 731px) and (height: 411px) {
        width: 25%;
        font-size: 25px;
        margin-left: 20%;
      }
      @media screen and (width: 736px) and (height: 414px) {
        width: 25%;
        font-size: 20px;
        margin-left: 35%;
      }
      @media screen and (width: 812px) and (height: 375px) {
        width: 25%;
        font-size: 20px;
        margin-left: 20%;
      }
      @media screen and (width: 823px) and (height: 411px) {
        width: 25%;
        font-size: 20px;
        margin-left: 20%;
      }
      @media screen and (width: 926px) and (height: 428px) {
        width: 25%;
        font-size: 25px;
        margin-left: 20%;
      }
      @media screen and (width: 1024px) and (height: 600px) {
        width: 25%;
        font-size: 40px;
        margin-left: 20%;
      }
      @media screen and (width: 1280px) and (height: 800px) {
        width: 25%;
        font-size: 40px;
        margin-left: 20%;
      }
      @media screen and (height: 1024px) and (width: 768px) {
        width: 25%;
        font-size: 30px;
        margin-left: 30%;
      }
      @media screen and (height: 1114px) and (width: 720px) {
        width: 30%;
        font-size: 40px;
        margin-left: 21%;
      }
      @media screen and (width: 512px) and (height: 717px) {
        width: 25%;
        font-size: 20px;
        margin-left: 35%;
      }
      @media screen and (width: 717px) and (height: 512px) {
        width: 25%;
        font-size: 20px;
        margin-left: 35%;
      }
    }
  }
}
