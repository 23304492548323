@import '../../assets/scss/colors';
.my-props-upcoming {
    background: $backdropGradient;
    background-image: url(../../assets/images/gradient.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top center;
    min-height: 100vh;
    > .inner {
        margin: 0 auto;
        width: 100%;
        max-width: 1280px;
        padding: 32px;
        @media (max-width: 600px) {
            padding: 32px 24px;
        }
        &:after {
            display: block;
            clear: both;
            content: "";
        }
        .no-results {
            text-align: center;
            color: #fff;
            background: transparent;
            > div {
                background-image: url(../../assets/images/not-found.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                height: 160px;
                width: 173px;
                margin: 0 auto 30px;
            }
            h3 {
                font-size: 24px;
                font-family: 'DINPro Bold';
                font-weight: 400;
                line-height: 32px;
                color: $secondaryTextColor;
            }
            span {
                font-size: 16px;
                font-weight: 400;
                color: $scaleColor200;
            }
            a {
                height: 40px;
                width: 144px;
                display: block;
                border: 1px solid $scaleColor200;
                border-radius: 12px;
                line-height: 38px;
                text-align: center;
                margin: 30px auto 0;
                font-size: 15px;
                color: #fff;
                &:hover {
                    background: $primaryColor;
                    border: 1px solid $primaryColor;
                }
            }
        }
        .filters {
            overflow: hidden;
            margin-bottom: 24px;
            button {
                float: left;
                margin-right: 8px;
                height: 40px;
                border: 1px solid $scaleColor900;
                border-radius: 8px;
                background: $scaleColor900;
                color: #fff;
                font-size: 14px;
                font-weight: 400;
                padding: 0 16px;
                cursor: pointer;
                margin-bottom: 8px;
                &:hover {
                    border-color: $primaryColor;
                }
                &.active {
                    border-color: $primaryColor;
                }
            }
        }
        aside {
            float: left;
            width: 312px;
            padding: 0 32px 0 0;
            @media (max-width: 940px) {
                width: 150px;
            }
            @media (max-width: 750px) {
                display: none;
            }
            h2 {
                font-size: 18px;
                font-weight: 300;
                color: #fff;
                font-family: 'DINPro Bold';
                margin: 0 0 24px;
            }
            nav {
                display: block;
                margin: 0 -4px;
                &:after {
                    display: block;
                    clear: both;
                    content: "";
                }
                a {
                    float: left;
                    display: inline;
                    width: calc(50% - 8px);
                    margin: 0 4px 8px;
                    height: 88px;
                    background: $scaleColor900;
                    border: 1px solid $scaleColor900;
                    text-align: center;
                    border-radius: 8px;
                    text-transform: uppercase;
                    color: #fff;
                    cursor: pointer;
                    background-position: center;
                    background-size: cover;
                    padding-top: 58px;
                    position: relative;
                    font-size: 12px;
                    &:after {
                        width: 40px;
                        height: 40px;
                        display: block;
                        content: "";
                        position: absolute;
                        background-position: center;
                        background-repeat: no-repeat;
                        top: 14px;
                        left: 50%;
                        margin-left: -20px;
                    }
                    &.live:after {
                        background-image: url(../../assets/images/svg/live.svg);
                    }
                    &.upcoming:after {
                        background-image: url(../../assets/images/svg/upcoming.svg);
                    }
                    &.History:after {
                        background-image: url(../../assets/images/svg/history.svg);
                    }
                    @media (max-width: 940px) {
                        float: none;
                        display: block;
                        width: 100%;
                    }
                    &.active {
                        border: 1px solid $primaryColor;
                        position: relative;
                        &:before {
                            width: 18px;
                            height: 18px;
                            display: block;
                            position: absolute;
                            content: "";
                            background-image: url(../../assets/images/svg/check.svg);
                            background-repeat: no-repeat;
                            background-position: center;
                            top: 8px;
                            right: 8px;
                        }
                    }
                    &:hover {
                        border: 1px solid $primaryColor;
                    }
                    &.sports {
                        padding-top: 58px;
                        position: relative;
                        font-size: 12px;
                        &:after {
                            width: 40px;
                            height: 40px;
                            display: block;
                            content: "";
                            position: absolute;
                            background-position: center;
                            background-repeat: no-repeat;
                            top: 14px;
                            left: 50%;
                            margin-left: -20px;
                        }
                    }
                }
            }
        }
        .events {
            float: left;
            width: calc(100% - 312px);
            @media (max-width: 940px) {
                width: calc(100% - 150px);
            }
            @media (max-width: 750px) {
                width: 100%;
                display: block;
                float: none;
            }
            .mobile-select-sport {
                display: none;
                @media (max-width: 750px) {
                    display: block;
                }
                > h2 {
                    font-size: 18px;
                    font-weight: 300;
                    color: #fff;
                    font-family: 'DINPro Bold';
                    margin: 0 0 24px;
                }
                .selected {
                    display: block;
                    height: 40px;
                    line-height: 40px;
                    padding: 0 18px;
                    background: $scaleColor900;
                    border-radius: 8px;
                    color: #fff;
                    font-size: 15px;
                    position: relative;
                    cursor: pointer;
                    background-position: center;
                    background-size: cover;
                    &:after {
                        background-image: url(../../assets/images/svg/Rectangle.svg);
                        background-repeat: no-repeat;
                        background-position: center;
                        width: 8px;
                        height: 8px;
                        display: block;
                        position: absolute;
                        content: "";
                        right: 18px;
                        top: 50%;
                        margin-top: -4px;
                    }
                }
                .selection {
                    margin-bottom: 24px;
                    overflow: hidden;
                    max-height: 0;
                    &.open {
                        max-height: 700px;
                    }
                    nav {
                        display: block;
                        margin: 0;
                        padding: 0;
                        a {
                            display: block;
                            height: 40px;
                            line-height: 38px;
                            padding: 0 18px;
                            background: $scaleColor900;
                            border: 1px solid $scaleColor900;
                            border-radius: 8px;
                            color: #fff;
                            font-size: 15px;
                            position: relative;
                            cursor: pointer;
                            margin-top: 16px;
                            background-position: center;
                            background-size: cover;
                            &.active {
                                border: 1px solid $primaryColor;
                                &:before {
                                    width: 18px;
                                    height: 18px;
                                    display: block;
                                    position: absolute;
                                    content: "";
                                    background-image: url(../../assets/images/svg/check.svg);
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    top: 50%;
                                    margin-top: -9px;
                                    right: 16px;
                                }
                            }
                        }
                    }
                }
            }
            > h2 {
                font-size: 18px;
                font-weight: 300;
                color: #fff;
                font-family: 'DINPro Bold';
                margin: 0 0 24px;
            }
            .props-listing {
                > div {
                    > h2 {
                        font-family: 'DINPro Bold';
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 24px;
                        color: #fff;
                        margin: 0 0 24px;
                    }
                }
                .head {
                    height: 36px;
                    border-radius: 8px;
                    background: $scaleColor900;
                    padding: 0 16px;
                    margin: 0 0 8px;
                    @media (max-width: 640px) {
                        display: none;
                    }
                    span {
                        float: left;
                        line-height: 36px;
                        text-transform: uppercase;
                        color: #fff;
                        font-size: 12px;
                        font-weight: 400;
                        &.player {
                            width: 60%;
                        }
                        &.props {
                            width: 25%;
                        }
                        &.points {
                            width: 15%;
                        }
                        &.over-under {
                            width: 15%;
                        }
                    }
                }
                ul {
                    display: block;
                    margin: 0 0 24px;
                    padding: 0;
                    li {
                        display: block;
                        margin: 0 0 8px;
                        border-radius: 12px;
                        background: $scaleColor900;
                        padding: 16px;
                        overflow: hidden;
                        > div {
                            float: left;
                            &.player {
                                width: 60%;
                                overflow: hidden;
                                @media (max-width: 640px) {
                                    width: 100%;
                                    display: block;
                                    float: none;
                                }
                                img {
                                    float: left;
                                    width: 56px;
                                    border-radius: 8px;
                                    margin-right: 16px;
                                }
                                h2 {
                                    margin: 0;
                                    font-size: 18px;
                                    font-family: 'DINPro Bold';
                                    color: #fff;
                                    font-weight: 400;
                                    cursor: pointer;
                                    span {
                                        font-size: 14px;
                                        font-weight: 400;
                                        font-family: "Open Sans";
                                        color: $scaleColor300;
                                        text-transform: uppercase;
                                    }
                                }
                                h3 {
                                    margin: 0;
                                    font-size: 14px;
                                    line-height: 22px;
                                    font-weight: 400;
                                    color: #fff;
                                }
                                h4 {
                                    font-size: 12px;
                                    line-height: 20px;
                                    font-weight: 400;
                                    color: $scaleColor300;
                                    margin: 0;
                                }
                            }
                            &.props {
                                width: 25%;
                                @media (max-width: 640px) {
                                    width: 100%;
                                    display: block;
                                    float: none;
                                    overflow: hidden;
                                    padding: 24px 0;
                                }
                                h3 {
                                    font-size: 16px;
                                    margin: 0 0 4px;
                                    line-height: 26px;
                                    color: #fff;
                                    span {
                                        font-size: 14px;
                                        line-height: 22px;
                                        font-weight: 400;
                                        color: $scaleColor300;

                                    }
                                    @media (max-width: 640px) {
                                        float: left;
                                        margin: 0;
                                        br {
                                            display: none;
                                        }
                                    }
                                }

                            }
                            &.points {
                                width: 15%;
                                @media (max-width: 640px) {
                                    width: 50%;
                                }
                                span {
                                    height: 40px;
                                    display: block;
                                    width: 56px;
                                    border-radius: 8px;
                                    background: $scaleColor800;
                                    text-align: center;
                                    line-height: 40px;
                                    font-size: 14px;
                                    font-weight: 600;
                                    color: #fff;
                                    margin: 10px 0 0;
                                    @media (max-width: 640px) {
                                        margin-left: 60px;
                                        position: relative;
                                        &:before {
                                            content: 'Props:';
                                            margin-right: 5px;
                                            left: -60px;
                                            position: absolute;
                                        }
                                    }
                                }
                            }
                            &.over-under {
                                width: 15%;
                                @media (max-width: 640px) {
                                    width: 50%;
                                }
                                span {
                                    display: block;
                                    border-radius: 8px;
                                    height: 40px;
                                    line-height: 40px;
                                    text-align: center;
                                    font-size: 14px;
                                    font-weight: 600;
                                    color: #fff;
                                    margin: 10px 0 0;
                                    &.blue {
                                        background: $primaryColor;
                                    }
                                    &.green {
                                        background: $successColor;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
