@import '../../assets/scss/colors';

.my-contest-event-page {
    background: $backdropGradient;
    background-image: url(../../assets/images/gradient.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top center;
    min-height: 100vh;
    > .inner {
        margin: 0 auto;
        width: 100%;
        max-width: 1280px;
        padding: 0 32px;
        @media (max-width: 640px) {
            padding: 0 24px;
        }
        &:after {
            display: block;
            clear: both;
            content: "";
        }
        aside {
            float: left;
            width: 385px;
            padding: 64px 0 0 0;
            @media (max-width: 1200px) {
                width: 320px;
                padding: 64px 30px 0 0;
            }
            @media (max-width: 1080px) {
                float: none;
                width: 100%;
                padding: 30px 0 30px;
            }
            @media (max-width: 640px) {
                padding: 16px 0;
            }
            .back-btn {
                font-size: 15px;
                color: $scaleColor200;
                background-image: url(../../assets/images/svg/arrow-left.svg);
                background-position: left center;
                background-repeat: no-repeat;
                padding-left: 35px;
                display: block;
                margin: 0 0 32px;
                @media (max-width: 640px) {
                    margin: 0 0 16px;
                }
            }
            .event-details {
                border-radius: 12px;
                background: $scaleColor900;
                margin: 0 0 16px;
                @media (max-width: 640px) {
                    display: none;
                }
                .top {
                    padding: 24px;
                    overflow: hidden;
                    img {
                        float: left;
                        height: 55px;
                        margin-right: 20px;
                        border-radius: 8px;
                    }
                    h1 {
                        font-size: 18px;
                        line-height: 24px;
                        color: #fff;
                        font-family: 'DINPro Bold';
                        font-weight: 300;
                        margin: 0;
                        span {
                            font-family: "Open Sans";
                            height: 20px;
                            line-height: 20px;
                            font-weight: 600;
                            padding: 0 10px;
                            font-size: 11px;
                            border-radius: 4px;
                            background: $secondaryOrange;
                            margin: -4px 0 0 16px;
                            display: inline-block;
                            text-transform: uppercase;
                        }
                    }
                }
                .bottom {
                    border-radius: 12px;
                    padding: 24px;
                    background: $scaleColor800;
                    .info {
                        overflow: hidden;
                        border-bottom: 1px solid $scaleColor700;
                        padding-bottom: 20px;
                        h3 {
                            width: 33%;
                            margin: 0;
                            float: left;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            color: $scaleColor200;
                            span {
                                display: block;
                                font-size: 18px;
                                font-weight: 600;
                                color: #fff;
                            }
                        }
                    }
                    .stats {
                        overflow: hidden;
                        border-bottom: 1px solid $scaleColor700;
                        padding: 20px 0;
                        &:last-child {
                          border: none;
                            padding-bottom: 0;
                        }
                        p {
                            margin: 0;
                            float: left;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            color: $scaleColor200;
                            &:nth-child(2){
                                float: right;
                            }
                        }

                    }
                    .dates {
                        overflow: hidden;
                        padding-top: 16px;
                        p {
                            float: left;
                            font-size: 12px;
                            line-height: 20px;
                            font-weight: 400;
                            color: $scaleColor200;
                            margin: 0;
                        }
                        .countdown {
                            float: right;
                            overflow: hidden;
                            text-align: center;
                            display: inline;
                            > div {
                                float: left;
                                display: inline;
                                padding: 0 10px;
                                font-size: 9px;
                                font-weight: 400;
                                color: #fff;
                                text-transform: uppercase;
                                border-left: 1px solid $scaleColor700;
                                &:first-child {
                                    border: none;
                                }
                                span {
                                    display: block;
                                    font-size: 16px;
                                    font-weight: 300;
                                    font-family: 'DINPro Bold';
                                    margin: 0 0 5px;
                                    color: $primaryColor;
                                }
                            }
                        }
                    }
                }
            }
            .event-details-mobile {
                display: none;
                border-radius: 12px;
                background: $scaleColor900;
                margin: 0 0 16px;
                @media (max-width: 640px) {
                    display: block;
                }
                .top {
                    padding: 24px;
                    img {
                        float: left;
                        height: 55px;
                        margin-right: 16px;
                        border-radius: 8px;
                    }
                    h3 {
                        font-size: 18px;
                        line-height: 27px;
                        color: #fff;
                        font-family: 'DINPro Bold';
                        font-weight: 300;
                        clear: both;
                        margin: 0 0 16px;
                        span {
                            font-family: "Open Sans";
                            height: 20px;
                            line-height: 20px;
                            font-weight: 600;
                            padding: 0 10px;
                            font-size: 11px;
                            border-radius: 4px;
                            background: $secondaryOrange;
                            margin: -4px 0 0 16px;
                            display: inline-block;
                            text-transform: uppercase;
                        }
                    }
                    h4 {
                        margin: 0 0 16px;
                        float: left;
                        width: 33.33333%;
                        font-size: 18px;
                        color: #fff;
                        font-weight: 600;
                        span {
                            display: block;
                            font-size: 12px;
                            font-weight: 400;
                            color: $scaleColor200;
                            margin: 0 0 5px;
                        }
                    }
                }
                .bottom {
                    clear: both;
                    border-radius: 12px;
                    background: $scaleColor800;
                    padding: 0 16px;
                    p {
                        margin: 0;
                        height: 46px;
                        line-height: 46px;
                        border-top: 1px solid $scaleColor700;
                        font-size: 14px;
                        color: $scaleColor200;
                        &:first-child {
                            border-color: transparent;
                        }
                        span {
                            float: right;
                            font-weight: 600;
                            color: #fff;
                        }
                    }
                    a {
                        margin-top: 16px;
                        display: block;
                        background: $primaryColor;
                        height: 36px;
                        line-height: 36px;
                        border-radius: 12px;
                        padding: 0 12px;
                        color: #fff;
                        font-size: 15px;
                        font-weight: 600;
                        text-align: center;
                    }
                }
            }
            .event-summary {
                border-radius: 12px;
                background: $scaleColor900;
                padding: 24px;
                margin: 0 0 16px;
                @media (max-width: 640px) {
                    padding: 0 24px;
                    overflow: hidden;
                    max-height: 0;
                    margin: 0;
                    &.open {
                        padding: 24px;
                        max-height: 300px;
                        margin: 0 0 16px;
                    }
                }
                h2 {
                    font-size: 24px;
                    font-weight: 300;
                    color: #FFF;
                    font-family: 'DINPro Bold';
                    margin: 0 0 8px;
                }
                p {
                    font-size: 15px;
                    line-height: 24px;
                    font-weight: 400;
                    color: $scaleColor200;
                }
            }

            .event-Prize-Payouts {
                background: $scaleColor900;
                border-radius: 12px;
                padding: 24px 0 0;
                @media (max-width: 640px) {
                    padding: 0;
                    overflow: hidden;
                    max-height: 0;
                    &.open {
                        padding: 24px 0 0;
                        max-height: 1000px;
                        margin: 0 0 16px;
                    }
                }
                h2 {
                    font-size: 18px;
                    font-weight: 300;
                    color: #FFF;
                    font-family: 'DINPro Bold';
                    margin: 0 24px 17px;
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                    tr {
                        th {
                            border-top: 1px solid $scaleColor800;
                            padding: 0 24px;
                            height: 40px;
                            line-height: 39px;
                            text-align: left;
                            // background: rgba(51,78,85,0.6);
                            background: $scaleColor800;
                            font-size: 12px;
                            text-transform: uppercase;
                            font-weight: 400;
                            color: $scaleColor200;
                        }
                        td {
                            padding: 0 24px;
                            height: 40px;
                            line-height: 39px;
                            border-top: 1px solid $scaleColor800;
                            font-size: 14px;
                            font-weight: 400;
                            color: #FFF;
                        }.nowrap {
                            white-space: nowrap;
                        }
                    }
                }
                .event-information {
                    border-radius: 0 0 12px 12px;
                    // background: rgba(51,78,85,0.7);
                    background: $scaleColor800;
                    opacity: 0.7;
                    padding: 24px;

                    h2 {
                        font-size: 18px;
                        font-weight: 300;
                        color: #FFF;
                        font-family: 'DINPro Bold';
                        margin: 0 0 8px;
                    }
                    p {
                        font-size: 15px;
                        line-height: 24px;
                        font-weight: 400;
                        color: $scaleColor200;
                        margin-bottom: 0;
                    }
                }
            }
            .mobile-switch-info {
                display: none;
                overflow: hidden;
                @media (max-width: 640px) {
                    display: block;
                }
                button {
                    height: 32px;
                    width: 49%;
                    background: $successColor;
                    border-radius: 12px;
                    color: #fff;
                    font-size: 13px;
                    font-weight: 600;
                    cursor: pointer;
                    &:first-child {
                        float: left;
                    }
                    &:last-child {
                        float: right;
                    }
                }
            }
        }
        .event-content {
            width: calc(100% - 385px);
            padding: 40px 0 40px 40px;
            float: left;
            @media (max-width: 1200px) {
                padding: 40px 0 40px 30px;
                width: calc(100% - 320px);
            }
            @media (max-width: 1080px) {
                float: none;
                width: 100%;
                padding: 0 0 40px;
                border: none;
            }
            .tabs-head {
                border-bottom: 1px solid $scaleColor900;
                button {
                    background: transparent;
                    padding: 0 0 16px 0;
                    margin-right: 40px;
                    color: $secondaryTextColor;
                    cursor: pointer;
                    border-bottom: 2px solid $scaleColor1000;
                    &.active {
                        border-bottom: 2px solid $primaryColor;
                        color: $secondaryTextColor;
                    }

                }
            }
            .tabs-body {
                .tab-container {
                    display: none;
                }
                .tab-container.active {
                    display: block;
                }
            }
        }
    }
}
