@import '../../assets/scss/colors';
.my-contest-history {
    background: $backdropGradient;
    background-image: url(../../assets/images/gradient.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top center;
    min-height: 100vh;
    > .inner {
        margin: 0 auto;
        width: 100%;
        max-width: 1280px;
        padding: 32px;
        @media (max-width: 600px) {
            padding: 32px 24px;
        }
        &:after {
            display: block;
            clear: both;
            content: "";
        }
        .filters {
            overflow: hidden;
            margin-bottom: 24px;
            button {
                float: left;
                margin-right: 8px;
                height: 40px;
                border: 1px solid $scaleColor800;
                border-radius: 8px;
                background: $entityBlock;
                color: #fff;
                font-size: 14px;
                font-weight: 400;
                padding: 0 16px;
                cursor: pointer;
                margin-bottom: 8px;
                &:hover {
                    border-color: $primaryColor;
                }
                &.active {
                    border-color: $primaryColor;
                }
            }
        }
        aside {
            float: left;
            width: 312px;
            padding: 0 32px 0 0;
            @media (max-width: 940px) {
                width: 150px;
            }
            @media (max-width: 750px) {
                display: none;
            }
            h2 {
                font-size: 18px;
                font-weight: 300;
                color: $secondaryTextColor;
                font-family: 'DINPro Bold';
                margin: 0 0 24px;
            }
            nav {
                display: block;
                margin: 0 -4px;
                &:after {
                    display: block;
                    clear: both;
                    content: "";
                }
                a {
                    float: left;
                    display: inline;
                    width: calc(50% - 8px);
                    margin: 0 4px 8px;
                    height: 88px;
                    background: $entityBlock;
                    border: 1px solid $scaleColor900;
                    text-align: center;
                    border-radius: 8px;
                    text-transform: uppercase;
                    color: #fff;
                    cursor: pointer;
                    background-position: center;
                    background-size: cover;
                    padding-top: 58px;
                    position: relative;
                    font-size: 12px;
                    &:after {
                        width: 40px;
                        height: 40px;
                        display: block;
                        content: "";
                        position: absolute;
                        background-position: center;
                        background-repeat: no-repeat;
                        top: 14px;
                        left: 50%;
                        margin-left: -20px;
                    }
                    &.live:after {
                        background-image: url(../../assets/images/svg/live.svg);
                    }
                    &.upcoming:after {
                        background-image: url(../../assets/images/svg/upcoming.svg);
                    }
                    &.History:after {
                        background-image: url(../../assets/images/svg/history.svg);
                    }
                    @media (max-width: 940px) {
                        float: none;
                        display: block;
                        width: 100%;
                    }
                    &.active {
                        border: 1px solid $primaryColor;
                        position: relative;
                        &:before {
                            width: 18px;
                            height: 18px;
                            display: block;
                            position: absolute;
                            content: "";
                            background-image: url(../../assets/images/svg/check.svg);
                            background-repeat: no-repeat;
                            background-position: center;
                            top: 8px;
                            right: 8px;
                        }
                    }
                    &:hover {
                        border: 1px solid $primaryColor;
                    }
                }
            }
        }
        .events {
            float: left;
            width: calc(100% - 312px);
            @media (max-width: 940px) {
                width: calc(100% - 150px);
            }
            @media (max-width: 750px) {
                width: 100%;
                display: block;
                float: none;
            }
            .mobile-select-sport {
                display: none;
                @media (max-width: 750px) {
                    display: block;
                }
                > h2 {
                    font-size: 18px;
                    font-weight: 300;
                    color: $secondaryTextColor;
                    font-family: 'DINPro Bold';
                    margin: 0 0 24px;
                }
                .selected {
                    display: block;
                    height: 40px;
                    line-height: 40px;
                    padding: 0 18px;
                    background: $scaleColor900;
                    border-radius: 8px;
                    color: #fff;
                    font-size: 15px;
                    position: relative;
                    cursor: pointer;
                    background-position: center;
                    background-size: cover;
                    &:after {
                        background-image: url(../../assets/images/svg/Rectangle.svg);
                        background-repeat: no-repeat;
                        background-position: center;
                        width: 8px;
                        height: 8px;
                        display: block;
                        position: absolute;
                        content: "";
                        right: 18px;
                        top: 50%;
                        margin-top: -4px;
                    }
                }
                .selection {
                    margin-bottom: 24px;
                    overflow: hidden;
                    max-height: 0;
                    &.open {
                        max-height: 700px;
                    }
                    nav {
                        display: block;
                        margin: 0;
                        padding: 0;
                        a {
                            display: block;
                            height: 40px;
                            line-height: 38px;
                            padding: 0 18px;
                            background: $scaleColor900;
                            border: 1px solid $scaleColor900;
                            border-radius: 8px;
                            color: #fff;
                            font-size: 15px;
                            position: relative;
                            cursor: pointer;
                            margin-top: 16px;
                            background-position: center;
                            background-size: cover;
                            &.active {
                                border: 1px solid $primaryColor;
                                &:before {
                                    width: 18px;
                                    height: 18px;
                                    display: block;
                                    position: absolute;
                                    content: "";
                                    background-image: url(../../assets/images/svg/check.svg);
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    top: 50%;
                                    margin-top: -9px;
                                    right: 16px;
                                }
                            }
                        }
                    }
                }
            }
            > h2 {
                font-size: 18px;
                font-weight: 300;
                color: $secondaryTextColor;
                font-family: 'DINPro Bold';
                margin: 0 0 24px;
            }
            .sort-listing {
                border-top: 1px solid $scaleColor900;
                border-bottom: 1px solid $scaleColor900;
                padding: 15px 0;
                height: 51px;
                margin: 0 0 14px;
                .sort-events {
                    float: right;
                    position: relative;
                    label {
                        color: $secondaryTextColor;
                        font-size: 14px;
                        font-weight: 600;
                        padding-right: 20px;
                        background-image: url(../../assets/images/svg/Rectangle.svg);
                        background-position: right center;
                        background-repeat: no-repeat;
                        cursor: pointer;
                    }
                    > div {
                        position: absolute;
                        right: 0;
                        z-index: 10;
                        max-height: 0;
                        overflow: hidden;
                        background: $scaleColor900;
                        padding: 0 10px 10px;
                        &.open {
                            max-height: 500px;
                        }
                        ul {
                            display: block;
                            margin: 0;
                            padding: 0;
                            width: 310px;
                            li {
                                display: block;
                                height: 40px;
                                line-height: 38px;
                                padding: 0 18px;
                                background: $scaleColor800;
                                border: 1px solid $scaleColor900;
                                border-radius: 8px;
                                color: #fff;
                                font-size: 15px;
                                position: relative;
                                cursor: pointer;
                                margin-top: 10px;
                                background-position: center;
                                background-size: cover;
                                &:hover {
                                    border: 1px solid $primaryColor;
                                }
                                &.active {
                                    border: 1px solid $primaryColor;
                                    &:before {
                                        width: 18px;
                                        height: 18px;
                                        display: block;
                                        position: absolute;
                                        content: "";
                                        background-image: url(../../assets/images/svg/check.svg);
                                        background-repeat: no-repeat;
                                        background-position: center;
                                        top: 50%;
                                        margin-top: -9px;
                                        right: 16px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .events-listing {
                ul {
                    display: block;
                    margin: 0;
                    padding: 0;
                    li {
                        display: block;
                        margin: 0 0 16px;
                        border-radius: 12px;
                        background: $entityBlock;
                        padding: 16px;
                        cursor: pointer;
                        .desktop {
                            display: block;
                            @media (max-width: 600px) {
                                display: none;
                            }
                            .top {
                                overflow: hidden;
                                padding-bottom: 16px;
                                border-bottom: 1px solid $scaleColor800;
                                img {
                                    float: left;
                                    height: 48px;
                                    border-radius: 8px;
                                    margin-right: 16px;
                                }
                                .headings {
                                    float: left;
                                    h3 {
                                        margin: 0 0 5px;
                                        padding: 0;
                                        font-size: 18px;
                                        color: #fff;
                                        font-family: 'DINPro Bold';
                                        font-weight: 300;
                                        cursor: pointer;
                                        span {
                                            font-family: "Open Sans";
                                            height: 20px;
                                            line-height: 20px;
                                            font-weight: 600;
                                            padding: 0 10px;
                                            font-size: 11px;
                                            border-radius: 4px;
                                            background: $secondaryOrange;
                                            margin: -4px 0 0 16px;
                                            display: inline-block;
                                            text-transform: uppercase;
                                            &.contest-filled {
                                                background: $errorColor;
                                            }
                                        }
                                    }
                                    h4 {
                                        margin: 0;
                                        padding: 0;
                                        font-size: 14px;
                                        color: #fff;
                                        font-weight: 300;
                                        cursor: pointer;
                                    }
                                }
                                .info {
                                    float: right;
                                    display: inline;
                                    overflow: hidden;
                                    h3 {
                                        font-size: 18px;
                                        font-weight: 400;
                                        color: #fff;
                                        margin: 0 0 0 25px;
                                        display: inline-block;
                                        text-align: right;
                                        text-transform: uppercase;
                                        cursor: pointer;
                                        span {
                                            display: block;
                                            font-size: 12px;
                                            font-weight: 400;
                                            color: $scaleColor200;
                                            margin-bottom: 5px;
                                        }
                                    }
                                }
                            }
                            .bottom {
                                overflow: hidden;
                                padding-top: 16px;
                                .left {
                                    float: left;
                                    display: inline;
                                    p {
                                        margin: 0 0 0 50px;
                                        font-size: 12px;
                                        font-weight: 400;
                                        line-height: 20px;
                                        color: $scaleColor200;
                                        float: left;
                                        cursor: pointer;
                                        &:first-child {
                                            margin: 0;
                                        }
                                    }
                                ;
                                }
                                .right {
                                    float: right;
                                    display: inline;
                                    .countdown {
                                        overflow: hidden;
                                        text-align: center;
                                        float: left;
                                        display: inline;
                                        margin: 0 20px 0 0;
                                        > div {
                                            float: left;
                                            display: inline;
                                            padding: 0 15px;
                                            font-size: 9px;
                                            font-weight: 400;
                                            color: #fff;
                                            text-transform: uppercase;
                                            border-left: 1px solid $scaleColor700;
                                            &:first-child {
                                                border: none;
                                            }
                                            span {
                                                display: block;
                                                font-size: 16px;
                                                font-weight: 300;
                                                font-family: 'DINPro Bold';
                                                margin: 0 0 5px;
                                                color: $primaryColor;
                                            }
                                        }
                                    }
                                    button {
                                        float: left;
                                        display: inline;
                                        background: $primaryColor;
                                        height: 36px;
                                        line-height: 36px;
                                        border-radius: 12px;
                                        padding: 0 12px;
                                        color: #fff;
                                        font-size: 15px;
                                        font-weight: 400;
                                        cursor: pointer;
                                        &:hover {
                                            background: #fff;
                                            color: $primaryColor;
                                        }
                                    }
                                }
                            }
                        }
                        .mobile {
                            display: none;
                            @media (max-width: 600px) {
                                display: block;
                            }
                            .top {
                                img {
                                    float: left;
                                    height: 55px;
                                    margin-right: 16px;
                                    border-radius: 8px;
                                }
                                h3 {
                                    font-size: 18px;
                                    line-height: 27px;
                                    color: #fff;
                                    font-family: 'DINPro Bold';
                                    font-weight: 300;
                                    margin: 0 0 16px;
                                    width: calc(100% - 71px);
                                    float: left;
                                    min-height: 54px;
                                    span {
                                        font-family: "Open Sans";
                                        height: 20px;
                                        line-height: 20px;
                                        font-weight: 600;
                                        padding: 0 10px;
                                        font-size: 11px;
                                        border-radius: 4px;
                                        background: $secondaryOrange;
                                        margin: -4px 0 0 16px;
                                        display: inline-block;
                                        text-transform: uppercase;
                                    }
                                }
                                .clearfix {
                                    clear: both;
                                    display: block;
                                }
                                h4 {
                                    margin: 0 0 16px;
                                    float: left;
                                    width: 50%;
                                    font-size: 18px;
                                    color: #fff;
                                    font-weight: 600;
                                    span {
                                        display: block;
                                        font-size: 12px;
                                        font-weight: 400;
                                        color: $scaleColor200;
                                        margin: 0 0 5px;
                                    }
                                }
                            }
                            .bottom {
                                clear: both;
                                border-radius: 12px;
                                background: $scaleColor800;
                                margin: 0 -16px -16px;
                                padding: 16px;
                                p {
                                    margin: 0;
                                    height: 46px;
                                    line-height: 46px;
                                    border-top: 1px solid $scaleColor700;
                                    font-size: 14px;
                                    color: $scaleColor200;
                                    &:first-child {
                                        border-color: transparent;
                                    }
                                    span {
                                        float: right;
                                        font-weight: 600;
                                        color: #fff;
                                    }
                                }
                                button,
                                a {
                                    margin-top: 16px;
                                    display: block;
                                    background: $primaryColor;
                                    height: 36px;
                                    line-height: 36px;
                                    border-radius: 12px;
                                    padding: 0 12px;
                                    color: #fff;
                                    font-size: 15px;
                                    font-weight: 600;
                                    text-align: center;
                                }
                            }
                        }
                    }
                    .no-results {
                        text-align: center;
                        color: #fff;
                        background: transparent;
                        > div {
                            background-image: url(../../assets/images/not-found.png);
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: contain;
                            height: 160px;
                            width: 173px;
                            margin: 0 auto 30px;
                        }
                        h3 {
                            font-size: 24px;
                            font-family: 'DINPro Bold';
                            font-weight: 400;
                            line-height: 32px;
                            color: $secondaryTextColor;
                        }
                        span {
                            font-size: 16px;
                            font-weight: 400;
                            color: $scaleColor200;
                        }
                        a {
                            height: 40px;
                            width: 144px;
                            display: block;
                            border: 1px solid $secondaryTextColor;
                            border-radius: 12px;
                            line-height: 38px;
                            text-align: center;
                            margin: 30px auto 0;
                            font-size: 15px;
                            color: $secondaryTextColor;
                            &:hover {
                                background: $primaryColor;
                                border: 1px solid $primaryColor;
                            }
                        }
                    }
                }
                .load-more {
                    height: 40px;
                    line-height: 38px;
                    border-radius: 12px;
                    padding: 0 50px;
                    color: #fff;
                    font-size: 15px;
                    font-weight: 600;
                    text-align: center;
                    margin: 30px auto;
                    cursor: pointer;
                    display: none;
                    border: 1px solid #fff;
                    background: transparent;
                    &:hover {
                        background: #fff;
                        color: $primaryColor;
                    }
                    &.show {
                        display: block;
                    }
                }
            }
        }
    }
}
