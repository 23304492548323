@import '../../../assets/scss/colors.scss';

#how-to-play-body{

  display: flex;
  flex-direction: column;

  box-sizing: border-box;

  p{
    font-size: 14px;
    text-indent: 0px;
    margin-bottom: 15px;
    margin-top: 15px;
    line-height: 18px;
  }

  li{
    line-height: 18px;
  }

  strong{
    margin-bottom: 20px;
    font-size: 13px;
    color: $primaryColor;
  }

  b{
    margin-bottom: 20px;
    font-size: 13px;
    color: $primaryColor;
  }

  ul{
    text-indent: 0;
    padding-left: 15px;
    margin-bottom: 0px;
  }


  .image-row{
    width: 100%;
    max-width: 700px;
    height: 350px;

    display: flex;
    justify-content: space-between;

    margin: 30px auto;

    @media(max-width: 825px){

      flex-direction: column;
      align-items: center;
      height: 720px;
    }

    .image-row-col{
      width: 250px;
      height: 350px;

      display: flex;
      flex-direction: column;
      align-items: center;

      @media(max-width: 825px){
        margin-bottom: 20px;
      }

      #image-row-col-header{
        width: 100%;
        height: 60px;
        line-height: 60px;

        text-align: center;

        margin: 0;
        text-indent: 0;

      }

      #tall-img{
        object-fit: cover;
      }

      img{
        object-fit: cover;
        width: 100%;
        height: 290px;

        border-radius: 8px;
        object-position: center top;

        box-shadow: 0 0 10px 1px $secondaryColor;
      }
    }


    
    
  }
  .full-row-image{
    width: 100%;

    margin: 30px 0px;
    padding: 0px 100px;

    @media(max-width: 800px){
      margin: 20px 0px;
      padding: 0px;
    }

    img{
      width: 100%;
      object-fit: contain;
      border-radius: 8px;

      box-shadow: 0 0 10px 1px $secondaryColor;
    }
  }


}


