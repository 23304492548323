@import './assets/scss/colors';
/*------------------------------------------normalize---------------------------*/
* {
    box-sizing: border-box;
    transition: 0.3s all linear;

}
html {
    line-height: 1.15;
    scroll-behavior: smooth;
}
body {
    margin: 0;
    position: relative;
    font-family: "Open Sans", sans-serif;
    transition: 0s all linear;
    // background: $scaleColor1000;
    background: $backdropGradient;
}
main {
    display: block;
}
h1 {
    font-size: 2em;
    margin: 0.67em 0;
}
hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}
pre {
    font-family: monospace, monospace;
    font-size: 1em;
}
a {
    background: transparent;
    text-decoration: none;
}
a:active,
a:focus {
    outline: 0;
    border: none;
}
abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
}
b,
strong {
    font-weight: 700;
}
code,
kbd,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}
small {
    font-size: 80%;
}
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sub {
    bottom: -0.25em;
}
sup {
    top: -0.5em;
}
img {
    border-style: none;
}
button,
input,
optgroup,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    background: white;
    -webkit-appearance: none;
    outline:none;
}
select {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    background: white;
}
button {
    border: none;
}
button,
input {
    overflow: visible;
}
input:focus,
select:focus {
    outline: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background 5000s ease-in-out 0s;
}
input::-webkit-input-placeholder { /* Edge */
    opacity: 1;
}
input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    opacity: 1;
}
input::placeholder {
    opacity: 1;
}
button,
select {
    text-transform: none;
}
button,
[type='button'],
[type='reset'],
[type='submit'] {
    -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
    border-style: none;
    padding: 0;
}
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
    outline: 1px dotted ButtonText;
}
fieldset {
    padding: 0.35em 0.75em 0.625em;
}
legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}
progress {
    vertical-align: baseline;
}
textarea {
    overflow: auto;
}
[type='checkbox'],
[type='radio'] {
    box-sizing: border-box;
    padding: 0;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
    height: auto;
}
[type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}
[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}
::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}
details {
    display: block;
}
summary {
    display: list-item;
}
template {
    display: none;
}
[hidden] {
    display: none;
}
ul, ol {
    margin: 0;
    padding: 0;
}
li {
    margin: 0;
    padding: 0;
}
li, h1, h2, h3, h4, p, span {
    cursor: default;
}
/*------------------------------fonts----------------------------------*/
@font-face {
    font-family: 'DINPro Bold';
    src: url('./assets/fonts/dinprobold.woff') format('woff')

}

