@import "../../assets/scss/colors";

.menu-item {
  padding: 0 20px;
  margin: 5px 10px;
  user-select: none;
  cursor: pointer;
  border: none;
  color: $primaryColor;
  font-size: 12.5px;
}

.menu-item-wrapper.active {
  border: 1px $primaryColor solid;
}

.menu-item.active {
  border: 1px $primaryColor solid;
}

.menu-item-wrapper.inactive {
  border: 1px none solid;
}

.menu-item.inactive {
  border: 1px none solid;
}

.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
  color: $primaryColor;
}

@media (min-width: 1200px) {
  .props-lobby-page {
    background: $backdropGradient;
    background-image: url(../../assets/images/gradient.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top center;
    min-height: 100vh;
    padding-bottom: 100px;

    > .inner {
      display: flex;
      margin: 0 auto;
      width: 100%;
      max-width: 1280px;
      padding: 40px 32px;

      @media (max-width: 640px) {
        padding: 0 24px;
      }

      &:after {
        display: block;
        clear: both;
        content: "";
      }

      .tabs-heads {
        // border-bottom: 1px solid $scaleColor900;
        border-bottom: 1px solid $scaleColor900;
        text-align: center;
        margin-bottom: 32px;

        button {
          background: transparent;
          padding: 0 0 16px 0;
          margin-right: 40px;
          margin-left: 40px;
          color: $secondaryTextColor;
          cursor: pointer;
          border-bottom: 2px solid $scaleColor1000;
          font-size: 18px;

          &.active {
            border-bottom: 2px solid $primaryColor;
            color: $secondaryTextColor;
          }

          @media (max-width: 750px) {
            font-size: 13px;
            margin-right: 10px;
          }
        }
      }

      .tabs-container-sport {
        border-bottom: 1px solid $scaleColor900;
        text-align: center;
        margin-bottom: 10px;
        margin-top: -20px;
        width: 100%;
      }

      .tabs-container-game {
        border-bottom: 1px solid $scaleColor900;
        text-align: center;
        margin-bottom: 10px;
        margin-top: 0px;
      }
      aside {
        float: left;
        width: 384px;
        padding: 0 32px 0 0;

        @media (max-width: 940px) {
          width: 150px;
        }

        @media (max-width: 1080px) {
          float: none;
          width: 100%;
          padding: 30px 0 30px;
        }

        @media (max-width: 640px) {
          padding: 16px 0;
        }

        @media (max-width: 1200px) {
          width: 320px;
          padding: 64px 30px 0 0;
        }

        .event-summary {
          border-radius: 12px;
          background: $scaleColor900;
          padding: 24px;
          margin: 0 0 16px;

          h2 {
            font-size: 24px;
            font-weight: 300;
            color: #fff;
            font-family: "DINPro Bold";
            margin: 0 0 8px;
          }

          p {
            font-size: 15px;
            line-height: 24px;
            font-weight: 400;
            color: $scaleColor200;
            p.promo-styling {
              color: $primaryColor;
            }
          }

          li {
            margin-left: 30px;
          }
        }

        .listing {
          position: sticky;
          top: 0px;

          margin: 0;
          padding: 0;
          display: block;

          li {
            display: block;
            margin: 0 0 8px;
            background: $scaleColor900;
            padding: 16px;
            border-radius: 12px;
            overflow: hidden;
            position: relative;

            @media (max-width: 640px) {
              margin: 0 0 16px;
            }

            .player {
              display: flex;
              float: left;
              width: 67%;
              overflow: hidden;
              cursor: pointer;
              padding-top: 5px;

              @media (max-width: 640px) {
                float: none;
                width: 100%;
                margin-bottom: 12px;
              }

              img {
                max-height: 45px;
                max-width: 45px;
                border-radius: 8px;
                float: left;
                margin-right: 8px;
              }

              h3 {
                margin: 0;
                font-size: 13px;
                line-height: 17px;
                font-weight: 700;
                color: #fff;
                cursor: pointer;

                span {
                  margin-top: 5px;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 22px;
                  color: $scaleColor300;
                  display: block;
                }
              }

              h4 {
                margin: 3px 40px 0;
                font-size: 10px;
                line-height: 20px;
                color: $scaleColor300;
                font-weight: 400;
              }
            }

            .rightSide {
              margin-bottom: 10px;
              vertical-align: 50%;
              height: 45px;
              font-size: 12px;

              div {
                .OU {
                  text-align: center;
                  font-size: 14px;
                  color: $primaryColor;
                }

                h3 {
                  color: white;
                  font-size: 12px;
                  text-align: center;
                }
              }

              .text {
                width: auto;
                align-content: right;
                text-align: center;
                color: white;
                font-size: 9px;
              }
            }

            .close-player-popup {
              position: absolute;
              top: 20px;
              right: 24px;
              height: 18px;
              width: 18px;
              background: transparent;
              background-image: url(../../assets/images/svg/close.svg);
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              cursor: pointer;
              color: transparent;
            }

            .props {
              float: left;
              width: 30%;

              @media (max-width: 640px) {
                float: none;
                width: 100%;
                margin-bottom: 12px;
              }

              .OU {
                font-size: 12px;
              }

              h3 {
                span {
                  font-size: 12px;
                }

                font-size: 12px;
                line-height: 22px;
                font-weight: 400;
                color: $scaleColor300;

                // margin: 12px 0 0;
                .prop-value {
                  font-size: 12px;
                  color: #fff;
                }

                @media (max-width: 640px) {
                  br {
                    display: none;
                  }
                }
              }
            }

            .points {
              float: left;
              width: 132px;

              @media (max-width: 640px) {
                float: none;
                width: 100%;
              }

              button {
                display: block;
                height: 30px;
                margin: 0 0 4px;
                border-radius: 8px;
                background: $scaleColor800;
                width: 132px;
                font-size: 14px;
                font-weight: 400;
                color: #fff;
                cursor: pointer;

                @media (max-width: 640px) {
                  width: 100%;
                  margin: 0 0 8px;
                }

                &:last-child {
                  margin: 0;
                }

                &.active,
                &:hover {
                  background: $primaryColor;
                }
              }
            }

            .ice-pick {
              position: absolute;
              background: $primaryColor;
              height: 18px;
              font-weight: 600;
              line-height: 18px;
              font-size: 12px;
              padding: 0 15px;
              color: #fff;
              top: 20px;
              left: -20px;
              transform: rotateZ(-45deg);
            }
          }
        }
        .submit-button {
          width: 100%;
          height: 48px;
          background: $primaryColor;
          border-radius: 12px;
          text-transform: uppercase;
          font-size: 14px;
          color: #fff;
          font-weight: 700;
          cursor: pointer;

          @media (max-width: 640px) {
            margin: 12px 0 0;
            width: 45%;
          }

          &:hover {
            background: #fff;
            color: $primaryColor;
          }
        }
        // }
      }

      .event-props {
        float: left;
        width: calc(100% - 384px);

        @media (max-width: 1200px) {
          padding: 40px 0 40px 30px;
          width: calc(100% - 320px);
        }

        @media (max-width: 1080px) {
          float: none;
          width: 100%;
          padding: 0 0 40px;
          border: none;
        }

        .actions {
          overflow: hidden;
          border-bottom: 1px solid $scaleColor900;
          border-top: 1px solid $scaleColor900;
          margin: 0 0 24px;

          @media (max-width: 640px) {
            border: none;
            margin: 0 0 10px;
          }

          .left {
            float: left;

            @media (max-width: 640px) {
              float: none;
            }
          }

          .right {
            float: right;

            @media (max-width: 640px) {
              float: none;
            }
          }

          button {
            background: transparent;
            padding: 18px 0 18px 26px;
            margin-right: 40px;
            color: $secondaryTextColor;
            background-position: left center;
            background-repeat: no-repeat;
            cursor: pointer;
            white-space: nowrap;

            @media (max-width: 640px) {
              width: 50%;
              margin: 0;
              padding: 15px 26px;
              font-size: 14px;
              text-align: left;
            }

            &:hover {
              color: $primaryColor;
            }

            &.import-picks,
            &.re-enter-contest {
              background-image: url(../../assets/images/svg/import.svg);
            }

            &.export-picks,
            &.apply-similar {
              background-image: url(../../assets/images/svg/export.svg);
            }

            &.clear-selection,
            &.withdraw-contest {
              background-image: url(../../assets/images/svg/close2.svg);
            }

            &.invite-friends {
              background-image: url(../../assets/images/svg/add.svg);
            }

            &.edit-picks {
              background-image: url(../../assets/images/svg/info.svg);
            }
          }
        }

        .tab-making-picks-props-listing {
          margin: 0 0 16px;
          display: none;

          &.active {
            display: block;
          }

          .head {
            height: 36px;
            border-radius: 8px;
            background: $scaleColor900;
            padding: 0 16px;
            margin: 0 0 8px;

            @media (max-width: 640px) {
              display: none;
            }

            span {
              width: 50%;
              float: left;
              line-height: 36px;
              text-transform: uppercase;
              color: #fff;
              font-size: 12px;
              font-weight: 400;

              &:nth-child(2) {
                width: calc(50% - 132px);
              }

              &:last-child {
                width: 132px;
              }
            }
          }

          .listing {
            margin: 0;
            padding: 0;
            display: block;

            .promoText {
              color: $primaryColor;
              font-size: 18px;
              margin-right: 5px;
              padding-top: 40px;
              font-weight: 700;
              position: relative;
              float: right;
              margin-left: 10px;
            }
            .promoTextLeft {
              color: $primaryColor;
              font-size: 18px;
              margin-bottom: 5px;
              font-weight: 700;
              padding-top: 40px;
              position: relative;
              float: left;
              margin-right: 10px;
            }

            .promoProps {
              border: 5px dotted $primaryColor;
            }

            li {
              display: block;
              margin: 0 0 8px;
              background: $scaleColor900;
              padding: 16px;
              border-radius: 12px;
              overflow: hidden;
              position: relative;

              @media (max-width: 640px) {
                margin: 0 0 16px;
              }

              .player {
                float: left;
                width: 50%;
                overflow: hidden;
                cursor: pointer;

                @media (max-width: 640px) {
                  float: none;
                  width: 100%;
                  margin-bottom: 12px;
                }

                img {
                  max-height: 64px;
                  max-width: 64px;
                  border-radius: 8px;
                  float: left;
                  margin-right: 24px;
                }

                h3 {
                  margin: 0;
                  font-size: 16px;
                  line-height: 17px;
                  font-weight: 700;
                  color: #fff;
                  cursor: pointer;

                  span {
                    margin-top: 5px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    color: $scaleColor300;
                    display: block;
                  }
                }

                h4 {
                  margin: 3px 0 0;
                  font-size: 12px;
                  line-height: 20px;
                  color: $scaleColor300;
                  font-weight: 400;
                }
              }

              .props {
                float: left;
                width: calc(50% - 132px);

                @media (max-width: 640px) {
                  float: none;
                  width: 100%;
                  margin-bottom: 12px;
                }

                h3 {
                  font-size: 14px;
                  line-height: 22px;
                  font-weight: 400;
                  color: $scaleColor300;
                  margin: 12px 0 0;

                  .prop-value {
                    font-size: 16px;
                    color: #fff;
                  }

                  @media (max-width: 640px) {
                    br {
                      display: none;
                    }
                  }
                }
              }

              .points {
                float: left;
                width: 132px;

                @media (max-width: 640px) {
                  float: none;
                  width: 100%;
                }

                button {
                  display: block;
                  height: 30px;
                  margin: 0 0 4px;
                  border-radius: 8px;
                  background: $scaleColor800;
                  width: 132px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #fff;
                  cursor: pointer;

                  @media (max-width: 640px) {
                    width: 100%;
                    margin: 0 0 8px;
                  }

                  &:last-child {
                    margin: 0;
                  }

                  &.active,
                  &:hover {
                    background: $primaryColor;
                  }
                }
              }

              .ice-pick {
                position: absolute;
                background: $primaryColor;
                height: 18px;
                font-weight: 600;
                line-height: 18px;
                font-size: 12px;
                padding: 0 15px;
                color: #fff;
                top: 20px;
                left: -20px;
                transform: rotateZ(-45deg);
              }
            }
          }
        }

        .totals {
          overflow: hidden;
          position: fixed;
          z-index: 20;
          bottom: 0;
          width: 100%;
          left: 0;
        //   background: $scaleColor1000;
          background: $scaleColor900;
          padding: 20px 0 20px;
          border-top: 1px solid $scaleColor900;
          box-shadow: 0 0 6px $scaleColor900;

          .inner-totals {
            margin: 0 auto;
            width: 100%;
            max-width: 1280px;
            padding: 0 32px;

            > div {
              float: left;
              font-size: 15px;
              line-height: 24px;
              font-weight: 400;
              color: $scaleColor400;
              margin-right: 23px;
              margin-top: 12px;

              span {
                font-weight: 700;
                color: #fff;
              }
            }

            button {
              float: right;
              width: 152px;
              height: 48px;
              background: $primaryColor;
              border-radius: 12px;
              text-transform: uppercase;
              font-size: 14px;
              color: #fff;
              font-weight: 700;
              cursor: pointer;

              @media (max-width: 640px) {
                margin: 12px 0 0;
                width: 45%;
              }

              &:hover {
                background: #fff;
                color: $primaryColor;
              }
            }
          }
        }
      }
    }

    .pay-modal {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 100;
      background: rgba(0, 34, 43, 0.6);
      display: none;
      align-items: center;
      text-align: center;

      &.show {
        display: flex;
      }

      .inner {
        margin: 0 auto;
        background: $scaleColor800;
        border-radius: 12px;
        border: 1px solid $scaleColor700;
        position: relative;

        h2 {
          margin: 0 0 20px;
          border-bottom: 1px solid $scaleColor700;
          font-size: 18px;
          font-weight: 700;
          color: #fff;
          padding: 20px 24px;
          text-align: left;
        }

        .close-pay-modal {
          position: absolute;
          top: 20px;
          right: 24px;
          height: 18px;
          width: 18px;
          background: transparent;
          background-image: url(../../assets/images/svg/close.svg);
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          cursor: pointer;
        }

        ul {
          padding: 0 12px 12px;

          h4 {
            text-align: left;
            color: white;
          }

          ul {
            border-top: 1px solid $scaleColor700;
            padding-top: 5px;

            li {
              display: grid;
              grid-template-columns: 50% 35%;
              grid-column-gap: 15%;

              .ticketLabels {
                text-align: left;
                color: white;
              }

              .ticketValues {
                color: white;
              }

              .houseTicketLabels {
                text-align: left;
                color: white;
              }

              .houseTicketValues {
                color: white;
              }
            }
          }
        }

        > div {
          overflow: hidden;
          text-align: left;
          padding: 0 24px 24px;

          > div {
            float: left;
            width: 33.3333%;
            padding: 0 12px;

            label {
              display: block;
              font-size: 12px;
              font-weight: 400;
              color: $scaleColor200;
              white-space: nowrap;
              line-height: 20px;
            }

            span {
              display: block;
              color: #fff;
              font-size: 18px;
              font-weight: 600;
              line-height: 28px;
            }

            input {
              text-align: center;
              background: transparent;
              width: 60px;
              color: #fff;
              font-size: 18px;
              font-weight: 600;
              -webkit-appearance: none;
              border: 1px solid #fff;
              padding: 1px 2px;
            }
          }
        }

        .pay-now {
          height: 40px;
          border-radius: 12px;
          line-height: 40px;
          background: $primaryColor;
          color: #fff;
          font-size: 14px;
          font-weight: 700;
          padding: 0 40px;
          margin-bottom: 24px;
          text-transform: uppercase;
          cursor: pointer;

          &:hover {
            background: #fff;
            color: $primaryColor;
          }
        }
      }
    }

    .no-results {
      text-align: center;
      color: $scaleColor900;
      background: transparent;
      float: left;
    //   width: calc(100% - 384px);
      width: 100%;

      @media (max-width: 1200px) {
        padding: 40px 0 40px 30px;
        // width: calc(100% - 320px);
        width: 100%;
      }

      @media (max-width: 1080px) {
        float: none;
        width: 100%;
        padding: 40px 0 40px;
        border: none;
      }

      > div {
        background-image: url(../../assets/images/not-found.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 160px;
        width: 173px;
        margin: 0 auto 30px;
      }

      h3 {
        font-size: 24px;
        font-family: "DINPro Bold";
        font-weight: 400;
        line-height: 32px;
        color: $secondaryTextColor;
      }

      span {
        font-size: 16px;
        font-weight: 400;
        color: $scaleColor200;
      }

      a {
        height: 40px;
        width: 144px;
        display: block;
        border: 1px solid $scaleColor200;
        border-radius: 12px;
        line-height: 38px;
        text-align: center;
        margin: 30px auto 0;
        font-size: 15px;
        color: #fff;

        &:hover {
          background: $primaryColor;
          border: 1px solid $primaryColor;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .props-lobby-page {
    background: $backdropGradient;
    background-image: url(../../assets/images/gradient.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top center;
    min-height: 100vh;
    padding-bottom: 100px;

    > .inner {
      margin: 0 auto;
      width: 100%;
      max-width: 1280px;
      padding: 40px 32px;

      @media (max-width: 640px) {
        padding: 0 24px;
      }

      &:after {
        display: block;
        clear: both;
        content: "";
      }

      .tabs-heads {
        // border-bottom: 1px solid $scaleColor900;
        border-bottom: 1px solid $scaleColor900;
        text-align: center;
        margin-bottom: 32px;

        button {
          background: transparent;
          padding: 0 0 16px 0;
          margin-right: 40px;
          margin-left: 40px;
          color: $secondaryTextColor;
          cursor: pointer;
          border-bottom: 2px solid $scaleColor1000;
          font-size: 18px;

          &.active {
            border-bottom: 2px solid $primaryColor;
            color: $secondaryTextColor;
          }

          @media (max-width: 750px) {
            font-size: 13px;
            margin-right: 10px;
          }
        }
      }

      .tabs-container-sport {
        border-bottom: 1px solid $scaleColor900;
        text-align: center;
        margin-bottom: 10px;
        margin-top: -20px;
        width: 100%;
      }

      .tabs-container-game {
        border-bottom: 1px solid $scaleColor900;
        text-align: center;
        margin-bottom: 10px;
        margin-top: 0px;
      }

      aside {
        float: left;
        width: 384px;
        padding: 0 32px 0 0;

        @media (max-width: 940px) {
          width: 150px;
        }

        @media (max-width: 1200px) {
          width: 320px;
          padding: 64px 30px 0 0;
        }

        @media (max-width: 1080px) {
          float: none;
          width: 100%;
          padding: 30px 0 30px;
        }

        @media (max-width: 640px) {
          padding: 16px 0;
        }

        .event-summary {
          border-radius: 12px;
          background: $scaleColor900;
          padding: 24px;
          margin: 0 0 16px;

          h2 {
            font-size: 24px;
            font-weight: 300;
            color: #fff;
            font-family: "DINPro Bold";
            margin: 0 0 8px;
          }

          p {
            font-size: 15px;
            line-height: 24px;
            font-weight: 400;
            color: $scaleColor200;
          }
        }

        @media (max-width: 1199px) {
          .listing {
            display: none !important;
            border: 1px solid red;
          }
        }
      }

      .event-props {
        float: left;
        width: calc(100% - 384px);

        @media (max-width: 1200px) {
          padding: 40px 0 40px 30px;
          width: calc(100% - 320px);
        }

        @media (max-width: 1080px) {
          float: none;
          width: 100%;
          padding: 0 0 40px;
          border: none;
        }

        .actions {
          overflow: hidden;
          border-bottom: 1px solid $scaleColor900;
          border-top: 1px solid $scaleColor900;
          margin: 0 0 24px;

          @media (max-width: 640px) {
            border: none;
            margin: 0 0 10px;
          }

          .left {
            float: left;

            @media (max-width: 640px) {
              float: none;
            }
          }

          .right {
            float: right;

            @media (max-width: 640px) {
              float: none;
            }
          }

          button {
            background: transparent;
            padding: 18px 0 18px 26px;
            margin-right: 40px;
            color: #fff;
            background-position: left center;
            background-repeat: no-repeat;
            cursor: pointer;
            white-space: nowrap;

            @media (max-width: 640px) {
              width: 50%;
              margin: 0;
              padding: 15px 26px;
              font-size: 14px;
              text-align: left;
            }

            &:hover {
              color: $primaryColor;
            }

            &.import-picks,
            &.re-enter-contest {
              background-image: url(../../assets/images/svg/import.svg);
            }

            &.export-picks,
            &.apply-similar {
              background-image: url(../../assets/images/svg/export.svg);
            }

            &.clear-selection,
            &.withdraw-contest {
              background-image: url(../../assets/images/svg/close2.svg);
            }

            &.invite-friends {
              background-image: url(../../assets/images/svg/add.svg);
            }

            &.edit-picks {
              background-image: url(../../assets/images/svg/info.svg);
            }
          }
        }

        .tab-making-picks-props-listing {
          margin: 0 0 16px;
          display: none;

          &.active {
            display: block;
          }

          .head {
            height: 36px;
            border-radius: 8px;
            background: $scaleColor900;
            padding: 0 16px;
            margin: 0 0 8px;

            @media (max-width: 640px) {
              display: none;
            }

            span {
              width: 50%;
              float: left;
              line-height: 36px;
              text-transform: uppercase;
              color: #fff;
              font-size: 12px;
              font-weight: 400;

              &:nth-child(2) {
                width: calc(50% - 132px);
              }

              &:last-child {
                width: 132px;
              }
            }
          }

          .listing {
            //border: 1px solid red;
            margin: 0;
            padding: 0;
            display: block;

            .promoText {
              color: $primaryColor;
              font-size: 18px;
              margin-right: 5px;
              padding-top: 40px;
              font-weight: 700;
              position: relative;
              float: right;
              margin-left: 10px;
            }
            .promoTextLeft {
              color: $primaryColor;
              font-size: 18px;
              margin-bottom: 5px;
              font-weight: 700;
              padding-top: 40px;
              position: relative;
              float: left;
              margin-right: 10px;
            }

            .promoProps {
              border: 5px dotted $primaryColor;
            }

            li {
              display: block;
              margin: 0 0 8px;
              background: $scaleColor900;
              padding: 16px;
              border-radius: 12px;
              overflow: hidden;
              position: relative;

              @media (max-width: 640px) {
                margin: 0 0 16px;
              }

              .player {
                float: left;
                width: 50%;
                overflow: hidden;
                cursor: pointer;

                @media (max-width: 640px) {
                  float: none;
                  width: 100%;
                  margin-bottom: 12px;
                }

                img {
                  max-height: 64px;
                  max-width: 64px;
                  border-radius: 8px;
                  float: left;
                  margin-right: 24px;
                }

                h3 {
                  margin: 0;
                  font-size: 16px;
                  line-height: 17px;
                  font-weight: 700;
                  color: #fff;
                  cursor: pointer;

                  span {
                    margin-top: 5px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    color: $scaleColor300;
                    display: block;
                  }
                }

                h4 {
                  margin: 3px 0 0;
                  font-size: 12px;
                  line-height: 20px;
                  color: $scaleColor300;
                  font-weight: 400;
                }
              }

              .props {
                float: left;
                width: calc(50% - 132px);

                @media (max-width: 640px) {
                  float: none;
                  width: 100%;
                  margin-bottom: 12px;
                }

                h3 {
                  font-size: 14px;
                  line-height: 22px;
                  font-weight: 400;
                  color: $scaleColor300;
                  margin: 12px 0 0;

                  .prop-value {
                    font-size: 16px;
                    color: #fff;
                  }

                  @media (max-width: 640px) {
                    br {
                      display: none;
                    }
                  }
                }
              }

              .points {
                float: left;
                width: 132px;

                @media (max-width: 640px) {
                  float: none;
                  width: 100%;
                }

                button {
                  display: block;
                  height: 30px;
                  margin: 0 0 4px;
                  border-radius: 8px;
                  background: $scaleColor800;
                  width: 132px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #fff;
                  cursor: pointer;

                  @media (max-width: 640px) {
                    width: 100%;
                    margin: 0 0 8px;
                  }

                  &:last-child {
                    margin: 0;
                  }

                  &.active,
                  &:hover {
                    background: $primaryColor;
                  }
                }
              }

              .ice-pick {
                position: absolute;
                background: $primaryColor;
                height: 18px;
                font-weight: 600;
                line-height: 18px;
                font-size: 12px;
                padding: 0 15px;
                color: #fff;
                top: 20px;
                left: -20px;
                transform: rotateZ(-45deg);
              }
            }
          }
        }

        .totals {
          overflow: hidden;
          position: fixed;
          z-index: 20;
          bottom: 0;
          width: 100%;
          left: 0;
        //   background: $scaleColor1000;
          background: $scaleColor900;
          padding: 20px 0 20px;
          border-top: 1px solid $scaleColor900;
          box-shadow: 0 0 6px $scaleColor900;

          .inner-totals {
            margin: 0 auto;
            width: 100%;
            max-width: 1280px;
            padding: 0 32px;

            > div {
              float: left;
              font-size: 15px;
              line-height: 24px;
              font-weight: 400;
              color: $scaleColor400;
              margin-right: 23px;
              margin-top: 12px;

              span {
                font-weight: 700;
                color: #fff;
              }
            }

            button {
              float: right;
              width: 152px;
              height: 48px;
              background: $primaryColor;
              border-radius: 12px;
              text-transform: uppercase;
              font-size: 14px;
              color: #fff;
              font-weight: 700;
              cursor: pointer;

              @media (max-width: 640px) {
                margin: 12px 0 0;
                width: 45%;
              }

              &:hover {
                background: #fff;
                color: $primaryColor;
              }
            }
          }
        }
      }
    }

    .pay-modal {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 100;
      background: rgba(0, 34, 43, 0.6);
      display: none;
      align-items: center;
      text-align: center;

      &.show {
        display: flex;
      }

      .inner {
        margin: 0 auto;
        background: $scaleColor800;
        border-radius: 12px;
        border: 1px solid $scaleColor700;
        position: relative;

        h2 {
          margin: 0 0 20px;
          border-bottom: 1px solid $scaleColor700;
          font-size: 18px;
          font-weight: 700;
          color: #fff;
          padding: 20px 24px;
          text-align: left;
        }

        .close-pay-modal {
          position: absolute;
          top: 20px;
          right: 24px;
          height: 18px;
          width: 18px;
          background: transparent;
          background-image: url(../../assets/images/svg/close.svg);
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          cursor: pointer;
        }

        > div {
          overflow: hidden;
          text-align: left;
          padding: 0 24px 24px;

          > div {
            float: left;
            width: 33.3333%;
            padding: 0 12px;

            label {
              display: block;
              font-size: 12px;
              font-weight: 400;
              color: $scaleColor200;
              white-space: nowrap;
              line-height: 20px;
            }

            span {
              display: block;
              color: #fff;
              font-size: 18px;
              font-weight: 600;
              line-height: 28px;
            }

            input {
              text-align: center;
              background: transparent;
              width: 60px;
              color: #fff;
              font-size: 18px;
              font-weight: 600;
              -webkit-appearance: none;
              border: 1px solid #fff;
              padding: 1px 2px;
            }
          }
        }

        .pay-now {
          height: 40px;
          border-radius: 12px;
          line-height: 40px;
          background: $primaryColor;
          color: #fff;
          font-size: 14px;
          font-weight: 700;
          padding: 0 40px;
          margin-bottom: 24px;
          text-transform: uppercase;
          cursor: pointer;

          &:hover {
            background: #fff;
            color: $primaryColor;
          }
        }
      }
    }

    .no-results {
      text-align: center;
    //   color: $scaleColor900;
      background: transparent;
      float: left;
    //   width: calc(100% - 384px);
     width: 100%;

      @media (max-width: 1200px) {
        padding: 40px 0 40px 30px;
        // width: calc(100% - 320px);
        width: 100%;
      }

      @media (max-width: 1080px) {
        float: none;
        width: 100%;
        padding: 40px 0 40px;
        border: none;
      }

      > div {
        background-image: url(../../assets/images/not-found.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 160px;
        width: 173px;
        margin: 0 auto 30px;
      }

      h3 {
        font-size: 24px;
        font-family: "DINPro Bold";
        font-weight: 400;
        line-height: 32px;
        color: $secondaryTextColor;
      }

      span {
        font-size: 16px;
        font-weight: 400;
        color: $scaleColor200;
      }

      a {
        height: 40px;
        width: 144px;
        display: block;
        border: 1px solid $scaleColor200;
        border-radius: 12px;
        line-height: 38px;
        text-align: center;
        margin: 30px auto 0;
        font-size: 15px;
        color: #fff;

        &:hover {
          background: $primaryColor;
          border: 1px solid $primaryColor;
        }
      }
    }
  }
}
