@import '../../assets/scss/colors';
.landing-page-header {
    width: 100%;
    padding: 22px 15px 0;
    position: absolute;
    top: 0;
    .inner {
        max-width: 1170px;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        margin-top: 15px;

        img {
            width: 170px;

        }
    }
    .mobile-open {
        display: none;
        @media (max-width: 765px) {
            background: transparent;
            position: absolute;
            top: 20px;
            right: 10px;
            display: block;
            padding: 20px;
            cursor: pointer;
        }
    }
    nav {
        // margin-top: 15px;
        float: right;
        @media (max-width: 765px) {
            position: absolute;
            z-index: 5;
            float: none;
            display: block;
            width: calc(100% - 30px);
            background: $secondaryColor;
            max-height: 0;
            overflow: hidden;
            transition: 0.2s all linear;
            &.open {
                max-height: 120px;
                transition: 0.2s all linear;
            }
        }
        ul {
            display: block;
            overflow: hidden;
            li {
                float: left;
                display: inline;
                @media (max-width: 765px) {
                    float: none;
                    display: block;
                    width: 100%;
                }
                > button {
                    cursor: pointer;
                    background: transparent;
                    display: block;
                    height: 40px;
                    line-height: 40px;
                    color: #fff;
                    font-size: 15px;
                    padding: 0 18px;
                    font-style: normal;
                    font-weight: normal;
                    &:hover {
                        text-decoration: none;
                        color: $primaryColor;
                    }
                }
                a {
                    display: block;
                    height: 40px;
                    line-height: 40px;
                    color: $secondaryTextColor;
                    font-size: 15px;
                    padding: 0 18px;
                    font-style: normal;
                    font-weight: normal;
                    &:hover {
                        text-decoration: none;
                        cursor:pointer;
                        color: $primaryColor;
                    }
                    &.blue-btn {
                        background: $primaryColor;
                        border-radius: 12px;
                        margin-left: 20px;
                        @media (max-width: 765px) {
                            margin-left: 0;
                        }
                        &:hover {
                            // background: #fff;
                            background: $scaleColor900;
                        }
                    }
                }
            }
        }
    }
}


