@import '../../assets/scss/colors';
.tab-container.versus-live {
    padding-top: 34px;
    .actions {
        overflow: hidden;
        border-bottom: 1px solid $scaleColor900;
        margin: 0 0 24px;
        @media (max-width: 640px) {
            border: none;
            margin: 0 0 10px;
        }
        .left {
            float: left;
            @media (max-width: 640px) {
                float: none;
            }
        }
        .right {
            float: right;
            @media (max-width: 640px) {
                float: none;
            }
        }
        button {
            background: transparent;
            padding: 18px 0 18px 26px;
            margin-right: 40px;
            color: $secondaryTextColor;
            background-position: left center;
            background-repeat: no-repeat;
            cursor: pointer;
            white-space: nowrap;
            @media (max-width: 640px) {
                width: 50%;
                margin: 0;
                padding: 15px 26px;
                font-size: 14px;
                text-align: left;
            }
            &:hover {
                color: $primaryColor;
            }
            &.import-picks,
            &.re-enter-contest {
                background-image: url(../../assets/images/svg/import.svg);
            }
            &.export-picks,
            &.apply-similar {
                background-image: url(../../assets/images/svg/export.svg);
            }
            &.clear-selection,
            &.withdraw-contest {
                background-image: url(../../assets/images/svg/close2.svg);
            }
            &.invite-friends {
                background-image: url(../../assets/images/svg/add.svg);
            }
            &.edit-picks {
                background-image: url(../../assets/images/svg/info.svg);
            }
        }
    }
    .making-picks-listing {
        margin: 0 0 16px;
        .head {
            height: 36px;
            border-radius: 8px;
            background: $scaleColor900;
            padding: 0 16px;
            margin: 0 0 8px;
            @media (max-width: 640px) {
                display: none;
            }
            span {
                width: calc(50% - 50px);
                float: left;
                line-height: 36px;
                text-transform: uppercase;
                color: #fff;
                font-size: 12px;
                font-weight: 400;
                text-align: center;
                &:nth-child(2) {
                    width: 100px;
                    text-align: left;
                    padding-left: 25px;
                }
            }
        }
        .listing {
            margin: 0;
            padding: 0;
            display: block;
            li {
                display: block;
                margin: 0 0 8px;
                background: $scaleColor900;
                padding: 16px;
                border-radius: 12px;
                overflow: hidden;
                position: relative;
                @media (max-width: 640px) {
                    margin: 0 0 16px;
                }
                .date {
                    padding-bottom: 20px;
                    h4 {
                        text-align: center;
                        margin: 0;
                        font-size: 16px;
                        line-height: 17px;
                        font-weight: 400;
                        color: #FFF;
                    }
                }
                .player {
                    float: left;
                    width: calc(50% - 50px);
                    cursor: pointer;
                    overflow: hidden;
                    @media (max-width: 640px) {
                        float: none;
                        width: 100%;
                        margin-bottom: 12px;
                    }
                    > span {
                        display: block;
                        font-size: 16px;
                        line-height: 17px;
                        color: #fff;
                        margin: 0 0 12px;
                        text-transform: uppercase;
                    }
                    img {
                        max-height: 64px;
                        max-width: 64px;
                        border-radius: 8px;
                        float: left;
                        margin-right: 24px;
                    }
                    h3 {
                        margin: 0;
                        font-size: 16px;
                        line-height: 17px;
                        font-weight: 700;
                        color: #FFF;
                        cursor: pointer;
                        span {
                            margin-top: 5px;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 22px;
                            color: $scaleColor300;
                            display: block;
                        }
                    }
                    h4 {
                        margin: 0;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 22px;
                        color: $scaleColor300;
                        display: block;
                    }
                   > div {
                       border: 1px solid $scaleColor700;
                       border-radius: 8px;
                       width: 100%;
                       margin: 15px auto 0;
                       text-align: center;
                       position: relative;
                       height: 37px;
                       div {
                           height: 37px;
                           line-height: 35px;
                           font-size: 13px;
                           font-weight: 600;
                           color: #fff;
                           border-radius: 8px;
                           width: 50%;
                           margin-top: -1px;
                           margin-left: -1px;
                           float: left;
                           &.green {
                               background: $successColor;
                           }
                           &.red {
                               background: $errorColor;
                           }
                           &.blue {
                               background: $primaryColor;
                           }
                       }
                       span {
                           height: 35px;
                           line-height: 35px;
                           font-size: 13px;
                           color: #fff;
                           width: 50%;
                           margin-left: -1px;
                           float: right;
                       }
                   }
                }
                .vs {
                    float: left;
                    width: 100px;
                    text-align: center;
                    @media (max-width: 640px) {
                        float: none;
                        display: block;
                        width: 100%;
                    }
                    span {
                        height: 40px;
                        line-height: 40px;
                        border-radius: 8px;
                        background: #334e55;
                        display: block;
                        width: 70px;
                        text-align: center;
                        font-size: 14px;
                        font-weight: 600;
                        color: #fff;
                        margin-top: 45px;
                        @media (max-width: 640px) {
                            margin: 25px auto;
                        }
                    }
                }
            }
        }
    }
}

