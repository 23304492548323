@import "../../assets/scss/colors";
.logged-in-header {
  padding: 15px 0;
  display: block;
  background: $scaleColor900;
  height: 80px;
  .inner {
    padding: 0 40px;
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
    .logo {
      float: left;
      display: inline;
    //   margin-top: 17px;
      margin-top: 8px;
    //   margin-right: 12px;
      img {
        display: block;
        width: 145px;
      }
    }
    .logged-in-nav {
      float: left;
      display: inline;
      margin-left: 13%;
      @media (min-width: 1200px) and (max-height: 1000px) {
        margin-left: 2%;
      }
      @media (min-width: 1300px) and (max-height: 1200px) {
        margin-left: 3%;
      }
      @media (max-width: 1250px) {
        margin-left: -2%;
      }
      @media (max-width: 1140px) {
        display: none;
      }
      @media (min-width: 1140px) and (max-width: 1400px) {
        // margin-left: -1%;
        margin-left: 0px;
      }
      ul {
        margin: 0;
        padding: 0;
        li {
          float: left;
          display: inline;
          margin: 0;
          padding: 13px 16px;
          @media (min-width: 1140px) and (max-width: 1340px) {
            margin-left: 3%;
            margin-right: -8%;
          }
          a {
            height: 24px;
            line-height: 24px;
            display: block;
            font-size: 15px;
            font-family: "Open Sans";
            color: $scaleColor300;
            position: relative;
            background: $scaleColor900;
            z-index: 2;
            &:hover {
              color: #fff;
            }
          }
          &.active {
            position: relative;
            a {
              color: #fff;
            }
            &:before {
              height: 54px;
              width: 2px;
              display: block;
              content: "";
              background: $primaryColor;
              position: absolute;
              top: -2px;
              left: 50%;
              margin-left: -1px;
              z-index: 1;
              transform: rotateZ(20deg);
            }
          }
        }
      }
    }
    .header-right {
      float: right;
      display: inline;
      @media (max-width: 1345px) and (max-height: 390px) {
        margin-top: 0%;
        margin-right: -7%;
        width: 45%;
      }
      @media (min-width: 1140px) {
        margin-left: -50%;
        margin-right: -4%;
        padding: 0px -10px 0px 1px;
        width: 60%;
      }
      @media (width: 1280px) and (height: 800px) {
        margin-top: 0%;
        width: 60%;
      }
      @media (width: 812px) {
        margin-right: -20%;
      }
      @media (width: 667px) {
        margin-right: -18%;
      }
      @media (width: 653px) {
        margin-right: -18%;
      }
      @media (width: 640px) {
        margin-right: -18%;
      }
      .notifications {
        float: left;
        background-image: url(../../assets/images/svg/notification.svg);
        background-repeat: no-repeat;
        background-position: center;
        padding: 20px;
        margin-right: 1px;
        margin-top: 6px;
        span {
          display: none;
        }
        @media (max-width: 1140px) {
          display: none;
        }
        @media (min-width: 1140px) {
          margin-left: -1%;
          margin-right: 5%;
        }
        @media (width: 1280px) and (height: 800px) {
          margin-left: -1%;
          margin-right: 2%;
          margin-top: 1%;
        }
        @media (width: 1366px) and (height: 1024px) {
          margin-right: 5%;
        }
      }
      .user-menu {
        float: left;
        display: inline;
        margin-top: 9px;
        margin-right: 2%;
        position: relative;
        @media (min-width: 1140px) {
          margin-left: -4%;
        }
        @media (max-width: 1140px) {
          margin-right: 20px;
        }
        @media (max-width: 460px) {
          position: static;
        }
        @media (width: 1280px) and (height: 800px) {
          margin-top: 1.5%;
          margin-left: 1%;
        }

        img {
          float: left;
          cursor: pointer;
        }

        span {
          float: left;
          margin-left: 14px;
          margin-top: 3%;
          font-size: 16px;
          color: #fff;
          padding-right: 22px;
          background-image: url(../../assets/images/svg/Rectangle.svg);
          background-repeat: no-repeat;
          background-position: right top 10px;
          cursor: pointer;
          @media (max-width: 1140px) {
            display: none;
          }
        }
        nav {
          position: absolute;
          background: $scaleColor900;
          top: 56px;
          display: block;
          max-height: 0;
          overflow: hidden;
          transition: 0.2s all linear;
          border-radius: 0 0 5px 5px;
          @media (max-width: 1140px) {
            margin-left: -50px;
          }
          @media (max-width: 460px) {
            width: 100%;
            margin: 0;
            left: 0;
            top: 80px;
            text-align: center;
          }
          &.open {
            transition: 0.2s all linear;
            max-height: 96px;
          }
          ul {
            margin: 0;
            padding: 0 20px;
            display: block;
            z-index: 20;
            li {
              display: block;
              margin: 0;
              padding: 0;
              a {
                display: block;
                padding: 15px;
                font-size: 15px;
                color: $scaleColor300;
                &:hover {
                  color: $primaryColor;
                }
              }
              button {
                background: transparent;
                padding: 15px;
                font-size: 15px;
                color: #fff;
                cursor: pointer;
                @media (max-width: 460px) {
                  color: $secondaryOrange;
                }
                &:hover {
                  color: $primaryColor;
                }
              }
              &:first-child {
                border-bottom: 1px solid $scaleColor900;
              }
            }
          }
        }
      }
      .ticket-modal {
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 100;
        background: rgba(0, 34, 43, 0.6);
        display: none;
        align-items: center;
        top: 0;
        left: 0;
        @media (max-width: 600px) {
          padding: 0 12px;
        }
        &.show {
          display: flex;
        }
        .inner {
          margin: 0 auto;
          background: $scaleColor800;
          border-radius: 12px;
          border: 1px solid $scaleColor700;
          position: relative;
          width: 25%;
          top: -20%;
          @media (max-width: 1200px) {
            width: 50%;
            margin-top: 10%;
          }
          @media (max-width: 700px) {
            height: 70%;
          }
          @media (max-width: 653px) {
            margin-top: 20%;
          }
          @media (max-width: 640px) {
            margin-top: 23%;
            height: 40%;
          }
          @media (max-width: 500px) {
            width: 90%;
          }
          @media (height: 320px) {
            height: 95%;
          }
          @media (height: 360px) {
            height: 75%;
          }
          @media (height: 280px) {
            height: 90%;
            margin-top: 15%;
          }
          h2 {
            margin: 0 0 20px;
            border-bottom: 1px solid $scaleColor700;
            font-size: 18px;
            font-weight: 700;
            color: #fff;
            padding: 20px 24px;
            text-align: center;
          }
          .close-ticket-modal {
            position: absolute;
            top: 20px;
            right: 24px;
            height: 18px;
            width: 18px;
            background: transparent;
            background-image: url(../../assets/images/svg/close.svg);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer;
          }
          ul {
            padding: 0 12px 12px;
            h4 {
              text-align: left;
              color: white;
            }
            ul {
              border-top: 1px solid $scaleColor700;
              padding-top: 5px;
              li {
                display: grid;
                grid-template-columns: 50% 35%;
                grid-column-gap: 15%;

                .ticketLabels {
                  text-align: left;
                  color: white;
                }
                .ticketValues {
                  color: white;
                }
                .houseTicketLabels {
                  text-align: left;
                  color: white;
                }
                .houseTicketValues {
                  color: white;
                }
              }
            }
          }
          > div {
            overflow: hidden;
            text-align: left;
            padding: 0 24px 24px;
            > div {
              float: left;
              width: 33.3333%;
              padding: 0 12px;
              label {
                display: block;
                font-size: 12px;
                font-weight: 400;
                color: $scaleColor200;
                white-space: nowrap;
                line-height: 20px;
              }
              span {
                display: block;
                color: #fff;
                font-size: 18px;
                font-weight: 600;
                line-height: 28px;
              }
              input {
                text-align: center;
                background: transparent;
                width: 60px;
                color: #fff;
                font-size: 18px;
                font-weight: 600;
                -webkit-appearance: none;
                border: 1px solid #fff;
                padding: 1px 2px;
              }
            }
          }

          p {
            color: white;
          }
        }
      }
      .ticket-indicator {
        background: transparent;
        color: white;
        float: left;
        margin-top: 10px;
        margin-right: 4%;
        height: 30px;
        line-height: 30px;
        margin-left: -2%;
        cursor: pointer;
        @media (max-width: 430px) {
          display: none;
        }
        @media (min-width: 1140px) and (max-width: 1200px) {
          margin-left: -5%;
        }
        @media (max-width: 1140px) {
          margin-left: -80%;
          margin-top: -38%;
        }
        @media (max-width: 800px) {
          margin-left: -80%;
          margin-top: -38%;
        }
        @media (max-width: 600px) {
          margin-left: -45%;
        }
        @media (width: 1280px) and (height: 800px) {
          margin-top: 1.5%;
          margin-left: -1%;
          margin-right: 1%;
        }
        @media (width: 812px) {
          margin-top: -11%;
          margin-left: -35%;
        }
        @media (width: 667px) {
          margin-top: -13%;
        }
        @media (width: 653px) {
          margin-top: -14%;
        }
        @media (width: 640px) {
          margin-top: -14%;
        }
        @media (width: 568px) {
          margin-top: -16%;
        }
      }
      .user-wallet {
        float: left;
        background-image: url(../../assets/images/svg/wallet.svg);
        background-position: left center;
        background-repeat: no-repeat;
        background-size: contain;
        padding-left: 40px;
        font-size: 15px;
        color: #fff;
        margin-top: 10px;
        margin-right: 16px;
        height: 30px;
        line-height: 30px;
        @media (max-width: 1140px) {
          display: none;
        }
      }
      .deposit-btn {
        height: 32px;
        border-radius: 12px;
        line-height: 32px;
        background: $successColor;
        color: #fff;
        font-size: 13px;
        font-weight: 600;
        float: left;
        display: inline;
        padding: 0 12px;
        margin-top: 1%;
        @media (max-width: 1140px) {
          display: none;
        }
        &:hover {
          background: $primaryColor;
        }
      }
      .affiliate-btn {
        height: 32px;
        border-radius: 12px;
        line-height: 32px;
        background: $primaryColor;
        color: #fff;
        font-size: 13px;
        font-weight: 600;
        float: left;
        display: inline;
        padding: 0 12px;
        margin-top: 8px;
        margin-right: 2%;
        @media (max-width: 1140px) {
          display: none;
        }
        @media (min-width: 1140px) {
          margin-left: 6%;
          width: auto;
        }
        @media (min-width: 1140px) and (max-width: 1400px) {
          width: 20%;
          margin-left: 10%;
          font-size: 60%;
          text-align: center;
        }
        &:hover {
          background: $primaryColor;
        }
      }
      .mobile-nav-btn {
        display: none;
        @media (max-width: 1140px) {
          background: transparent;
          display: block;
          padding: 10px;
          cursor: pointer;
        }
        @media (max-width: 850px) {
          margin-left: 40%;
        }
        @media (max-width: 700px) {
          margin-left: 50%;
        }
      }
    }
    .mobile-nav {
      border-radius: 0 0 3px 3px;
      display: none;
      max-height: 0;
      overflow: hidden;
      position: absolute;
      z-index: 20;
      right: 0;
      top: 80px;
      width: 320px;
      background: $scaleColor900;
      text-align: center;
      padding: 0 24px;

      @media (max-width: 1140px) {
        display: block;
        height: 100%;
      }
      @media (max-width: 460px) {
        width: 100%;
        top: 0;
        height: 95vh;
      }
      &.open {
        max-height: 700px;
        @media (max-height: 1030px) {
          max-height: 330px;
        }
        @media (max-height: 1000px) {
          max-height: 542px;
        }
        @media (max-height: 950px) {
          max-height: 540px;
        }
        @media (max-height: 700px) {
          max-height: 430px;
        }
        @media (max-height: 520px) {
          max-height: 435px;
        }
        @media (height: 1024px) {
          max-height: 450px;
        }
        @media (max-height: 430px) {
          max-height: 430px;
        }
        @media (max-height: 375px) {
          height: 430px;
        }
        @media (height: 768px) and (width: 1024px) {
          max-height: 430px;
        }
        @media (height: 1366px) {
          max-height: 430px;
        }
        @media (height: 720px) {
          max-height: 450px;
        }
        @media (width: 1024px) and (height: 600px) {
          max-height: 330px;
        }
        @media (height: 653px) {
          max-height: 330px;
        }
        @media (height: 540px) {
          max-height: 440px;
        }
        @media (height: 1114px) {
          max-height: 420px;
        }
        @media (height: 717px) {
          max-height: 430px;
        }
        @media (height: 600px) {
          max-height: 450px;
        }
        @media (max-width: 500px) and (max-height: 800px) {
          max-height: 540px;
        }
      }
      > .logo {
        display: none;
        @media (max-width: 460px) {
          display: inline;
          float: left;
          margin-top: 15px;
          margin-left: 16px;
        }
        img {
          width: 107px;
        }
      }
      > .close-mobile-nave {
        display: none;
        @media (max-width: 460px) {
          float: right;
          display: inline;
          background: transparent;
          background-image: url(../../assets/images/svg/close.svg);
          background-repeat: no-repeat;
          background-position: center;
          margin-top: 18px;
          width: 60px;
          height: 40px;
          cursor: pointer;
        }
      }
      ul {
        display: block;
        margin: 0 0 50px;
        clear: both;
        @media (max-width: 460px) {
          padding: 24px 0 0;
        }
        li {
          display: block;
          margin: 0;
          padding: 0;
          border-bottom: 1px solid $scaleColor900;
          text-align: center;
          &:first-child {
            border-top: 1px solid $scaleColor900;
          }
          a {
            display: block;
            height: 55px;
            line-height: 55px;
            font-size: 15px;
            font-weight: 400;
            color: $scaleColor300;
            &.active,
            &:hover {
              color: $primaryColor;
            }
          }
          .notifications {
            span {
              background-image: url(../../assets/images/svg/notification.svg);
              background-repeat: no-repeat;
              background-position: center;
              width: 20px;
              height: 21px;
              display: block;
              left: 50%;
              margin-left: -75px;
              margin-top: 18px;
              position: absolute;
            }
          }
        }
      }
      > div {
        position: absolute;
        bottom: 30px;
        width: calc(100% - 48px);
        @media (max-width: 1200px) {
          margin-bottom: -10%;
        }
        @media (min-width: 431px) {
          margin-bottom: -25%;
        }
        @media (min-width: 1000px) {
          margin-bottom: 80%;
        }
        @media (height: 1024px) {
          margin-bottom: -10%;
        }
        @media (max-height: 930px) {
          margin-bottom: -9%;
        }
        @media (height: 640px) {
          margin-bottom: -28%;
        }
        @media (height: 768px) {
          margin-bottom: -13%;
        }
        @media (max-height: 430px) {
          margin-bottom: -18%;
        }
        @media (height: 1366px) {
          margin-bottom: -12%;
        }
        @media (height: 1114px) {
          margin-bottom: -12%;
        }
        @media (max-height: 720px) {
          margin-bottom: -10%;
        }
        @media (max-height: 720px) and (min-width: 900px) {
          margin-bottom: -14%;
        }
        @media (height: 717px) {
          margin-bottom: -12%;
        }
        @media (height: 600px) {
          margin-bottom: -8%;
        }
        @media (max-width: 1000px) and (max-height: 400px) {
          margin-bottom: -15%;
        }
        .ticket-indicator {
          background: transparent;
          color: white;
          align-items: center;
          margin-top: 10px;
          margin-right: 16px;
          height: 30px;
          line-height: 30px;
          cursor: pointer;
          @media (max-width: 430px) {
            margin-top: 20%;
          }
          @media (min-width: 431px) {
            display: none;
          }
        }
        .user-wallet {
          background-image: url(../../assets/images/svg/wallet.svg);
          background-position: left center;
          background-repeat: no-repeat;
          padding-left: 40px;
          font-size: 15px;
          color: #fff;
          display: inline-block;
          margin: 0 0 24px;
          @media (max-width: 430px) {
            margin-top: 20%;
          }
          @media (min-width: 431px) {
            margin-bottom: 18%;
            margin-left: -5%;
          }
          @media (min-width: 400px) {
            margin-bottom: 10%;
          }
          @media (max-width: 700px) and (max-height: 400px) {
            margin-bottom: 10%;
          }
          @media (min-width: 700px) and (max-height: 450px) {
            margin-bottom: 10%;
          }
          @media (min-width: 750px) and (min-height: 1000px) {
            margin-bottom: 50%;
          }
          @media (max-width: 1100px) and (min-height: 1300px) {
            margin-bottom: 25%;
          }
          @media (height: 720px) {
            margin-bottom: 5%;
          }
          @media (height: 720px) and (max-width: 600px) {
            margin-bottom: 12%;
          }
          @media (min-width: 1000px) and (max-height: 650px) {
            margin-bottom: 25%;
          }
          @media (min-width: 1000px) and (max-height: 1000px) {
            margin-bottom: 25%;
          }
          @media (max-width: 800px) and (min-height: 1100px) {
            margin-bottom: 47%;
          }
          @media (height: 717px) {
            margin-bottom: 10%;
          }
        }
        .affiliates {
          height: 40px;
          border-radius: 12px;
          line-height: 40px;
          background: $primaryColor;
          color: #fff;
          font-size: 15px;
          font-weight: 600;
          display: block;
          margin-bottom: 24px;
          &:hover {
            background: $primaryColor;
          }
          @media (min-width: 431px) {
            margin-top: -5%;
            // display: none;
          }
          @media (min-height: 1000px) and (max-width: 1000px) {
            margin-top: -45%;
          }
          @media (min-width: 1000px) {
            margin-top: -20%;
          }
          @media (min-width: 1000px) and (max-height: 650px) {
            margin-bottom: 25%;
          }
          @media (max-width: 1100px) and (min-height: 1200px) {
            margin-bottom: 24%;
          }
          @media (max-width: 1000px) and (min-height: 400px) {
            margin-bottom: 4%;
          }
          @media (min-width: 1000px) and (max-height: 1000px) {
            margin-bottom: 25%;
          }
        }
        .deposit-btn {
          height: 40px;
          border-radius: 12px;
          line-height: 40px;
          background: $primaryColor;
          color: #fff;
          font-size: 15px;
          font-weight: 600;
          display: block;
          margin-bottom: 24px;
          &:hover {
            background: $primaryColor;
          }
          @media (min-width: 431px) {
            // margin-top: 5%;
          }
          @media (min-width: 1000px) {
            margin-top: -20%;
          }
        }
      }
    }
  }
}
