@import '../../assets/scss/colors';
.tab-container.my-contest  {
    padding-top: 34px;
    .making-picks-listing {
        margin: 0 0 16px;
        .head {
            height: 36px;
            border-radius: 8px;
            background: $scaleColor900;
            padding: 0 16px;
            margin: 0 0 8px;
            @media (max-width: 640px) {
                display: none;
            }
            span {
                float: left;
                line-height: 36px;
                text-transform: uppercase;
                color: #fff;
                font-size: 12px;
                font-weight: 400;
                width: 15%;
                &:nth-child(1) {
                    width: 36%;
                }
                &:nth-child(4) {
                    width: 34%;
                }
            }
        }
        .listing {
            margin: 0;
            padding: 0;
            display: block;
            li {
                display: block;
                margin: 0 0 8px;
                background: $scaleColor900;
                padding: 16px;
                border-radius: 12px;
                overflow: hidden;
                position: relative;
                @media (max-width: 640px) {
                    margin: 0 0 16px;
                }
                .player {
                    float: left;
                    width: 36%;
                    overflow: hidden;
                    cursor: pointer;
                    @media (max-width: 640px) {
                        float: none;
                        width: 100%;
                        margin-bottom: 12px;
                    }
                    img {
                        max-height: 64px;
                        max-width: 64px;
                        border-radius: 8px;
                        float: left;
                        margin-right: 24px;
                    }
                    h3 {
                        margin: 12px 0 0;
                        font-size: 16px;
                        line-height: 17px;
                        font-weight: 700;
                        color: #FFF;
                        cursor: pointer;
                        span {
                            margin-top: 5px;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 22px;
                            color: $scaleColor300;
                            display: block;
                        }
                    }
                }
                .props {
                    float: left;
                    width: 15%;
                    @media (max-width: 640px) {
                        width: 50%;
                        margin-bottom: 12px;
                    }
                    h3 {
                        font-size: 14px;
                        line-height: 22px;
                        font-weight: 400;
                        color: $scaleColor300;
                        margin: 12px 0 0;
                        @media (max-width: 640px) {
                            &:before {
                                content: 'Props:';
                                margin-right: 5px;
                            }
                        }
                        .prop-value {
                            font-size: 16px;
                            color: #FFF
                        }

                        @media (max-width: 640px) {

                        }
                    }
                    h4 {
                        margin: 3px 0 0;
                        font-size: 12px;
                        line-height: 20px;
                        color: $scaleColor300;
                        font-weight: 400;
                        background-image: url(../../assets/images/svg/calendar.svg);
                        background-position: left center;
                        background-repeat: no-repeat;
                        padding-left: 27px;
                    }
                }
                .live-points {
                    float: left;
                    width: 15%;
                    @media (max-width: 640px) {
                        width: 50%;
                        margin-bottom: 12px;
                    }
                    .mobile {
                        display: none;
                        @media (max-width: 640px) {
                            display: block;
                            font-size: 14px;
                            line-height: 22px;
                            font-weight: 400;
                            color: $scaleColor300;
                            float: left;
                        }
                    }
                    span {
                        height: 35px;
                        line-height: 35px;
                        background: $scaleColor800;
                        display: block;
                        width: 50px;
                        text-align: center;
                        border-radius: 8px;
                        margin-top: 14px;
                        color: #fff;
                        @media (max-width: 640px) {
                            margin: 15px 0 0;
                            float: right;
                        }
                    }
                }
                .points {
                    float: left;
                    width: 34%;
                    @media (max-width: 640px) {
                        clear: both;
                        float: none;
                        width: 100%;
                    }
                   > div {
                       margin-top: 14px;
                       height: 35px;
                       line-height: 33px;
                       border: 1px solid $scaleColor700;
                       border-radius: 8px;
                       font-size: 13px;
                       color: #fff;
                       span {
                           width: 50%;
                           height: 35px;
                           line-height: 35px;
                           font-size: 13px;
                           font-weight: 600;
                           color: #fff;
                           text-align: center;
                           float: left;
                           margin-top: -1px;
                           margin-left: -1px;
                           border-radius: 8px;
                           &.green {
                               background: $successColor;
                           }
                           &.red {
                               background: $errorColor;
                           }
                           &.blue {
                               background: $primaryColor;
                           }
                       }
                       > div {
                           float: left;
                           width: 50%;
                           text-align: center;
                       }
                   }
                }
                .ice-pick {
                    position: absolute;
                    background: $primaryColor;
                    height: 18px;
                    font-weight: 600;
                    line-height: 18px;
                    font-size: 12px;
                    padding: 0 15px;
                    color: #fff;
                    top: 20px;
                    left: -20px;
                    transform: rotateZ(-45deg);
                }
            }
        }
    }
    hr {
        border: none;
        border-bottom: 1px solid $scaleColor900;
        border-top: 0;
        margin-left: -68px;
    }
}

