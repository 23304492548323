@font-face {
  font-family: 'DINPro Bold';
  src: url('../fonts/dinprobold.woff') format('woff');
}

@font-face {
  font-family: 'DINPro-Regular';
  src: url('../fonts/DINPro-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'DINPro-Medium';
  src: url('../fonts/DINPro-Medium.otf') format('opentype');

}
@font-face {
  font-family: 'DINPro-Light';
  src: url('../fonts/DINPro-Light.otf') format('opentype');
}