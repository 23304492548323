@import "../../assets/scss/colors";
.landing-page {
  background: url("../../assets/images/bg1.jpg") no-repeat;
//   background: $secondaryColor;
//   background: $backdropGradient;
  background-size: 100% calc(100% + 45px);
  // background-position: top -45px center;
  .landing-section-1 {
    // background: url("../../assets/images/bg1.jpg") no-repeat;
    // background-size: 100% calc(100% + 45px);
    // background-position: top -45px center;
    padding-top: 140px;
    overflow: hidden;
    z-index: -2;
    @media (max-width: 564px) {
      padding-bottom: 150px;
    }
    @media (max-height: 500px) {
      padding-top: 100px;
    }
    .promo-logo {
      margin-top: -100px;
      height: 80px;

      .partner-text {
        font-size: 15px;
        margin-right: 50%;
        @media (max-width: 1050px) {
          font-size: 10px;
          margin-top: 5%;
          margin-left: -15%;
          margin-right: 25%;
        }
        @media (max-width: 1000px) and (max-height: 500px) {
          margin-top: 1%;
          margin-left: -20%;
        }
        .jags-logo {
          width: 7%;
          height: auto;
          object-fit: contain;
          @media (max-width: 1200px) {
            width: 10%;
            height: auto;
            object-fit: contain;
          }
        }

        .chargers-logo {
          width: 12%;
          height: auto;
          margin-left: -2%;
          @media (max-width: 1200px) {
            width: 17%;
            height: auto;
            margin-left: -3%;
          }
        }
      }
    }
    .ls1-background-image {
      position: absolute;
      width: 100%;
      height: 600px;
      background-size: cover;
      z-index: 1;
    }
    .inner-home {
      padding: 0vh 10px 190px 10px;
      margin: 0 auto;
      float: left;
      min-width: 50%;
      position: relative;
      z-index: 2;
      @media (max-width: 1340px) {
        width: calc(100% - 680px);
      }
      @media (max-width: 1250px) {
        padding: 50px 50px;
      }
      @media (max-width: 1050px) {
        display: block;
        float: none;
        width: 100%;
        max-width: 640px;
      }
      @media (max-width: 430px) {
        padding-top: 2vh;
        padding-bottom: 3vh;
      }
      @media (max-height: 430px) {
        padding-top: 5vh;
        padding-bottom: 10px;
      }
      h1 {
        font-size: 40px;
        line-height: 58px;
        text-align: center;
        letter-spacing: -0.03em;
        font-weight: 400;
        font-family: "DINPro Bold";
        color: $secondaryTextColor;
        margin: 0;
        @media (max-width: 1250px) {
          font-size: 38px;
          margin: 0 auto;
          max-width: 350px;
        }
        @media (max-width: 1050px) {
          max-width: 800px;
          margin: 0;
          font-size: 48px;
        }
        @media (max-width: 500px) {
          font-size: 37px;
          line-height: 50px;
        }
      }
      p {
        font-style: normal;
        font-size: 18px;
        line-height: 30px;
        color: #fff;
        font-weight: 700;
        margin: 24px 0 40px 0px;
        text-align: center;
      }
      .btns ul {
        display: block;
        margin: 0 auto;
        padding: 0;
        text-align: center;
        max-width: 570px;
        overflow: hidden;
        li {
          padding-right: 5px;
          padding-left: 5px;
          float: left;
          display: inherit;
          margin-bottom: 15px;
          width: 50%;
          &:first-child {
            width: 100%;
            a {
              background: $primaryColor;
              &:hover {
                // background: transparent;
                background: $scaleColor900;
              }
            }
          }
          button {
            background: rgb(22, 22, 22);
            border-color: white;
            border: 1px solid $scaleColor800;
            box-sizing: border-box;
            border-radius: 12px;
            display: flex;
            min-height: 64px;
            align-items: center;
            text-align: center;
            width: 220px;
            color: #fff;
            justify-content: center;
            margin-left: 25px;
            @media (max-width: 1200px) {
              margin-left: 0.5px;
              width: 150px;
              @media (max-height: 430px) {
                margin-left: 30px;
                width: 200px;
              }
              @media (width: 768px) and (max-height: 1030px) {
                margin-left: 20%;
              }
              @media (width: 1024px) and (max-height: 770px) {
                margin-left: 20%;
              }
              @media (min-width: 1024px) and (max-height: 1366px) {
                margin-left: 20%;
              }
              @media (width: 568px) and (height: 320px) {
                margin-left: 5%;
              }
              @media (width: 720px) and (height: 1114px) {
                margin-left: 20%;
              }
              @media (width: 720px) and (height: 540px) {
                margin-left: 20%;
              }
              @media (width: 540px) and (height: 720px) {
                margin-left: 15%;
              }
              @media (width: 512px) and (height: 717px) {
                margin-left: 10%;
              }
            }
            span {
              margin-left: 10px;
              text-align: left;
              font-style: normal;
              font-weight: bold;
              font-size: 15px;
              cursor: pointer;
            }
            small {
              display: block;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              color: $scaleColor200;
            }
          }
          a {
            width: 100%;
            border: 1px solid $scaleColor800;
            box-sizing: border-box;
            border-radius: 65px;
            display: flex;
            min-height: 82px;
            align-items: center;
            text-align: center;
            color: #fff;
            justify-content: center;
            &:hover {
              text-decoration: none;
              background: $primaryColor;
            }
            span {
              text-align: left;
              font-style: normal;
              font-weight: bold;
              font-size: 35px;
              font-family: Arial, Helvetica, sans-serif;
              cursor: pointer;
              small {
                display: block;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                color: $scaleColor200;
              }
            }
          }
        }
      }
      #super-bowl-contest {
        margin: 20px 0px 15px 0px;
        font-size: 32px;
        line-height: 32px;
        color: $primaryColor;
        font-weight: bold;
        font-style: italic;
        @media (max-width: 1250px) {
          margin-left: auto;
          margin-right: auto;
          font-size: 26px;
          max-width: 350px;
        }
        @media (max-width: 500px) {
          max-width: 800px;
          margin: 20px 0px 2vh 0px;
          font-size: 25px;
          // font-size: 28px
          line-height: 36px;
        }
        @media (max-height: 500px) {
          max-width: 800px;
          font-size: 26px;
          margin: 5px 0px 2vh 0px;
        }
      }
      #deposit-match-message {
        margin: 20px 0px 15px 0px;
        font-size: 28px;
        line-height: 32px;
        color: $primaryColor;
        font-weight: bold;
        font-style: italic;
        @media (max-width: 1250px) {
          margin-left: auto;
          margin-right: auto;
          font-size: 26px;
          max-width: 350px;
        }
        @media (max-width: 500px) {
          max-width: 800px;
          margin: 20px 0px 2vh 0px;
          font-size: 20px;
          // font-size: 28px
          line-height: 36px;
        }
        @media (max-height: 500px) {
          max-width: 800px;
          font-size: 26px;
          margin: 5px 0px 2vh 0px;
        }
      }
      #thrive-tagline {
        font-size: 26px;
        margin: 20px 0px 25px 0px;
        @media (max-height: 500px) {
          font-size: 22px;
          margin: 0px 0px 4vh 0px;
        }
        @media (max-width: 500px) {
          margin: 0px 0px 4vh 0px;
        }
      }
    }
    h3 {
      color: $secondaryTextColor;
      text-align: center;
      font-style: italic;
      @media (max-width: 1200px) {
        font-size: 18px;
      }
    }
    .landing-slider {
      width: 50%;
      max-width: 50%;
      height: 88vh;
      margin: 0 auto;
      padding: 0px 15px 90px 15px;
      position: relative;
      float: left;
      min-height: 600px;
      z-index: 2;
      @media (max-width: 1050px) {
        width: 100%;
        max-width: 800px;
        display: block;
        float: none;
      }
      @media (width: 1280px) and (height: 800px) {
        img {
          margin-left: -30%;
        }
      }
      @media (width: 1366px) and (height: 1024px) {
        img {
          margin-left: -25%;
        }
      }
      @media (width: 1114px) and (height: 720px) {
        img {
          margin-left: -42%;
        }
      }
      @media (max-width: 865px) {
        padding: 50px 15px 11px 15px;
        min-width: 0;
      }

      > div {
        width: 620px;
        height: 100%;
        padding: 0 48px;
        margin: 0 auto;
        position: relative;
        @media (max-width: 765px) {
          width: 520px;
        }
        @media (max-width: 564px) {
          width: 360px;
          padding: 0;
        }
        @media (max-width: 400px) {
          width: 300px;
          padding: 0;
        }
        > .prev {
          position: absolute;
          display: block;
          height: 48px;
          width: 48px;
          background: url(../../assets/images/left.png) no-repeat;
          background-size: contain;
          left: 0;
          top: 50%;
          margin-top: -24px;
          cursor: pointer;
          @media (max-width: 564px) {
            top: auto;
            bottom: -70px;
            margin-top: 0;
            left: 50%;
            margin-left: -80px;
          }
        }
        > .next {
          position: absolute;
          display: block;
          height: 48px;
          width: 48px;
          background: url(../../assets/images/right.png) no-repeat;
          background-size: contain;
          right: 0;
          top: 50%;
          margin-top: -24px;
          cursor: pointer;
          @media (max-width: 564px) {
            top: auto;
            bottom: -70px;
            margin-top: 0;
            right: 50%;
            margin-right: -80px;
          }
        }
        > .prev.disabled,
        > .next.disabled {
          opacity: 0.25;
        }
        ul {
          display: block;
          position: relative;
          margin: 0 auto;
          padding: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
          @media (max-width: 765px) {
            width: 424px;
          }
          @media (max-width: 564px) {
            width: 360px;
          }
          @media (max-width: 400px) {
            width: 300px;
          }
          li {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            text-align: center;
            transition: 0.2s all linear;
            img {
              height: 100%;
              width: auto;
            }
            @media (max-width: 765px) {
              width: 424px;
            }
            @media (max-width: 564px) {
              width: 360px;
            }
            @media (max-width: 400px) {
              width: 300px;
            }
            &.active {
              left: 0;
              transition: 2s all linear;
            }
            &.next {
              left: 100%;
              transition: 2s all linear;
            }
            &.prev {
              left: -100%;
              transition: 2s all linear;
            }
            @media (max-width: 400px) {
              img {
                display: block;
                width: 100%;
              }
            }
          }
        }
      }
      .landing-slider-interface {
        width: 75%;
        min-width: 395px;
      }
    }
    > img {
      display: block;
      max-width: 100%;
      height: auto;
    }
    .sportSpecific {
      width: 302px;
      height: 654px;
    }
    .nfl {
      position: absolute;
      top: 20%;
      right: 15%;
      @media (max-width: 564px) {
        width: 56px;
        right: auto;
        top: 0;
        left: -5px;
      }
    }
    .nba {
      position: absolute;
      left: 0;
      top: 40%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      @media (max-width: 765px) {
        top: 30%;
        right: 0px;
      }
      @media (max-width: 564px) {
        width: 64px;
        left: 90%;
        top: auto;
        bottom: 90px;
      }
    }
    .dota {
      position: absolute;
      bottom: 20%;
      right: 0;
      @media (max-width: 564px) {
        width: 48px;
        right: auto;
        bottom: 0;
        left: -4px;
      }
    }
  }
  .landing-section-2 {
    // background: url("../../assets/images/bg1.jpg") no-repeat;
    // background: $secondaryColor;
    background-size: 100% calc(100% + 45px);
    background-position: top -45px center;
    padding: 0 0 60px;
    clear: both;
    margin-bottom: -5%;
    @media (max-width: 1200px) {
      margin-top: -200px;
    }
    .inner {
      width: 100%;
      max-width: 1170px;
      padding: 0 15px;
      margin: 0 auto;
      text-align: center;
      iframe {
        width: 100%;
        @media (max-width: 850px) {
          width: 100%;
        }
      }
      .header {
        color: $secondaryTextColor;
        text-decoration: underline;
        font-size: 40px;
        font-style: italic;
      }
      .reasons {
        color: $secondaryTextColor;
        text-align: center;
        @media (max-width: 1200px) {
          font-size: 22px;
          margin: 5%;
        }
        @media (max-width: 1000px) and (max-height: 500px) {
          white-space: nowrap;
          font-size: 22px;
        }
      }
      h1 {
        text-decoration: underline;
      }
      h2 {
        font-family: "DINPro Bold";
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 52px;
        letter-spacing: -0.02em;
        color: $secondaryTextColor;
      }
      h6 {
        color: $primaryColor;
        font-style: italic;
        @media (width: 428px) and (height: 926px) {
          font-size: 10px;
        }
      }
      #how-to-play {
        font-style: italic;
        color: white;
        text-decoration: underline;
        @media (min-width: 1300px) and (max-height: 1200px) {
          margin-top: 5%;
        }
      }
      ul {
        overflow: hidden;
        display: block;
        margin: 0;
        padding: 50px 0 100px;
        @media (max-width: 765px) {
          padding-top: 0;
        }

        li {
          float: left;
          width: 33.33333%;
          display: inline;
          padding: 0 45px;
          @media (max-width: 765px) {
            float: none;
            width: 100%;
            display: block;
            margin: 0 0 20px;
          }
          div {
            min-height: 153px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            margin-bottom: 30px;
            img {
              display: block;
            }
          }
          h3 {
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 32px;
            text-align: center;
            color: $secondaryTextColor;
            margin-top: 15px;
            font-family: "DINPro Bold";
          }
          p {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
            text-align: center;
            color: $secondaryTextColor;
            margin-top: 15px;
          }
        }
      }
      > p {
        font-size: 18px;
        color: #fff;
        line-height: 1.5;
        font-style: italic;
      }
    }
  }
  .landing-section-3 {
    // background: url("../../assets/images/bg1.jpg") no-repeat;
    // background-size: 100% calc(100% + 45px);
    // background-position: top -45px center;
    @media (max-width: 765px) {
      padding-top: 0;
      height: auto;
    }
    @media (max-width: 564px) {
      overflow: hidden;
      padding-bottom: 100px;
    }
    .inner {
      width: 100%;
      max-width: 1170px;
      padding: 0 15px;
      margin: 0 auto;
      text-align: center;
      @media (max-width: 564px) {
        padding: 0;
      }
      h2 {
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 52px;
        align-items: center;
        letter-spacing: -0.02em;
        color: #ffffff;
        font-family: "DINPro Bold";
        margin-bottom: 20px;
      }
      h3 {
        color: white;
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        color: #ffffff;
        opacity: 0.8;
        margin: 0px 0px 15px 0px;

        margin: 0px;
        @media (max-width: 564px) {
          padding: 0 15px;
        }
      }
      #rollover-warning {
        margin-bottom: 10px;
      }
      .btns ul {
        display: block;
        margin: 0;
        padding: 0;
        text-align: center;
        @media (max-width: 564px) {
          padding: 0 15px;
        }
        li {
          display: inline-block;
          padding-right: 5px;
          padding-left: 5px;
          @media (max-width: 564px) {
            margin-bottom: 15px;
          }
          a {
            background: rgb(22, 22, 22);
            border-color: white;
            border: 1px solid $scaleColor800;
            box-sizing: border-box;
            border-radius: 12px;
            display: flex;
            min-height: 64px;
            align-items: center;
            text-align: center;
            width: 165px;
            color: #fff;
            justify-content: center;
            &:hover {
              text-decoration: none;
              background: $primaryColor;
            }
            span {
              margin-left: 10px;
              text-align: left;
              font-style: normal;
              font-weight: bold;
              font-size: 15px;
              small {
                display: block;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                color: $scaleColor200;
              }
            }
          }
        }
      }
      .img {
        padding-top: 70px;
        @media (max-width: 564px) {
          padding-left: 30px;
        }
        img {
          display: block;
          max-width: 100%;
          margin: 0 auto;
          &.showOnMobile {
            display: none;
          }
          @media (max-width: 564px) {
            &.hideOnMobile {
              display: none;
            }
            &.showOnMobile {
              display: block;
              width: 100%;
            }
          }
        }
      }
    }
  }
  .testimonials {
    text-decoration: underline;
    font-style: italic;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      padding: 50px;
      @media (max-width: 1000px) {
        padding-top: 120px;
      }
    }
    h1 {
      font-size: 30px;
    }
  }
  .landing-review-slider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    max-width: 50%;
    margin: 0 auto;
    padding: 0px 15px 90px 15px;
    position: relative;
    float: left;
    z-index: 2;
    color: white;
    @media (max-width: 1050px) {
      width: 100%;
      max-width: 800px;
      display: block;
      float: none;
    }
    @media (max-width: 865px) {
      padding: 50px 15px 11px 15px;
      min-width: 0;
    }
    > div {
      width: 620px;
      height: 50%;
      padding: 0 48px;
      margin: 0 auto;
      position: relative;
      @media (max-width: 765px) {
        width: 520px;
      }
      @media (max-width: 564px) {
        width: 360px;
        padding: 0;
      }
      @media (max-width: 400px) {
        width: 300px;
        padding: 0;
      }
      > .prev {
        position: absolute;
        display: block;
        height: 48px;
        width: 48px;
        background: url(../../assets/images/left.png) no-repeat;
        background-size: contain;
        left: 0;
        top: 50%;
        margin-top: -24px;
        cursor: pointer;
        @media (max-width: 564px) {
          top: auto;
          bottom: -70px;
          margin-top: 0;
          left: 50%;
          margin-left: -80px;
        }
      }
      > .next {
        position: absolute;
        display: block;
        height: 48px;
        width: 48px;
        background: url(../../assets/images/right.png) no-repeat;
        background-size: contain;
        right: 0;
        top: 50%;
        margin-top: -24px;
        cursor: pointer;
        @media (max-width: 564px) {
          top: auto;
          bottom: -70px;
          margin-top: 0;
          right: 50%;
          margin-right: -80px;
        }
      }
      > .prev.disabled,
      > .next.disabled {
        opacity: 0.25;
      }
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        // margin: 0 auto;
        padding: 0px 0px 275px !important;
        width: 100%;
        // height: 100%;
        overflow: hidden;
        @media (max-width: 1000px) and (max-height: 430px) {
          width: 100%;
        }
        @media (max-width: 765px) {
          width: 424px;
        }
        @media (max-width: 564px) {
          width: 360px;
        }
        @media (max-width: 400px) {
          width: 300px;
        }
        li {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          text-align: center;
          transition: 0.2s all linear;
          margin: 13.5%;
          margin-top: -2%;
          img {
            width: 100%;
            margin-left: 1%;
            @media (min-width: 1200px) {
              width: 900px;
              height: 300px;
              margin-left: -80%;
              margin-top: 5%;
            }
            @media (width: 1280px) and (height: 800px) {
              width: 450px;
              height: 150px;
              margin-left: -190%;
              margin-top: 120%;
            }
            @media (width: 1114px) and (height: 720px) {
              width: 600%;
              margin-left: -75%;
            }
            @media (width: 1024px) and (max-height: 800px) {
              width: 650px;
              margin-left: -90%;
              margin-top: 10%;
            }
            @media (width: 1024px) and (height: 1366px) {
              width: 650px;
              margin-left: -90%;
              margin-top: 20%;
            }
            @media (max-width: 1000px) and (max-height: 500px) {
              width: 450px;
              margin-left: -15%;
              margin-top: 40%;
            }
            @media (width: 768px) and (max-height: 1030px) {
              width: 650px;
              margin-left: -130%;
              margin-top: 20%;
            }
            @media (width: 736px) and (height: 414px) {
              width: 100%;
              margin-left: 37%;
              margin-top: 28%;
            }
            @media (width: 736px) and (height: 414px) {
              width: 75%;
            }
            @media (width: 731px) and (height: 411px) {
              width: 75%;
              margin-left: 35%;
            }
            @media (width: 720px) and (height: 540px) {
              width: 300px;
              margin-left: 25%;
              margin-top: 25%;
            }
            @media (width: 720px) and (height: 1114px) {
              width: 80%;
              margin-left: 30%;
              margin-top: 25%;
            }
            @media (width: 717px) and (height: 512px) {
              width: 280px;
              margin-left: -5%;
              margin-right: -45%;
            }
            @media (width: 667px) and (height: 375px) {
              width: 95%;
              margin-left: -12%;
              margin-top: 20%;
              margin-right: -40%;
            }
            @media (width: 653px) and (height: 280px) {
              width: 75%;
              margin-left: 23%;
              margin-top: 28%;
            }
            @media (width: 640px) and (height: 360px) {
              width: 100%;
              margin-left: 10%;
              margin-top: 22%;
            }
            @media (width: 568px) and (height: 320px) {
              width: 75%;
              margin-left: -2%;
              margin-top: 28%;
            }
            @media (width: 540px) and (height: 720px) {
              width: 80%;
              margin-left: 33%;
            }
            @media (width: 512px) and (height: 717px) {
              width: 85%;
              margin-left: 25%;
            }
            @media (width: 428px) and (height: 926px) {
              width: 380px;
              margin-top: 20%;
              margin-left: -23%;
            }
          }
          @media (max-width: 950px) {
            width: 100%;
          }
          @media (max-width: 765px) {
            width: 424px;
          }
          @media (max-width: 564px) {
            width: 360px;
          }
          @media (max-width: 400px) {
            width: 300px;
          }
          &.active {
            left: 0;
            transition: 0.6s all linear;
          }
          &.next {
            left: 100%;
            transition: 0.3s all linear;
          }
          &.prev {
            left: -100%;
            transition: 0.6s all linear;
          }
          @media (max-width: 400px) {
            img {
              display: block;
              width: 100%;
            }
          }
        }
      }
    }
    .landing-review-slider-interface {
      width: 100%;
      @media (min-width: 1300px) {
        width: 1000px;
        margin-left: -40%;
      }
      @media (width: 926px) and (height: 428px) {
        width: 100%;
        margin-top: 5%;
      }
      @media (width: 736px) and (height: 414px) {
        width: 100%;
        margin-top: -10%;
        // margin-left: -50%;
        margin-bottom: -10%;
      }
    }
  }
}
.landing-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
iframe {
  @media (max-width: 1000px) {
    height: 275px;
  }
  @media (max-height: 430px) {
    height: 450px;
  }
}
Footer {
  @media (min-width: 1200px) {
    display: inline;
  }
}
