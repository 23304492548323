@import '../../assets/scss/colors';

.wallet-pages {
    background: $backdropGradient;
    background-image: url(../../assets/images/gradient.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top center;
    min-height: 100vh;

    >.inner {
        margin: 0 auto;
        width: 100%;
        max-width: 864px;
        padding: 32px;
        min-height: 60vh;

        h1 {
            font-size: 24px;
            line-height: 32px;
            font-family: "DINPro Bold";
            font-weight: 400;
            color: $secondaryTextColor;
            text-align: center;
            margin: 0 0 36px;
        }

        .wallet-tabs-container {
            .tabs-heads {
                border-bottom: 1px solid $scaleColor900;
                margin-bottom: 24px;

                button {
                    background: transparent;
                    padding: 0 0 16px 0;
                    margin-right: 40px;
                    color: $secondaryTextColor;
                    cursor: pointer;
                    border-bottom: 2px solid $scaleColor1000;

                    &.active {
                        border-bottom: 2px solid $primaryColor;
                        color: $secondaryTextColor;
                    }

                    @media (max-width: 750px) {
                        font-size: 13px;
                        margin-right: 10px;
                    }
                }
            }

            .tabs-body {
                .tab-add-funds {
                    background: $scaleColor900;
                    padding: 12px 24px 16px;
                    border-radius: 12px;
                    display: none;
                    text-align: center;
                    font-weight: 700;

                    &.active {
                        display: block;
                    }

                    .wallet-title {
                        font-size: 42px;
                        font-weight: 700;
                        color: white;
                    }

                    .promoCodeInput {
                        margin: auto;
                        margin-top: 15px;
                        display: block;
                        text-align: center;

                        @media(max-width:900px) {
                            width: 80%;
                        }
                    }

                    .highlight-text-recommended {
                        color: #00bf61;
                        margin-bottom: -23px;
                        margin-left: -315px;
                        font-size: 16px;

                        @media (max-width: 900px) {
                            position: relative;
                            bottom: -30px;
                            left: 253px;
                        }

                        @media (max-width: 450px) {
                            position: relative;
                            bottom: -30px;
                            left: 235px;
                        }
                    }

                    .highlight-text-best-offer {
                        color: #00bf61;
                        margin-bottom: -20px;
                        margin-left: 300px;
                        font-size: 16px;

                        @media (max-width: 900px) {
                            position: relative;
                            bottom: -210px;
                            left: -60px;
                            white-space: nowrap;
                        }

                        @media (max-width: 450px) {
                            position: relative;
                            bottom: -210px;
                            left: -113px;
                            white-space: nowrap;
                        }
                    }

                    .highlight-text-bonus-1 {
                        color: #00bf61;
                        margin-left: -325px;
                        font-size: 14;

                        @media (max-width: 900px) {
                            position: relative;
                            bottom: 310px;
                            left: 250px;
                            white-space: nowrap;
                        }

                        @media (max-width: 450px) {
                            position: relative;
                            bottom: 310px;
                            left: 235px;
                            white-space: nowrap;
                        }
                    }

                    .highlight-text-bonus-2 {
                        color: #00bf61;
                        margin-left: 300px;
                        margin-top: -53px;
                        font-size: 14;

                        @media (max-width: 900px) {
                            position: relative;
                            bottom: 125px;
                            left: -60px;
                            white-space: nowrap;
                        }

                        @media (max-width: 450px) {
                            position: relative;
                            bottom: 130px;
                            left: -115px;
                            white-space: nowrap;
                        }
                    }

                    .deposit-options {
                        display: flex;
                        justify-content: center;
                        margin: auto;
                        margin-top: 20px;

                        @media (max-width: 900px) {
                            display: grid;
                            grid-template-columns: 45% 45%;
                            grid-template-rows: 180px 140px 120px;
                            text-align: center;
                            column-gap: 5%;
                        }

                        .highlight-options {
                            justify-content: center;
                            margin: auto;
                            font-size: 32px;
                            min-width: 140px;
                            height: 100px;

                            @media (max-width: 900px) {
                                min-width: 120px;
                                margin: 0px 0px 20px 0px;
                            }
                        }


                        span {
                            margin: 0px 7px;
                            background: black;
                            font-size: 32px;
                            min-width: 140px;
                            height: 100px;
                            line-height: 100px;
                            cursor: pointer;
                            border-width: 10px;
                            border-style: none;
                            color: white;

                            @media (max-width: 900px) {
                                min-width: 120px;
                                margin: 30px 0px 20px 0px;
                            }

                            input {
                                width: 98px;
                                margin: auto;
                                color: black;
                                text-align: center;
                                font-size: 15px;
                                margin-bottom: 20px;
                                line-height: 10px;
                                vertical-align: middle;

                                @media (max-width: 900px) {
                                    min-width: 60%;
                                    display: block;

                                }
                            }

                            &.highlight {
                                border-width: 5px;
                                border-style: solid;
                                border-color: #00bf61;
                                line-height: 93px;
                            }
                        }

                        #other {
                            padding-top: 2px;
                            border-width: 6px;
                            line-height: 50px;
                            font-size: 24px;
                            max-width: 160px;

                            @media (max-width: 900px) {
                                min-width: 100%;
                                grid-column: auto / span 2;
                            }
                        }

                    }

                    .continuePayment {
                        margin-top: 20px;
                    }

                    p {
                        color: white;
                        font-size: 12px;
                        font-style: italic;
                    }

                    label {
                        font-size: 18px;
                        line-height: 24px;
                        font-weight: 400;
                        font-family: "DINPro Bold";
                        margin: 0 0 6px;
                        display: block;
                    }

                    >div {
                        border: 1px solid $scaleColor800;
                        overflow: hidden;
                        margin: 0 0 16px;



                    }

                    button {

                        width: 120px;
                        height: 30px;
                        border-radius: 8px;
                        line-height: 30px;
                        background: $primaryColor;
                        color: #fff;
                        font-size: 12px;
                        font-weight: 600;
                        padding: 0 12px;
                        cursor: pointer;
                        text-transform: uppercase;

                        &:hover {
                            background: #fff;
                            color: $primaryColor;
                        }
                    }
                }

                .withdraw-container {
                    @media (max-width: 900px) {
                        display: grid;
                        grid-template-columns: 90vw;
                        grid-template-rows: auto;
                        width: 90vw;
                    }

                    #row1 {
                        @media (min-width: 900px) {
                            width: 60%;
                            float: left;
                            margin-left: -100px;

                        }

                        @media (max-width: 900px) {
                            grid-column: 1;
                            grid-row: 1;
                            text-align: center;
                            float: right;
                            margin-bottom: 20px;
                        }

                    }

                    #row2 {
                        @media (min-width: 900px) {
                            background: transparent;
                            float: left;
                            padding-left: 50px;
                            padding-top: 10%;
                        }

                        @media (max-width: 900px) {
                            background: transparent;
                            grid-row: 2;
                            grid-column: 1;
                            vertical-align: middle;
                        }
                    }

                    #row3 {
                        #prizeSubmit {
                            @media (min-width: 900px) {
                                margin-left: 30%;
                            }
                        }

                        @media (min-width: 900px) {
                            margin-left: 65%;
                            width: 50%;
                            height: 50%;

                        }

                        @media (max-width: 900px) {
                            grid-row: 3;
                            grid-column: 1;
                            text-align: center;
                        }
                    }
                }

                .tab-withdraw-funds {
                    background: $scaleColor900;
                    padding: 12px 24px 16px;
                    border-radius: 12px;
                    display: none;

                    &.active {
                        display: block;
                    }

                    >div {
                        border: 1px solid $scaleColor800;
                        overflow: hidden;
                        margin: 0 0 16px;

                        >span {
                            float: left;
                            border-right: 1px solid $scaleColor800;
                            width: 30px;
                            height: 35px;
                            line-height: 35px;
                            text-align: center;
                            color: #fff;

                            &.email {
                                background-image: url(../../assets/images/email.png);
                                background-position: center;
                                background-repeat: no-repeat;
                            }

                            &.user {
                                background-image: url(../../assets/images/profile.png);
                                background-position: center;
                                background-repeat: no-repeat;
                            }
                        }

                        input {
                            border: none;
                            background: transparent;
                            height: 35px;
                            width: calc(100% - 30px);
                            padding: 0 10px;
                            color: #fff;
                        }

                        input::placeholder {
                            opacity: 1;
                            color: #fff;
                        }
                    }

                    button {
                        width: 120px;
                        height: 30px;
                        border-radius: 8px;
                        line-height: 30px;
                        background: $primaryColor;
                        color: #fff;
                        font-size: 12px;
                        font-weight: 600;
                        padding: 0 12px;
                        cursor: pointer;
                        text-transform: uppercase;

                        &:hover {
                            background: #fff;
                            color: $primaryColor;
                        }
                    }
                }

                .tab-withdraw-history {
                    background: $scaleColor900;
                    padding: 24px;
                    border-radius: 12px;
                    display: none;

                    &.active {
                        display: block;
                    }

                    table {
                        width: 100%;
                        text-align: center;

                        th {
                            background: $scaleColor800;
                            color: #fff;
                            padding: 12px 8px;
                        }

                        td {
                            background: $scaleColor800;
                            color: #fff;
                            padding: 8px;
                            font-size: 14px;

                            button {
                                border: 1px solid #fff;
                                background: transparent;
                                border-radius: 8px;
                                padding: 5px 10px;
                                margin-left: 20px;
                                color: #fff;
                                cursor: pointer;

                                @media (max-width: 640px) {
                                    margin: 10px 0 0;
                                }

                                &:hover {
                                    background: $primaryColor;
                                    border: 1px solid $primaryColor;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .cancel-withdraw-popup {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 100;
        background: rgba(0, 34, 43, 0.6);
        display: none;
        align-items: center;
        text-align: center;

        &.show {
            display: flex;
        }

        .inner {
            margin: 0 auto;
            background: $scaleColor800;
            border-radius: 12px;
            border: 1px solid $scaleColor700;
            position: relative;
            width: 100%;
            max-width: 360px;

            h2 {
                margin: 0 0 20px;
                border-bottom: 1px solid $scaleColor700;
                font-size: 18px;
                font-weight: 400;
                color: #fff;
                padding: 20px 24px;
                text-align: left;
            }

            .close-invite-friend-modal {
                position: absolute;
                top: 20px;
                right: 24px;
                height: 18px;
                width: 18px;
                background: transparent;
                background-image: url(../../assets/images/svg/close.svg);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                cursor: pointer;
            }

            >div {
                height: 54px;
                text-align: left;
                padding: 0 24px 24px;

                input {
                    border: none;
                    border-radius: 8px;
                    height: 30px;
                    padding: 0 10px;
                    background: $scaleColor700;
                    float: left;
                    width: 65%;
                    color: #fff;
                    font-size: 13px;
                }

                input::placeholder {
                    opacity: 1;
                    color: #fff;
                }

                button {
                    float: right;
                    width: 30%;
                    height: 30px;
                    border-radius: 8px;
                    background: $primaryColor;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 600;
                    display: inline;
                    padding: 0 12px;
                    cursor: pointer;
                    text-transform: uppercase;

                    &:first-child {
                        float: left;
                        background: transparent;
                        border: 1px solid $scaleColor700;
                    }

                    &:hover {
                        background: #fff;
                        color: $primaryColor;
                    }
                }

            }
        }
    }

    .payment-popup {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 100;
        background: rgba(0, 34, 43, 0.6);
        display: none;
        align-items: center;

        &.show {
            display: flex;
        }

        .inner {
            margin: 0 auto;
            background: $scaleColor800;
            border-radius: 12px;
            border: 1px solid $scaleColor700;
            position: relative;
            width: 100%;
            max-width: 1000px;

            // button[data-pmname="PayPal"] {
            //     display: none;
            // }

            // button[data-pmname="Discover Card"] {
    //     // display: none;
    // }

            h2 {
                margin: 0 0 20px;
                border-bottom: 1px solid $scaleColor700;
                font-size: 18px;
                font-weight: 700;
                color: #fff;
                padding: 20px 24px;
                text-align: left;
            }

            .close-payment-modal {
                position: absolute;
                top: 20px;
                right: 24px;
                height: 18px;
                width: 18px;
                background: transparent;
                background-image: url(../../assets/images/svg/close.svg);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                cursor: pointer;
            }

            .script-body {
                position: relative;
                padding: 0 26px;

                iframe {
                    width: 100%;
                    display: block;
                    height: 500px;
                }

                >div {
                    min-height: 0 !important;
                }

                h5 {
                    text-align: left;
                    color: #fff;
                }

                .panel-heading {
                    h5 {
                        color: $scaleColor1000
                    }
                }

                i {
                    color: $scaleColor800;
                }

                label.control-label {
                    color: $scaleColor1000
                }

                input {
                    padding: 0 12px;
                }

            }
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type=number] {
    -moz-appearance: textfield;
}