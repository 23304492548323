@import "../../assets/scss/colors";

.username-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(0, 34, 43, 0.6);
  display: none;
  align-items: center;
  text-align: center;
  @media (max-width: 1200px) {
    padding: 0 12px;
  }
  &.show {
    display: flex;
  }
  .inner {
    margin: 0 auto;
    background: $scaleColor800;
    border-radius: 12px;
    border: 1px solid $scaleColor700;
    position: relative;
    h2 {
      margin: 0 0 20px;
      border-bottom: 1px solid $scaleColor700;
      font-size: 18px;
      font-weight: 700;
      color: #fff;
      padding: 20px 24px;
      text-align: center;
    }
    .close-username-modal {
      position: absolute;
      top: 20px;
      right: 15px;
      height: 18px;
      width: 18px;
      background: transparent;
      background-image: url(../../assets/images/svg/close.svg);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
    }
    > div {
      overflow: hidden;
      text-align: left;
      padding: 0 24px 24px;
      > div {
        float: left;
        width: 33.3333%;
        padding: 0 12px;
        label {
          display: block;
          font-size: 12px;
          font-weight: 400;
          color: $scaleColor200;
          white-space: nowrap;
          line-height: 20px;
        }
        span {
          display: block;
          color: #fff;
          font-size: 18px;
          font-weight: 600;
          line-height: 28px;
        }
        input {
          text-align: center;
          background: transparent;
          width: 60px;
          color: #fff;
          font-size: 18px;
          font-weight: 600;
          -webkit-appearance: none;
          border: 1px solid #fff;
          padding: 1px 2px;
        }
      }
    }
    .submit-username-now {
      height: 40px;
      border-radius: 12px;
      line-height: 40px;
      background: $primaryColor;
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      padding: 0 40px;
      margin-bottom: 24px;
      text-transform: uppercase;
      cursor: pointer;
      &:hover {
        background: #fff;
        color: $primaryColor;
      }
    }
  }
}
