@import '../../assets/scss/colors';
.tab-container.my-contest-entrants {
    .actions-space {
        display: block;
        height: 34px;
    }
    .actions {
        overflow: hidden;
        border-bottom: 1px solid $secondaryTextColor;
        margin: 0 0 24px;
        @media (max-width: 640px) {
            border: none;
            margin: 0 0 10px;
        }
        .left {
            float: left;
            @media (max-width: 640px) {
                float: none;
            }
        }
        .right {
            float: right;
            @media (max-width: 640px) {
                float: none;
            }
        }
        > button {
            background: transparent;
            padding: 18px 0 18px 26px;
            color: $secondaryTextColor;
            background-position: left center;
            background-repeat: no-repeat;
            cursor: pointer;
            white-space: nowrap;
            float: right;
            border-bottom: 2px solid $scaleColor1000;
            padding-left: 26px;
            margin-right: 14px;
            background-image: url(../../assets/images/svg/export.svg);
            background-position: left center;
            &.active {
                border-bottom: 2px solid $primaryColor;
                color: $secondaryTextColor;
            }
        }
    }
    .contest-entrants-listing {
        margin: 0 0 16px;
        .head {
            height: 36px;
            border-radius: 8px;
            background: $scaleColor900;
            padding: 0 16px;
            margin: 0 0 8px;
            span {
                width: 25%;
                float: left;
                line-height: 36px;
                text-transform: uppercase;
                color: #fff;
                font-size: 12px;
                font-weight: 400;
                @media (max-width: 640px) {
                    font-size: 11px;
                }
            }
            .ranking {
                width: 150px;
            }
            .user {
                width: calc(100% - 450px);
            }
            .user-points {
                width: 150px;
            }
            .prize {
                width: 150px;
                text-align: right;
            }
            @media (max-width: 640px) {
                .ranking {
                    width: 25%;
                    white-space: nowrap;
                    text-align: center;
                }
                .user {
                    width: 35%;
                    white-space: nowrap;
                }
                .user-points {
                    width: 20%;
                    white-space: nowrap;
                    text-align: center;
                }
                .prize {
                    width: 20%;
                    white-space: nowrap;
                    text-align: right;
                }
            }
        }
        .listing {
            margin: 0;
            padding: 0;
            display: block;
            li {
                display: block;
                margin: 0 0 8px;
                background: $scaleColor900;
                padding: 16px;
                border-radius: 12px;
                overflow: hidden;
                position: relative;
                cursor: pointer;
                &:hover {
                    background: $primaryColor;
                }

                .ranking {
                    float: left;
                    width: 150px;
                    font-size: 16px;
                    line-height: 17px;
                    font-weight: 400;
                    color: #FFF;
                    padding-top: 12px;
                }
                .user {
                    float: left;
                    width: calc(100% - 450px);
                    img {
                        max-height: 40px;
                        max-width: 40px;
                        border-radius: 8px;
                        float: left;
                        margin-right: 24px;
                        @media (max-width: 640px) {
                            margin-right: 10px;
                        }
                    }
                    span {
                        float: left;
                        margin: 12px 0 0;
                        font-size: 16px;
                        line-height: 17px;
                        font-weight: 700;
                        color: #FFF;
                        cursor: pointer;
                        @media (max-width: 640px) {
                            font-size: 14px;
                        }
                    }
                }
                .user-points {
                    float: left;
                    width: 150px;
                    span {
                        height: 40px;
                        line-height: 40px;
                        border-radius: 8px;
                        background: $scaleColor800;
                        display: block;
                        width: 70px;
                        text-align: center;
                        font-size: 14px;
                        font-weight: 600;
                        color: #fff;
                        @media (max-width: 640px) {
                            width: calc(100% - 5px);
                        }

                    }
                }
                .prize {
                    width: 150px;
                    float: left;
                    overflow: hidden;
                    .winningPrize {
                        float: right;
                        width: 100px;
                        height: 40px;
                        line-height: 40px;
                        font-size: 16px;
                        font-weight: 700;
                        color: #fff;
                        background: $successColor;
                        text-align: center;
                        border-radius: 8px;
                        @media (max-width: 640px) {
                            width: calc(100% - 5px);
                            margin-left: 5px;
                            font-size: 14px;
                        }
                    }
                    .notWinningPrize {
                        float: right;
                        width: 100px;
                        height: 40px;
                        line-height: 40px;
                        font-size: 16px;
                        font-weight: 700;
                        color: #fff;
                        background: $primaryColor;
                        text-align: center;
                        border-radius: 8px;
                        @media (max-width: 640px) {
                            width: calc(100% - 5px);
                            margin-left: 5px;
                            font-size: 14px;
                        }
                    }
                }
                &.user {
                    background: $scaleColor900;
                    cursor: initial;
                    .user {
                        > span {
                            margin: 0;
                            cursor: initial;
                            span.current {
                                margin: 3px 0 0;
                                height: 20px;
                                line-height: 20px;
                                border-radius: 4px;
                                background: #547094;
                                display: block;
                                width: 59px;
                                text-align: center;
                                font-size: 10px;
                                font-weight: 600;
                                text-transform: uppercase;
                            }
                        }
                    }

                }
                @media (max-width: 640px) {
                    .ranking {
                        width: 25%;
                        text-align: center;

                    }
                    .user {
                        width: 35%;
                    }
                    .user-points {
                        width: 20%;
                    }
                    .prize {
                        width: 20%;
                    }
                }
            }
        }
        &.live {
            .head {
                .ranking {
                    width: 120px;
                }
                .user {
                    width: calc(100% - 480px);
                }
                .maximum-points {
                    width: 120px;
                }
                .user-points {
                    width: 120px;
                }
                .prize {
                    width: 120px;
                    text-align: right;
                }
                @media (max-width: 640px) {
                    .user {
                        width: calc(100% - 300px);
                    }
                    .maximum-points {
                     display: none;
                    }
                    .ranking {
                        width: 100px;
                    }
                    .user-points {
                        width: 100px;
                    }
                    .prize {
                        width: 100px;
                        text-align: right;
                    }

                }
            }
            .listing {
                li {
                    .ranking {
                        width: 120px;
                    }
                    .user {
                        width: calc(100% - 480px);
                    }
                    .maximum-points {
                        width: 120px;
                        float: left;
                        span {
                            height: 40px;
                            line-height: 40px;
                            border-radius: 8px;
                            background: $scaleColor800;
                            display: block;
                            width: 70px;
                            text-align: center;
                            font-size: 14px;
                            font-weight: 600;
                            color: #fff;

                        }
                    }
                    .user-points {
                        width: 120px;
                    }
                    .prize {
                        width: 120px;
                        text-align: right;
                    }
                    @media (max-width: 640px) {
                        .user {
                            width: calc(100% - 300px);
                        }
                        .maximum-points {
                            display: none;
                        }
                        .ranking {
                            width: 100px;
                        }
                        .user-points {
                            width: 100px;
                        }
                        .prize {
                            width: 100px;
                            text-align: right;
                        }

                    }
                }
            }
        }
    }

}

