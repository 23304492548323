@import "../../../../assets/scss/colors.scss";

h1 {
  font-size: 50px;
  line-height: 58px;
  text-align: center;
  letter-spacing: -0.03em;
  font-weight: 400;
  font-family: "DINPro Bold";
  color: #fff;
  margin: 0;
  @media (max-width: 1250px) {
    font-size: 44px;
    margin: 0 auto;
    max-width: 350px;
  }
  @media (max-width: 1050px) {
    max-width: 800px;
    margin: 0;
    font-size: 48px;
  }
  @media (max-width: 500px) {
    font-size: 46px;
    line-height: 50px;
  }
}

.landing-section-1-promo {
  min-height: 100vh;
  min-width: 100%;

  display: flex;

  // background: url("../../../../assets/images/bg1.jpg") no-repeat;
  // background: $secondaryColor;
  background-size: cover;
  background-position: top -20px center;

  box-sizing: border-box;
  padding: 120px 50px 0 50px;
  @media (max-width: 1050px) {
    flex-direction: column-reverse;
    min-height: 1000px;
    padding-bottom: 100px;
  }
  @media (max-width: 600px) {
    justify-content: flex-end;
    padding: 120px 10px 0 10px;
  }
  @media (max-width: 600px) and (max-height: 800px) {
    justify-content: flex-end;
    padding: 120px 10px 0 10px;
    min-height: 800px;
  }
  @media (max-height: 600px) {
    justify-content: flex-end;
    padding: 120px 10px 0 10px;
  }

  .promo-left {
    width: 60%;
    min-width: 60%;
    max-width: 60%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 1400px) {
      width: 50%;
      min-width: 50%;
      max-width: 50%;
    }

    @media (max-width: 1050px) {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }

    .templateA-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .templateA-banner-cta {
        height: 120px;
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin-bottom: 30px;

        @media (max-width: 1050px) {
          height: auto;
        }
        @media (max-width: 600px) {
          margin-bottom: 10px;
          max-width: 370px;
        }
        p {
          color: $primaryColor;
          font-size: 30px;
          line-height: 60px;
          font-weight: 600;
          text-align: center;

          overflow-wrap: break-word;

          margin: 0;

          @media (max-width: 600px) {
            line-height: 40px;
            font-size: 28px;
          }
          @media (max-height: 600px) {
            line-height: 40px;
            font-size: 28px;
          }
        }
      }

      .templateA-banner-instructions {
        width: 90%;
        max-width: 750px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;

        p {
          color: white;
          margin: 5px 0;

          line-height: 28px;
          font-size: 18px;
          @media (max-width: 600px) {
            font-size: 17px;
            line-height: 22px;
          }
        }

        .how-to-claim {
          margin-bottom: 30px;

          font-weight: 600;
          font-size: 28px;
          @media (max-width: 1050px) {
            text-align: center;
          }
          @media (max-width: 600px) {
            font-size: 22px;
            margin-bottom: 10px;
            margin-top: 10px;
          }
        }

        .instruction-block {
          display: flex;

          p:first-of-type {
            margin-right: 6px;
            font-weight: 600;
          }
        }
      }
    }
    a {
      height: 80px;
      width: 90%;
      max-width: 750px;

      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 12px;
      border: 1px solid $scaleColor800;

      margin: 40px 0 0 0;

      background: $primaryColor;

      &:hover {
        background: transparent;
        cursor: pointer;
      }

      span {
        color: white;
        font-size: 16px;
        font-weight: 600;
        text-decoration: none;
      }

      @media (max-width: 600px) {
        margin: 20px 0 0 0;
      }
    }
    #templateA-warning {
      color: white;
      margin: 20px 0 0 0;
      font-size: 12px;
    }
    #templateA-rollover-warning {
      color: white;
      margin: 5px 0 0 0;
      font-size: 12px;
    }
  }

  .promo-right {
    width: 40%;
    min-width: 40%;
    max-width: 40%;

    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1400px) {
      width: 50%;
      min-width: 50%;
      max-width: 50%;
    }

    @media (max-width: 1050px) {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      height: 350px;

      padding-top: 50px;
      box-sizing: border-box;
    }
    @media (max-width: 600px) {
      padding-top: 0px;
      max-height: 150px;
    }
    @media (max-height: 600px) {
      padding-top: 0px;
      max-height: 150px;
    }

    .thrive-templateA {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      margin-top: 15px;

      @media (max-width: 1050px) {
        max-width: 100%;
        max-height: 100%;
        margin: 0;
      }

      .thrive-templateA-logo {
        height: 440px;
        width: 560px;
        max-width: 100%;

        background-size: contain;
        background-repeat: no-repeat;
        transition: all 0.5s;
        @media (max-width: 1050px) {
          max-width: 550px;
          max-height: 100%;
        }
        @media (max-width: 600px) {
          max-width: 140px;
          height: 110px;
        }
        @media (max-height: 600px) {
          max-width: 140px;
          height: 110px;
        }
      }
    }
  }

  .landing-section-2 {
    padding: 0 0 60px;
    clear: both;
    .inner {
      width: 100%;
      max-width: 1170px;
      padding: 0 15px;
      margin: 0 auto;
      text-align: center;
      h2 {
        font-family: "DINPro Bold";
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 52px;
        letter-spacing: -0.02em;
        color: #ffffff;
      }
      ul {
        overflow: hidden;
        display: block;
        margin: 0;
        padding: 50px 0 100px;
        @media (max-width: 765px) {
          padding-top: 0;
        }
        li {
          float: left;
          width: 33.33333%;
          display: inline;
          padding: 0 45px;
          @media (max-width: 765px) {
            float: none;
            width: 100%;
            display: block;
            margin: 0 0 20px;
          }
          div {
            min-height: 153px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            margin-bottom: 30px;
            img {
              display: block;
            }
          }
          h3 {
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 32px;
            text-align: center;
            color: #ffffff;
            margin-top: 15px;
            font-family: "DINPro Bold";
          }
          p {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
            text-align: center;
            color: $scaleColor200;
            margin-top: 15px;
          }
        }
      }
      > p {
        font-size: 18px;
        color: #fff;
        line-height: 1.5;
        font-style: italic;
      }
    }
  }
  .landing-section-3 {
    // background: url(../../../../assets/images/bg2.png) no-repeat top;
    // background: $secondaryColor;
    background-size: 100% 100%;
    padding: 50px 0 100px;
    @media (max-width: 765px) {
      padding-top: 0;
      height: auto;
    }
    @media (max-width: 564px) {
      overflow: hidden;
      padding-bottom: 100px;
    }
    .inner {
      width: 100%;
      max-width: 1170px;
      padding: 0 15px;
      margin: 0 auto;
      text-align: center;
      @media (max-width: 564px) {
        padding: 0;
      }
      h2 {
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 52px;
        align-items: center;
        letter-spacing: -0.02em;
        color: #ffffff;
        font-family: "DINPro Bold";
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        color: #ffffff;
        opacity: 0.8;
        margin: 0px 0px 15px 0px;

        @media (max-width: 564px) {
          padding: 0 15px;
        }
      }
      .btns ul {
        display: block;
        margin: 0;
        padding: 0;
        text-align: center;
        @media (max-width: 564px) {
          padding: 0 15px;
        }
        li {
          display: inline-block;
          padding-right: 5px;
          padding-left: 5px;
          @media (max-width: 564px) {
            margin-bottom: 15px;
          }
          a {
            border: 1px solid $scaleColor800;
            box-sizing: border-box;
            border-radius: 12px;
            display: flex;
            min-height: 64px;
            align-items: center;
            text-align: center;
            width: 165px;
            color: #fff;
            justify-content: center;
            &:hover {
              text-decoration: none;
              background: $primaryColor;
            }
            span {
              margin-left: 10px;
              text-align: left;
              font-style: normal;
              font-weight: bold;
              font-size: 15px;
              small {
                display: block;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                color: $scaleColor200;
              }
            }
          }
        }
      }
      .img {
        padding-top: 70px;
        @media (max-width: 564px) {
          padding-left: 30px;
        }
        img {
          display: block;
          max-width: 100%;
          margin: 0 auto;
          &.showOnMobile {
            display: none;
          }
          @media (max-width: 564px) {
            &.hideOnMobile {
              display: none;
            }
            &.showOnMobile {
              display: block;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
#how-to-play {
  margin-top: 55%;
  @media (max-height: 700px) {
    margin-top: 15%;
  }
  @media (height: 720px) {
    margin-top: 35%;
  }
  @media (min-height: 800px) {
    margin-top: 10%;
  }
  @media (min-height: 1000px) {
    margin-top: 20%;
  }
  @media (height: 1024px) {
    margin-top: 20%;
  }
  @media (height: 1024px) and (width: 1366px) {
    margin-top: -5%;
  }
  @media (max-height: 600px) {
    margin-top: 5%;
  }
  @media (max-height: 350px) {
    margin-top: 35%;
  }
  @media (max-height: 300px) {
    margin-top: 5%;
  }
  @media (height: 717px) {
    margin-top: 35%;
  }
  @media (height: 768px) {
    margin-top: 15%;
  }
}
