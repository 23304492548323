@import '../../assets/scss/colors';

.notifications-pages {
    background: $backdropGradient;
    background-image: url(../../assets/images/gradient.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top center;
    min-height: 100vh;
    > .inner {
        margin: 0 auto;
        width: 100%;
        max-width: 864px;
        padding: 32px;
        min-height: 60vh;
        h1 {
            font-size: 24px;
            line-height: 32px;
            font-family: "DINPro Bold";
            font-weight: 400;
            color: $secondaryTextColor;
            text-align: center;
            margin: 0 0 36px;
        }
        ul {
            display: block;
            margin: 0;
            li {
                display: block;
                padding: 12px 32px;
                background: $scaleColor900;
                cursor: pointer;
                margin: 0 0 12px;
                &:nth-child(even) {
                    background: $scaleColor800;
                }
                &.read {
                    opacity: 0.5;
                }
                h2 {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 26px;
                    color: #ffff;
                    margin: 0 0 5px;
                }
                p {
                    font-size: 14px;
                    line-height: 22px;
                    color: #fff;
                    padding: 0;
                    margin: 0 0 5px;
                }
                time {
                    font-size: 14px;
                    line-height: 11px;
                    color: $scaleColor200
                }
            }
            .no-results {
                text-align: center;
                color: #fff;
                background: transparent;
                > div {
                    background-image: url(../../assets/images/not-found.png);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    height: 160px;
                    width: 173px;
                    margin: 0 auto 30px;
                }
                h3 {
                    font-size: 24px;
                    font-family: 'DINPro Bold';
                    font-weight: 400;
                    line-height: 32px;
                    color: $secondaryTextColor;
                }
                span {
                    font-size: 16px;
                    font-weight: 400;
                    color: $scaleColor200;
                }
                a {
                    height: 40px;
                    width: 144px;
                    display: block;
                    border: 1px solid $scaleColor200;
                    border-radius: 12px;
                    line-height: 38px;
                    text-align: center;
                    margin: 30px auto 0;
                    font-size: 15px;
                    color: #fff;
                    &:hover {
                        background: $primaryColor;
                        border: 1px solid $primaryColor;
                    }
                }
            }
        }
        .load-more {
            height: 40px;
            line-height: 38px;
            border-radius: 12px;
            padding: 0 50px;
            color: #fff;
            font-size: 15px;
            font-weight: 600;
            text-align: center;
            margin: 30px auto;
            cursor: pointer;
            display: none;
            border: 1px solid #fff;
            background: transparent;
            &:hover {
                background: #fff;
                color: $primaryColor;
            }
            &.show {
                display: block;
            }
        }
    }
}
