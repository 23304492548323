@import '../../assets/scss/colors';
.player-stats-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    background: rgba(0, 34, 43, 0.6);
    display: none;
    align-items: center;
    text-align: center;
    @media (max-width: 600px) {
        padding: 0 12px;
    }
    &.show {
        display: flex;
    }
    .inner {
        margin: 0 auto;
        background: $scaleColor800;
        border-radius: 12px;
        border: 1px solid $scaleColor700;
        position: relative;
        width: 100%;
        max-width: 700px;
        .close-player-popup {
            position: absolute;
            top: 20px;
            right: 24px;
            height: 18px;
            width: 18px;
            background: transparent;
            background-image: url(../../assets/images/svg/close.svg);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer;
            color: transparent;
        }
        .head {
            overflow: hidden;
            padding: 24px;
            @media (max-width: 600px) {
                padding: 12px 12px 0;
            }
            img {
                float: left;
                width: 80px;
                margin-right: 24px;
                @media (max-width: 600px) {
                    width: 70px;
                }
            }
            h2 {
                margin: 6px 0 12px;
                text-align: left;
                color: #fff;
                @media (max-width: 600px) {
                    font-size: 18px;
                    margin: 0 0 5px;
                }
            }
            h3 {
                margin: 0;
                text-align: left;
                font-weight: 600;
                color: #fff;
                @media (max-width: 600px) {
                    font-size: 16px;
                    font-weight: 400;
                }
            }
        }
        .body {
            padding: 0 24px 24px;
            @media (max-width: 600px) {
                padding: 12px;
            }
            .stats {
                padding: 24px;
                text-align: left;
                background: $scaleColor700;
                border-radius: 12px;
                margin-bottom: 24px;
                @media (max-width: 460px) {
                    padding: 12px;
                    margin-bottom: 12px;
                }
                h2 {
                    font-size: 16px;
                    color: #fff;
                    margin: 0 0 16px;
                    @media (max-width: 600px) {
                        margin: 0 0 5px;
                    }
                }
                ul {
                    li {
                        display: block;
                        overflow: hidden;
                        span {
                            width: 50%;
                            float: left;
                            color: #fff;
                            font-size: 14px;
                            line-height: 20px;
                            &:last-child {
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
            .last5games {
                padding: 24px;
                text-align: left;
                background: $scaleColor700;
                border-radius: 12px;
                @media (max-width: 600px) {
                    padding: 12px;
                }
                h2 {
                    margin: 0 0 16px;
                    font-size: 16px;
                    color: #fff;
                    @media (max-width: 600px) {
                        margin: 0 0 5px;
                    }
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                    border-spacing: 0;
                    tr {
                        th {
                            font-size: 14px;
                            color: #fff;
                            border-bottom: 1px solid $scaleColor400;
                            padding: 5px;
                        }
                        td {
                            font-size: 14px;
                            color: #fff;
                            border-bottom: 1px solid $scaleColor400;
                            padding: 5px;
                        }
                    }
                }
            }
        }
    }
}
