@import '../../assets/scss/colors';
.my-contest-head-to-head-page.versus {
    background: $backdropGradient;
    background-image: url(../../assets/images/gradient.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top center;
    min-height: 100vh;
    > .inner {
        margin: 0 auto;
        width: 100%;
        max-width: 1280px;
        padding: 40px 32px;
        @media (max-width: 640px) {
            padding: 40px 24px;
        }
        &:after {
            display: block;
            clear: both;
            content: "";
        }
        .back-btn {
            font-size: 15px;
            color: $scaleColor200;
            background-image: url(../../assets/images/svg/arrow-left.svg);
            background-position: left center;
            background-repeat: no-repeat;
            padding-left: 35px;
            display: block;
            float: left;
            margin-bottom: -20px;
            @media (max-width: 760px) {
                margin-bottom: 0;
            }
        }
        h1 {
            text-align: center;
            clear: both;
            font-size: 24px;
            line-height: 32px;
            margin: 0 0 37px;
            color: #FFF;
            font-family: 'DINPro Bold';
            font-weight: 400;
            @media (max-width: 760px) {
              margin-top: 40px;
            }
        }
    }
    .head-to-head-listing {
        margin-bottom: 23px;
        .head {
            height: 36px;
            border-radius: 8px;
            background: $scaleColor900;
            padding: 0 16px;
            margin: 0 0 8px;
            @media (max-width: 640px) {
                display: none;
            }
            span {
                float: left;
                line-height: 36px;
                text-transform: uppercase;
                color: #fff;
                font-size: 12px;
                font-weight: 400;
                white-space: nowrap;

                &.player {
                    width: calc(30% - 50px);
                }
                &.vs {
                    width: 100px;
                }
                &.my-picks {
                    width: 20%;
                    padding-right: 10px;
                }
                &.opponents {
                    width: 20%;
                    padding-left: 10px;
                }
            }
        }
        ul {
            margin: 0;
            padding: 0;
            display: block;
            li {
                display: block;
                margin: 0 0 8px;
                background: $scaleColor900;
                padding: 16px;
                border-radius: 12px;
                overflow: hidden;
                position: relative;
                @media (max-width: 640px) {
                    margin: 0 0 16px;
                }
                > div {
                    float: left;
                    display: inline;
                }
                .player {
                    float: left;
                    width: calc(30% - 50px);
                    cursor: pointer;
                    overflow: hidden;
                    @media (max-width: 640px) {
                        float: none;
                        width: 100%;
                        margin-bottom: 12px;
                    }
                    > span {
                        display: block;
                        font-size: 16px;
                        line-height: 17px;
                        color: #fff;
                        margin: 0 0 12px;
                        text-transform: uppercase;
                    }
                    img {
                        max-height: 64px;
                        max-width: 64px;
                        border-radius: 8px;
                        float: left;
                        margin-right: 24px;
                    }
                    h3 {
                        margin: 0;
                        font-size: 16px;
                        line-height: 17px;
                        font-weight: 700;
                        color: #FFF;
                        cursor: pointer;
                        span {
                            margin-top: 5px;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 22px;
                            color: $scaleColor300;
                            display: block;
                        }
                    }
                    h4 {
                        margin: 0;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 22px;
                        color: $scaleColor300;
                        display: block;
                    }
                    > div {
                        border: 1px solid $scaleColor700;
                        border-radius: 8px;
                        width: 100%;
                        margin: 15px auto 0;
                        text-align: center;
                        position: relative;
                        height: 37px;
                        div {
                            height: 37px;
                            line-height: 35px;
                            font-size: 13px;
                            font-weight: 600;
                            color: #fff;
                            border-radius: 8px;
                            width: 50%;
                            margin-top: -1px;
                            margin-left: -1px;
                            float: left;
                            &.green {
                                background: $successColor;
                            }
                            &.red {
                                background: $errorColor;
                            }
                            &.blue {
                                background: $primaryColor;
                            }
                        }
                        span {
                            height: 35px;
                            line-height: 35px;
                            font-size: 13px;
                            color: #fff;
                            width: 50%;
                            margin-left: -1px;
                            float: right;
                        }
                    }
                }
                .vs {
                    float: left;
                    width: 100px;
                    text-align: center;
                    @media (max-width: 640px) {
                        float: none;
                        display: block;
                        width: 100%;
                    }
                    span {
                        height: 40px;
                        line-height: 40px;
                        border-radius: 8px;
                        background: #334e55;
                        display: block;
                        width: 70px;
                        text-align: center;
                        font-size: 14px;
                        font-weight: 600;
                        color: #fff;
                        margin-top: 45px;
                        @media (max-width: 640px) {
                            margin: 25px auto;
                        }
                    }
                }
                .my-picks {
                    width: 20%;
                    padding-right: 10px;
                    white-space: nowrap;
                    @media (max-width: 640px) {
                        float: none;
                        width: 100%;
                        margin-bottom: 12px;
                        clear: both;
                        padding: 0;
                        display: block;
                    }
                    .mobile {
                        display: none;
                        @media (max-width: 640px) {
                            display: block;
                            font-size: 14px;
                            line-height: 22px;
                            font-weight: 400;
                            color: $scaleColor300;
                            margin: 0 0 5px;
                            float: left;
                            width: 50%;
                        }
                    }
                    h4 {

                        font-size: 14px;
                        line-height: 22px;
                        font-weight: 400;
                        color: #fff;
                        @media (max-width: 640px) {
                            float: left;
                            width: 50%;
                            margin: 0 0 5px;
                        }
                    }
                    > div {
                        clear: both;
                        margin-top: 14px;
                        height: 35px;
                        line-height: 33px;
                        border: 1px solid $scaleColor700;
                        border-radius: 8px;
                        font-size: 13px;
                        color: #fff;
                        @media (max-width: 1000px) {
                            height: 70px;
                        }
                        @media (max-width: 640px) {
                            margin: 0;
                        }
                        span {
                            width: 50%;
                            height: 35px;
                            line-height: 35px;
                            font-size: 13px;
                            font-weight: 600;
                            color: #fff;
                            text-align: center;
                            float: left;
                            margin-top: -1px;
                            margin-left: -1px;
                            border-radius: 8px;
                            &.green {
                                background: $successColor;
                            }
                            &.red {
                                background: $errorColor;
                            }
                            @media (max-width: 1000px) {
                                width: 100%;
                                float: none;
                                display: block;
                                text-align: center;
                            }
                        }
                        > div {
                            float: left;
                            width: 50%;
                            text-align: center;
                            @media (max-width: 1000px) {
                                width: 100%;
                                float: none;
                                display: block;
                                text-align: center;
                            }
                        }
                    }
                }
                .opponents {
                    width: 20%;
                    padding-left: 10px;
                    white-space: nowrap;
                    @media (max-width: 640px) {
                        float: none;
                        width: 100%;
                        margin-bottom: 12px;
                        clear: both;
                        padding: 0;
                        display: block;
                    }
                    .mobile {
                        display: none;
                        @media (max-width: 640px) {
                            display: block;
                            font-size: 14px;
                            line-height: 22px;
                            font-weight: 400;
                            color: $scaleColor300;
                            margin: 0 0 5px;
                            float: left;
                            width: 50%;
                        }
                    }
                    h4 {
                        font-size: 14px;
                        line-height: 22px;
                        font-weight: 400;
                        color: #fff;
                        @media (max-width: 640px) {
                            float: left;
                            width: 50%;
                            margin: 0 0 5px;
                        }
                    }
                    > div {
                        margin-top: 14px;
                        height: 35px;
                        line-height: 33px;
                        border: 1px solid $scaleColor700;
                        border-radius: 8px;
                        font-size: 13px;
                        color: #fff;
                        @media (max-width: 1000px) {
                            height: 70px;
                        }
                        @media (max-width: 640px) {
                            margin: 0;
                            clear: both;
                        }
                        span {
                            width: 50%;
                            height: 35px;
                            line-height: 35px;
                            font-size: 13px;
                            font-weight: 600;
                            color: #fff;
                            text-align: center;
                            float: left;
                            margin-top: -1px;
                            margin-left: -1px;
                            border-radius: 8px;
                            &.green {
                                background: $successColor;
                            }
                            &.red {
                                background: $errorColor;
                            }
                            @media (max-width: 1000px) {
                                width: 100%;
                                float: none;
                                display: block;
                                text-align: center;
                            }
                        }
                        > div {
                            float: left;
                            width: 50%;
                            text-align: center;
                            @media (max-width: 1000px) {
                                width: 100%;
                                float: none;
                                display: block;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }
    .totals {
        border-top: 1px solid $scaleColor900;
        text-align: center;
        padding-top: 25px;
        > div {
            display: inline-block;
            font-size: 16px;
            line-height: 36px;
            height: 36px;
            color: #fff;
            font-weight: 700;
            text-align: left;
            padding-left: 24px;
            &:first-child {
                width: 180px;
                font-weight: 400;
                @media (max-width: 640px) {
                    width: 150px;
                }
            }
            &:nth-child(2),
            &:nth-child(3) {
                width: 120px;
                border-left: 1px solid $scaleColor900;
                @media (max-width: 640px) {
                    width: 80px;
                }
            }
        }
    }
}
