@import '../../assets/scss/colors';

.Login-page {
    height: 100vh;
    position: relative;
    background: #fff;
    @media (max-width: 820px) {
        height: auto;
    }
    .left {
        float: left;
        width: 50%;
        display: inline;
        height: 100vh;
        // background-image: url(../../assets/images/loginbg2.jpg);
        background: $gradientColor900;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @media (max-width: 820px) {
            background-position: top center;
            float: none;
            display: block;
            width: 100%;
            height: auto;
            padding: 24px 24px 60px 24px;
            background: $scaleColor900;
            // margin-bottom: 30px;
        }
        @media (max-width: 465px) {
            margin-bottom: 0;
            // background-image: url(../../assets/images/svg/login-head.svg);
            background-size: 100% auto;
            padding-bottom: 90px;
        }
        a {
            display: block;
            width: 146px;
            margin: 38px 0 0 56px;
            img {
                width: 100%;
            }

            @media (max-width: 820px) {
                width: 120px;
                margin: 0;
                img {
                    width: 120px;
                    display: block;
                }
            }
        }
        h1 {
            font-size: 40px;
            line-height: 52px;
            font-weight: 400;
            color: #fff;
            margin: 130px 100px 0 56px;
            @media (max-width: 820px) {
                display: none;
            }
        }

    }
    .right {
        float: left;
        width: 50%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background: $backdropGradient;
        @media (max-width: 820px) {
            float: none;
            display: block;
            width: 100%;
            height: 90vh;
            padding: 0 24px 60px;
            padding-top: 30px;
        }
        h1 {
            font-weight: 400;
            font-size: 34px;
            margin: 0 0 40px;
            color: $scaleColor1000;
            font-family: 'DINPro Bold';
        }
        ul {
            display: block;
            @media (max-width: 820px) {
                width: 100%;
                max-width: 600px;
                margin: 0 auto;
            }
            li {
                display: block;
                margin: 0 0 24px;
                position: relative;
                .field-input {
                    border: 1px solid $scaleColor900;
                    border-radius: 12px;
                    display: block;
                    width: 352px;
                    height: 56px;
                    padding: 0 48px 0 18px;
                    font-size: 16px;
                    color: $scaleColor1000;
                    @media (max-width: 820px) {
                        width: 100%;
                    }
                }
                .field-input[name="email"] {
                    background-image: url(../../assets/images/email.png);
                    background-repeat: no-repeat;
                    background-position: center right 21px;
                }
                .field-input[name="password"] {
                    background-image: url(../../assets/images/password.png);
                    background-repeat: no-repeat;
                    background-position: center right 21px;
                }
                .field-input[name="username"] {
                    background-image: url(../../assets/images/profile.png);
                    background-repeat: no-repeat;
                    background-position: center right 21px;
                }
                .field-input[name="promotionalCode"] {
                    background-image: url(../../assets/images/promo-code.png);
                    background-repeat: no-repeat;
                    background-position: center right 21px;
                }
                input[type="submit"] {
                    border: 1px solid $primaryColor;
                    background: $primaryColor;
                    border-radius: 12px;
                    display: block;
                    width: 352px;
                    height: 56px;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 700;
                    color: #fff;
                    cursor: pointer;
                    transition: 0.2s all linear;
                    @media (max-width: 820px) {
                        width: 100%;
                    }
                    &:hover {
                        background: $scaleColor900;
                        color: $scaleColor1000;
                        border: 1px solid $scaleColor200;
                        transition: 0.2s all linear;
                    }
                }
                input:required {
                    box-shadow: none;
                }
                input.error {
                    border-color: $errorColor;
                }
                span.error {
                    color: $errorColor;
                    font-size: 12px;
                    margin-top: 5px;
                    position: absolute;
                    left: 0;
                    display: block;
                    width: 100%;
                }
            }
        }
        .forgot-password {
            font-size: 15px;
            color: $scaleColor1000;
            &:hover {
                color: $primaryColor;
            }
        }
        p {
            color: $scaleColor500;
            font-size: 15px;
            line-height: 24px;
            a {
                color: $scaleColor1000;
                &:hover {
                    color: $primaryColor;
                }
            }
            button {
                color: $scaleColor1000;
                &:hover {
                    cursor: pointer;
                    color: $primaryColor;
                }
            }
        }
        .text-nav {
            position: absolute;
            top: 40px;
            right: 56px;
            font-size: 15px;
            margin: 0;
            color: $scaleColor500;
            @media (max-height: 670px) {
                position: static;
                margin-bottom: 16px;
            }
            @media (max-width: 820px) {
                position: static;
                margin-bottom: 16px;
            }
            a {
                color: $scaleColor1000;
                font-weight: 700;
                &:hover {
                    color: $primaryColor;
                }
            }

        }
        .server-error {
            display: none;
            color: $errorColor;
        }
        .forgot-password-success {
            display: none;
        }
    }
}
