@import 'assets/scss/colors';
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 500;
  display: none;
  align-items: center;
  justify-content: center;
  text-align: center;
  &.show {
    display: flex;
  }
}
.cube-grid-spinner {
  margin: 25px auto;
  width: 40px;
  height: 40px;
}
.cube-grid-spinner div {
  float: left;
  width: 33%;
  height: 33%;
  background: $primaryColor;
  -webkit-animation: cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.cube-grid-spinner .cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.cube-grid-spinner .cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.cube-grid-spinner .cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.cube-grid-spinner .cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.cube-grid-spinner .cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.cube-grid-spinner .cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.cube-grid-spinner .cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.cube-grid-spinner .cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.cube-grid-spinner .cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
@-webkit-keyframes cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}
@keyframes cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}
.site-messages {
  width: 100%;
  margin: 0;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  min-height: 56px;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: -56px;
  opacity: 0;
  font-size: 17px;
  &.show {
    top: 0;
    opacity: 1;
  }
}
.site-messages.success {
  background: $successColor;
}
.site-messages.error {
  background: $errorColor;
}
