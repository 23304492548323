@import '../../assets/scss/colors';
.tab-container.my-contest-upcoming {
    background: none;
    .actions {
        overflow: hidden;
        border-bottom: 1px solid $scaleColor900;
        margin: 0 0 24px;
        @media (max-width: 640px) {
            border: none;
            margin: 0 0 10px;
        }
        &.clear-margin {
            margin: 0;
        }
        .left {
            float: left;
            @media (max-width: 640px) {
                float: none;
            }
        }
        .right {
            float: right;
            @media (max-width: 640px) {
                float: none;
            }
        }
        a,
        button {
            background: transparent;
            padding: 18px 0 18px 26px;
            margin-right: 40px;
            color: $secondaryTextColor;
            background-position: left center;
            background-repeat: no-repeat;
            cursor: pointer;
            white-space: nowrap;
            @media (max-width: 640px) {
                width: 50%;
                margin: 0;
                padding: 15px 26px;
                font-size: 14px;
                text-align: left;
            }
            &:hover {
                color: $primaryColor;
            }
            &.import-picks,
            &.re-enter-contest {
                background-image: url(../../assets/images/svg/import.svg);
            }
            &.export-picks,
            &.apply-similar {
                background-image: url(../../assets/images/svg/export.svg);
            }
            &.clear-selection,
            &.withdraw-contest {
                background-image: url(../../assets/images/svg/close2.svg);
            }
            &.invite-friends {
                background-image: url(../../assets/images/svg/add.svg);
            }
            &.edit-picks {
                background-image: url(../../assets/images/svg/info.svg);
            }
        }
    }
    .actions {


    }
    .upcoming-picks-listing {
        margin: 0 0 16px;
        .head {
            height: 36px;
            border-radius: 8px;
            background: $scaleColor900;
            padding: 0 16px;
            margin: 0 0 8px;
            @media (max-width: 640px) {
                display: none;
            }
            span {
                width: 50%;
                float: left;
                line-height: 36px;
                text-transform: uppercase;
                color: #fff;
                font-size: 12px;
                font-weight: 400;
                &:last-child {
                    width: 132px;
                }
                &:nth-child(2) {
                    width: calc(50% - 132px);
                }
            }
        }
        .listing {
            margin: 0;
            padding: 0;
            display: block;
            li {
                display: block;
                margin: 0 0 8px;
                background: $scaleColor900;
                padding: 16px;
                border-radius: 12px;
                overflow: hidden;
                position: relative;
                @media (max-width: 640px) {
                    margin: 0 0 16px;
                }
                .player {
                    float: left;
                    width: 50%;
                    cursor: pointer;
                    overflow: hidden;
                    @media (max-width: 640px) {
                        float: none;
                        width: 100%;
                        margin-bottom: 12px;
                    }
                    img {
                        max-height: 64px;
                        max-width: 64px;
                        border-radius: 8px;
                        float: left;
                        margin-right: 24px;
                    }
                    h3 {
                        margin:0;
                        font-size: 16px;
                        line-height: 17px;
                        font-weight: 700;
                        color: #FFF;
                        cursor: pointer;

                        span {
                            margin-top: 5px;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 22px;
                            color: $scaleColor300;
                            display: block;
                        }
                    }
                    h4 {
                        margin: 3px 0 0;
                        font-size: 12px;
                        line-height: 20px;
                        color: $scaleColor300;
                        font-weight: 400;
                    }
                }
                .props {
                    float: left;
                    width: calc(50% - 132px);
                    @media (max-width: 640px) {
                        float: none;
                        width: 100%;
                        margin-bottom: 12px;
                    }
                    h3 {
                        font-size: 14px;
                        line-height: 22px;
                        font-weight: 400;
                        color: $scaleColor300;
                        margin: 6px 0 0;
                        .prop-value {
                            font-size: 16px;
                            color: #FFF
                        }
                        @media (max-width: 640px) {
                            br {
                                display: none;
                            }
                        }
                    }

                }
                .points {
                    float: left;
                    width: 132px;
                    @media (max-width: 640px) {
                        float: none;
                        width: 100%;
                    }
                    span {
                        display: block;
                        height: 30px;
                        margin: 12px 0 0;
                        border-radius: 8px;
                        background: $scaleColor800;
                        width: 132px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #FFF;
                        text-align: center;
                        line-height: 30px;
                    }
                }
                .ice-pick {
                    position: absolute;
                    background: $primaryColor;
                    height: 18px;
                    font-weight: 600;
                    line-height: 18px;
                    font-size: 12px;
                    padding: 0 15px;
                    color: #fff;
                    top: 20px;
                    left: -20px;
                    transform: rotateZ(-45deg);
                }
            }
        }
    }
    hr {
        border: none;
        border-bottom: 1px solid $scaleColor900;
        border-top: 0;
        margin-left: -68px;
    }
    .withdraw-popup {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 100;
        background: rgba(0, 34, 43, 0.6);
        display: none;
        align-items: center;
        text-align: center;
        @media (max-width: 600px) {
            padding: 0 12px;
        }
        &.show {
            display: flex;
        }
        .inner {
            margin: 0 auto;
            background: $scaleColor800;
            border-radius: 12px;
            border: 1px solid $scaleColor700;
            position: relative;
            width: 100%;
            max-width: 360px;
            h2 {
                margin: 0 0 20px;
                border-bottom: 1px solid $scaleColor700;
                font-size: 18px;
                font-weight: 400;
                color: #fff;
                padding: 20px 24px;
                text-align: left;
            }
            .close-invite-friend-modal {
                position: absolute;
                top: 20px;
                right: 24px;
                height: 18px;
                width: 18px;
                background: transparent;
                background-image: url(../../assets/images/svg/close.svg);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                cursor: pointer;
            }
            > div {
                height: 54px;
                text-align: left;
                padding: 0 24px 24px;
                input {
                    border: none;
                    border-radius: 8px;
                    height: 30px;
                    padding: 0 10px;
                    background: $scaleColor700;
                    float: left;
                    width: 65%;
                    color: #fff;
                    font-size: 13px;
                }
                button {
                    float: right;
                    width: 30%;
                    height: 30px;
                    border-radius: 8px;
                    background: $primaryColor;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 600;
                    display: inline;
                    padding: 0 12px;
                    cursor: pointer;
                    text-transform: uppercase;
                    &:first-child {
                        float: left;
                        background: transparent;
                        border: 1px solid $scaleColor700;
                    }
                    &:hover {
                        background: #fff;
                        color: $primaryColor;
                    }
                }

            }
        }
    }
    .apply-similar-modal {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 100;
        background: rgba(0, 34, 43, 0.6);
        display: none;
        align-items: center;
        text-align: center;
        @media (max-width: 600px) {
            padding: 0 12px;
        }
        &.show {
            display: flex;
        }
        .inner {
            margin: 0 auto;
            background: $scaleColor800;
            border-radius: 12px;
            border: 1px solid $scaleColor700;
            position: relative;
            width: 100%;
            max-width: 600px;
            h2 {
                margin: 0 0 20px;
                border-bottom: 1px solid $scaleColor700;
                font-size: 18px;
                font-weight: 700;
                color: #fff;
                padding: 20px 24px;
                text-align: left;
            }
            .close-import-picks-modal {
                position: absolute;
                top: 20px;
                right: 24px;
                height: 18px;
                width: 18px;
                background: transparent;
                background-image: url(../../assets/images/svg/close.svg);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                cursor: pointer;
            }
            > div {
                overflow: hidden;
                text-align: left;
                padding: 0 24px 24px;
                ul {
                    display: block;
                    margin: 0;
                    padding: 0;
                    max-height: 300px;
                    overflow-y: scroll;
                    li {
                        display: block;
                        margin: 0 0 8px;
                        background: $scaleColor900;
                        padding: 16px;
                        border-radius: 12px;
                        overflow: hidden;
                        position: relative;
                        > div {
                            overflow: hidden;
                            padding-right: 10px;
                            img {
                                float: left;
                                height: 50px;
                                margin-right: 16px;
                                @media (max-width: 600px) {
                                    margin-bottom: 5px;
                                }
                            }
                            h4 {
                                margin: 0 0 5px;
                                color: #fff;
                                font-size: 14px;
                                @media (max-width: 600px) {
                                    margin-top: 5px;
                                }
                            }
                            h5 {
                                margin: 0 0 5px;
                                color: $scaleColor700;
                                font-weight: 400;
                                @media (max-width: 600px) {
                                    clear: left;
                                }
                            }
                            span {
                                font-size: 14px;
                                color: $scaleColor200;
                                font-weight: 400;
                            }
                        }
                        input {
                            float: right;
                            width: 40px;
                            height: 40px;
                            border: 1px solid $scaleColor700;
                            background: transparent;
                            cursor: pointer;
                            background-position: center;
                            background-repeat: no-repeat;
                            &:checked {
                                background-image: url(../../assets/images/svg/check.svg);

                            }
                        }
                    }
                }
            }
            p {
                text-align: left;
                color: $scaleColor200;
                padding-left: 24px;
            }
            .apply-picks {
                height: 40px;
                border-radius: 12px;
                line-height: 40px;
                background: $primaryColor;
                color: #fff;
                font-size: 14px;
                font-weight: 700;
                padding: 0 40px;
                text-transform: uppercase;
                cursor: pointer;
                margin: 24px auto 0;
                display: block;
                &:hover {
                    background: #fff;
                    color: $primaryColor;
                }
            }
        }
    }
    .invite-friend-popup {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 100;
        background: rgba(0, 34, 43, 0.6);
        display: none;
        align-items: center;
        text-align: center;
        @media (max-width: 600px) {
            padding: 0 12px;
        }
        &.show {
            display: flex;
        }
        .inner {
            margin: 0 auto;
            background: $scaleColor800;
            border-radius: 12px;
            border: 1px solid $scaleColor700;
            position: relative;
            width: 100%;
            max-width: 360px;
            h2 {
                margin: 0 0 20px;
                border-bottom: 1px solid $scaleColor700;
                font-size: 18px;
                font-weight: 700;
                color: #fff;
                padding: 20px 24px;
                text-align: left;
            }
            .close-invite-friend-modal {
                position: absolute;
                top: 20px;
                right: 24px;
                height: 18px;
                width: 18px;
                background: transparent;
                background-image: url(../../assets/images/svg/close.svg);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                cursor: pointer;
            }
            > div {
                height: 54px;
                text-align: left;
                padding: 0 24px 24px;
                @media (max-width: 600px) {
                    height: auto;
                }
                input {
                    border: none;
                    border-radius: 8px;
                    height: 30px;
                    padding: 0 10px;
                    background: $scaleColor700;
                    float: left;
                    width: 65%;
                    color: #fff;
                    font-size: 13px;
                    @media (max-width: 600px) {
                        float: none;
                        width: 100%;
                        margin-bottom: 12px;
                        height: 48px;
                        border-radius: 12px;
                        font-size: 16px;
                        padding: 0 16px;
                    }
                }
                input::placeholder {
                    opacity: 1;
                    color: #fff;
                }
                button {
                    float: right;
                    width: 30%;
                    height: 30px;
                    border-radius: 8px;
                    line-height: 30px;
                    background: $primaryColor;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 600;
                    display: inline;
                    padding: 0 12px;
                    cursor: pointer;
                    text-transform: uppercase;
                    @media (max-width: 600px) {
                        float: none;
                        width: 100%;
                        height: 48px;
                        line-height: 45px;
                        border-radius: 12px;
                        font-size: 14px;
                    }
                    &:hover {
                        background: #fff;
                        color: $primaryColor;
                    }
                }
                .error {
                    padding-top: 5px;
                    clear: both;
                    font-size: 12px;
                    color: $errorColor;
                    display: none;
                }
            }
        }
    }
    .pay-modal {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 100;
        background: rgba(0, 34, 43, 0.6);
        display: none;
        align-items: center;
        text-align: center;
        @media (max-width: 600px) {
            padding: 0 12px;
        }
        &.show {
            display: flex;
        }
        .inner {
            margin: 0 auto;
            background: $scaleColor800;
            border-radius: 12px;
            border: 1px solid $scaleColor700;
            position: relative;
            h2 {
                margin: 0 0 20px;
                border-bottom: 1px solid $scaleColor700;
                font-size: 18px;
                font-weight: 700;
                color: #fff;
                padding: 20px 24px;
                text-align: left;
            }
            .close-pay-modal {
                position: absolute;
                top: 20px;
                right: 24px;
                height: 18px;
                width: 18px;
                background: transparent;
                background-image: url(../../assets/images/svg/close.svg);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                cursor: pointer;
            }
            > div {
                overflow: hidden;
                text-align: left;
                padding: 0 24px 24px;
                > div {
                    float: left;
                    width: 33.3333%;
                    padding: 0 12px;
                    label {
                        display: block;
                        font-size: 12px;
                        font-weight: 400;
                        color: $scaleColor200;
                        white-space: nowrap;
                        line-height: 20px;

                    }
                    span {
                        display: block;
                        color: #fff;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 28px;
                    }
                    input {
                        text-align: center;
                        background: transparent;
                        width: 60px;
                        color: #fff;
                        font-size: 18px;
                        font-weight: 600;
                        -webkit-appearance: none;
                        border: 1px solid #fff;
                        padding: 1px 2px;
                    }
                }
            }
            .pay-now {
                height: 40px;
                border-radius: 12px;
                line-height: 40px;
                background: $primaryColor;
                color: #fff;
                font-size: 14px;
                font-weight: 700;
                padding: 0 40px;
                margin-bottom: 24px;
                text-transform: uppercase;
                cursor: pointer;
                &:hover {
                    background: #fff;
                    color: $primaryColor;
                }
            }
        }
    }
}

