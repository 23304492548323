@import "../../assets/scss/colors";
.contest-lobby-page {
  background: $backdropGradient;
  background-image: url(../../assets/images/gradient.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;
  min-height: 100vh;
  > .inner {
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;
    padding: 32px;
    @media (max-width: 600px) {
      padding: 32px 24px;
    }
    &:after {
      display: block;
      clear: both;
      content: "";
    }
    > .filters {
      overflow: hidden;
      margin-bottom: 24px;
      button {
        float: left;
        margin-right: 8px;
        height: 40px;
        border: 1px solid $scaleColor800;
        border-radius: 8px;
        background: $scaleColor900;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        padding: 0 16px;
        cursor: pointer;
        margin-bottom: 8px;
        &:hover {
          border-color: $primaryColor;
        }
        &.active {
          border-color: $primaryColor;
        }
      }
    }
    > .entryFilters {
      overflow: hidden;
      margin-bottom: 24px;
      button {
        float: left;
        margin-left: -10px;
        margin-right: 18px;
        height: 40px;
        border: 1px solid $scaleColor800;
        border-radius: 8px;
        background: $scaleColor900;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        padding: 0 16px;
        cursor: pointer;
        margin-bottom: 8px;
        &:hover {
          border-color: $primaryColor;
        }
        &.active {
          border-color: $primaryColor;
        }
      }
    }
    > .sorters {
      overflow: hidden;
      margin-bottom: 24px;
      button {
        float: left;
        margin-right: 8px;
        height: 40px;
        border: 1px solid $scaleColor800;
        border-radius: 8px;
        background: $scaleColor900;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        padding: 0 16px;
        cursor: pointer;
        margin-bottom: 8px;
        &:hover {
          border-color: $primaryColor;
        }
        &.active {
          border-color: $primaryColor;
        }
      }
    }
    aside {
      float: left;
      width: 312px;
      padding: 64px 32px 0 0;
      @media (max-width: 940px) {
        width: 150px;
      }
      @media (max-width: 750px) {
        display: none;
      }
      h2 {
        font-size: 18px;
        font-weight: 300;
        color: $secondaryTextColor;
        font-family: "DINPro Bold";
        margin: 0 15px 24px;
      }
      ul {
        display: block;
        margin: 0 -20px;
        &:after {
          display: block;
          clear: both;
          content: "";
        }
        li {
          float: left;
          display: inline;
          width: calc(50% - 8px);
          margin: 0 4px 8px;
          height: 88px;
          background: $scaleColor900;
          border: 1px solid $scaleColor900;
          text-align: center;
          border-radius: 8px;
          font-size: 14px;
          color: #fff;
          padding-top: 35px;
          cursor: pointer;
          background-position: center;
          background-size: cover;
          @media (max-width: 940px) {
            float: none;
            display: block;
            width: 100%;
          }
          &.active {
            border: 1px solid $primaryColor;
            position: relative;
            &:before {
              width: 18px;
              height: 18px;
              display: block;
              position: absolute;
              content: "";
              background-image: url(../../assets/images/svg/check.svg);
              background-repeat: no-repeat;
              background-position: center;
              top: 8px;
              right: 8px;
            }
          }
          &:hover {
            border: 1px solid $primaryColor;
          }
          &.sports {
            padding-top: 58px;
            position: relative;
            font-size: 12px;
            &:after {
              width: 60px;
              height: 40px;
              display: block;
              content: "";
              position: absolute;
              background-position: center;
              background-repeat: no-repeat;
              top: 14px;
              left: 50%;
              margin-left: -30px;
            }
          }
          //   &.deck {
          //     background-image: url(../../assets/images/audl.png);
          //   }
          &.golf {
            background-image: url(../../assets/images/golf.png);
          }
          &.cricket {
            background-image: url(../../assets/images/cricket.png);
          }
          &.soccer {
            background-image: url(../../assets/images/soccer.png);
          }
          &.dota {
            background-image: url(../../assets/images/dota2.png);
          }
          //   &.overwatch {
          //     background-image: url(../../assets/images/overwatch.png);
          //   }

          &.lol {
            background-image: url(../../assets/images/lol.png);
          }
          &.val {
            background-image: url(../../assets/images/valorant.png);
          }
          &.ufc {
            background-image: url(../../assets/images/ufc3.png);
            font-size: 15px;
          }
          //&.ufc:after {
            //background-image: url(../../assets/images/sports/1539640882.svg);
          //}
          &.lottery {
            background-image: url(../../assets/images/lottery.png);
          }
          &.lottery:after {
            background-image: url(../../assets/images/sports/lotto.svg);
          }
          &.csgo {
            background-image: url(../../assets/images/csgo.png);
          }
          &.baseball {
            background-image: url(../../assets/images/baseball.png);
          }
          &.basketball {
            background-image: url(../../assets/images/basketball.png);
          }
          &.hockey {
            background-image: url(../../assets/images/hockey.jpg);
          }
          &.hockey:after {
            background-image: url(../../assets/images/sports/hockey-icon.svg);
            width: 80px;
            height: 53.33px;
            margin-left: -40px;
          }
          &.football {
            background-image: url(../../assets/images/nfl.png);
          }
          &.football:after {
            background-image: url(../../assets/images/sports/footbool-icon.svg);
          }
          &.basketball:after {
            background-image: url(../../assets/images/sports/basketball-icon.svg);
          }
          &.baseball:after {
            background-image: url(../../assets/images/sports/basball-icon.svg);
          }

          //   &.online:after {
          //     background-image: url(../../assets/images/sports/keyboard-icon.svg);
          //   }
          &.csgo:after {
            background-image: url(../../assets/images/sports/keyboard-icon.svg);
          }
          &.lol:after {
            background-image: url(../../assets/images/sports/keyboard-icon.svg);
          }
          &.val:after {
            background-image: url(../../assets/images/sports/keyboard-icon.svg);
          }
          &.dota:after {
            background-image: url(../../assets/images/sports/keyboard-icon.svg);
          }
          &.soccer:after {
            background-image: url(../../assets/images/sports/soccer-icon.svg);
          }
          &.golf:after {
            background-image: url(../../assets/images/sports/golf-icon.svg);
          }
          &.cricket:after {
            background-image: url(../../assets/images/sports/cricket-bat.svg);
          }
          //   &.deck:after {
          //     background-image: url(../../assets/images/sports/deck-icon.svg);
          //   }
        }
      }
    }
    .upcoming-events {
      float: left;
      width: calc(100% - 312px);
      @media (max-width: 940px) {
        width: calc(100% - 150px);
      }
      @media (max-width: 750px) {
        width: 100%;
        display: block;
        float: none;
      }
      > nav {
        overflow: hidden;
        margin-bottom: 24px;
        span {
          float: left;
          font-size: 13px;
          font-weight: 600;
          color: $secondaryTextColor;
          margin-right: 20px;
          height: 40px;
          line-height: 40px;
          @media (max-width: 600px) {
            display: none;
          }
        }
        button {
          float: left;
          margin-right: 8px;
          height: 40px;
          border: 1px solid black;
          border-radius: 8px;
          background: $scaleColor900;
          color: #fff;
          font-size: 14px;
          font-weight: 400;
          padding: 0 16px;
          cursor: pointer;
          &:hover {
            border-color: $primaryColor;
          }
          &.active {
            border-color: $primaryColor;
          }
        }
        #entryButton {
          float: left;
          margin-left: -10px;
          margin-right: 18px;
          height: 40px;
          border: 1px solid $scaleColor800;
          border-radius: 8px;
          background: $scaleColor900;
          color: #fff;
          font-size: 14px;
          font-weight: 400;
          padding: 0 16px;
          cursor: pointer;
          margin-bottom: 8px;
          &:hover {
            border-color: $primaryColor;
          }
          &.active {
            border-color: $primaryColor;
          }
          @media (max-width: 600px) {
            padding: 0 15px;
            margin-left: 2px;
            margin-right: 6px;
          }
        }
      }
      .mobile-select-sport {
        display: none;
        @media (max-width: 750px) {
          display: block;
        }
        > h2 {
          font-size: 18px;
          font-weight: 300;
          color: $secondaryTextColor;
          font-family: "DINPro Bold";
          margin: 0 0 24px;
        }
        .selected {
          display: block;
          height: 40px;
          line-height: 40px;
          padding: 0 18px;
          background: $scaleColor900;
          border-radius: 8px;
          color: #fff;
          font-size: 15px;
          position: relative;
          cursor: pointer;
          background-position: center;
          background-size: cover;
          &:after {
            background-image: url(../../assets/images/svg/Rectangle.svg);
            background-repeat: no-repeat;
            background-position: center;
            width: 8px;
            height: 8px;
            display: block;
            position: absolute;
            content: "";
            right: 18px;
            top: 50%;
            margin-top: -4px;
          }
          &.golf {
            background-image: url(../../assets/images/golf.png);
          }
          &.cricket {
            background-image: url(../../assets/images/cricket.png);
          }
          &.soccer {
            background-image: url(../../assets/images/soccer.png);
          }
          &.dota {
            background-image: url(../../assets/images/dota2.png);
          }
          &.overwatch {
            background-image: url(../../assets/images/overwatch.png);
          }
          &.lol {
            background-image: url(../../assets/images/lol.png);
          }
          &.val {
            background-image: url(../../assets/images/valorant.png);
          }
          &.csgo {
            background-image: url(../../assets/images/csgo.png);
          }
          &.baseball {
            background-image: url(../../assets/images/baseball.png);
          }
          &.basketball {
            background-image: url(../../assets/images/basketball.png);
          }
          &.football {
            background-image: url(../../assets/images/nfl.png);
          }
          &.hockey {
            background-image: url(../../assets/images/hockey.jpg);
          }
        }
        .selection {
          margin-bottom: 24px;
          overflow: hidden;
          max-height: 0;
          &.open {
            max-height: 700px;
          }
          ul {
            display: block;
            margin: 0;
            padding: 0;
            li {
              display: block;
              height: 40px;
              line-height: 38px;
              padding: 0 18px;
              background: $scaleColor900;
              border: 1px solid $scaleColor900;
              border-radius: 8px;
              color: #fff;
              font-size: 15px;
              position: relative;
              cursor: pointer;
              margin-top: 16px;
              background-position: center;
              background-size: cover;
              &.active {
                border: 1px solid $primaryColor;
                &:before {
                  width: 18px;
                  height: 18px;
                  display: block;
                  position: absolute;
                  content: "";
                  background-image: url(../../assets/images/svg/check.svg);
                  background-repeat: no-repeat;
                  background-position: center;
                  top: 50%;
                  margin-top: -9px;
                  right: 16px;
                }
              }
              &.golf {
                background-image: url(../../assets/images/golf.png);
              }
              &.cricket {
                background-image: url(../../assets/images/cricket.png);
              }
              &.soccer {
                background-image: url(../../assets/images/soccer.png);
              }
              &.dota {
                background-image: url(../../assets/images/dota2.png);
              }
              &.overwatch {
                background-image: url(../../assets/images/overwatch.png);
              }
              &.lol {
                background-image: url(../../assets/images/lol.png);
              }
              &.val {
                background-image: url(../../assets/images/valorant.png);
              }
              &.csgo {
                background-image: url(../../assets/images/csgo.png);
              }
              &.baseball {
                background-image: url(../../assets/images/baseball.png);
              }
              &.basketball {
                background-image: url(../../assets/images/basketball.png);
              }
              &.football {
                background-image: url(../../assets/images/nfl.png);
              }
              &.hockey {
                background-image: url(../../assets/images/hockey.jpg);
              }
            }
          }
        }
      }
      > h2 {
        font-size: 18px;
        font-weight: 300;
        color: $secondaryTextColor;
        font-family: "DINPro Bold";
        margin: 0 0 24px;
      }
      .upcoming-events-listing {
        ul {
          display: block;
          margin: 0;
          padding: 0;
          li {
            display: block;
            margin: 0 0 16px;
            border-radius: 12px;
            // background: $scaleColor900;
            background: $entityBlock;
            padding: 16px;
            .desktop {
              display: block;
              @media (max-width: 600px) {
                display: none;
              }
              .top {
                overflow: hidden;
                padding-bottom: 16px;
                border-bottom: 1px solid $scaleColor800;
                img {
                  float: left;
                  height: 48px;
                  border-radius: 8px;
                  margin-right: 16px;
                }
                .headings {
                  float: left;
                  h3 {
                    margin: 0 0 5px;
                    padding: 0;
                    font-size: 18px;
                    color: #fff;
                    font-family: "DINPro Bold";
                    font-weight: 300;
                    span {
                      font-family: "Open Sans";
                      height: 20px;
                      line-height: 20px;
                      font-weight: 600;
                      padding: 0 10px;
                      font-size: 11px;
                      border-radius: 4px;
                      background: $secondaryOrange;
                      margin: -4px 0 0 16px;
                      display: inline-block;
                      text-transform: uppercase;
                      &.contest-filled {
                        background: $errorColor;
                      }
                    }
                  }
                  h4 {
                    margin: 0;
                    padding: 0;
                    font-size: 14px;
                    color: #fff;
                    font-weight: 300;
                    float: left;
                  }
                  .multy-entries {
                    float: left;
                    padding: 0 20px;
                    > span {
                      padding: 3px 8px;
                      display: inline-block;
                      background: $scaleColor900;
                      border-radius: 8px;
                      font-size: 13px;
                      color: #fff;
                      font-weight: 300;
                      margin-right: 10px;
                      &:last-child {
                        background: $scaleColor800;
                      }
                    }
                  }
                }
                .info {
                  float: right;
                  display: inline;
                  overflow: hidden;
                  h3 {
                    font-size: 18px;
                    font-weight: 400;
                    color: #fff;
                    margin: 0 0 0 25px;
                    display: inline-block;
                    text-align: right;
                    text-transform: uppercase;
                    img {
                      flex: 1;
                      width: 25px;
                      height: 25px;
                      margin-right: -10px;
                    }
                    span {
                      display: block;
                      font-size: 12px;
                      font-weight: 400;
                      color: $scaleColor200;
                      margin-bottom: 5px;
                    }
                  }
                }
              }
              .bottom {
                overflow: hidden;
                padding-top: 16px;
                .left {
                  float: left;
                  display: inline;
                  p {
                    margin: 0;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;
                    color: $scaleColor200;
                  }
                }
                .right {
                  float: right;
                  display: inline;
                  .countdown {
                    overflow: hidden;
                    text-align: center;
                    float: left;
                    display: inline;
                    margin: 0 20px 0 0;
                    > div {
                      float: left;
                      display: inline;
                      padding: 0 15px;
                      font-size: 9px;
                      font-weight: 400;
                      color: #fff;
                      text-transform: uppercase;
                      border-left: 1px solid $scaleColor700;
                      &:first-child {
                        border: none;
                      }
                      span {
                        display: block;
                        font-size: 16px;
                        font-weight: 300;
                        font-family: "DINPro Bold";
                        margin: 0 0 5px;
                        color: $scaleColor100;
                      }
                    }
                  }
                  button,
                  a {
                    float: left;
                    display: inline;
                    background: $primaryColor;
                    height: 36px;
                    line-height: 36px;
                    border-radius: 12px;
                    padding: 0 12px;
                    color: #fff;
                    font-size: 15px;
                    font-weight: 400;
                    cursor: pointer;
                    &:hover {
                      background: #fff;
                      color: $primaryColor;
                    }
                  }
                }
              }
            }
            .mobile {
              display: none;
              @media (max-width: 600px) {
                display: block;
              }
              .top {
                img {
                  float: left;
                  height: 55px;
                  margin-right: 16px;
                  border-radius: 8px;
                }
                .countdown {
                  overflow: hidden;
                  text-align: center;
                  float: left;
                  display: inline;
                  width: calc(100% - 71px);
                  border: 1px solid $scaleColor800;
                  border-radius: 8px;
                  padding: 10px 0;
                  margin-bottom: 16px;
                  > div {
                    float: left;
                    display: inline;
                    width: 33.33333%;
                    padding: 0 15px;
                    font-size: 9px;
                    font-weight: 400;
                    color: #fff;
                    text-transform: uppercase;
                    border-left: 1px solid $scaleColor700;
                    &:first-child {
                      border: none;
                    }
                    span {
                      display: block;
                      font-size: 16px;
                      font-weight: 300;
                      font-family: "DINPro Bold";
                      margin: 0 0 5px;
                      color: $primaryColor;
                    }
                  }
                }
                h3 {
                  font-size: 18px;
                  line-height: 27px;
                  color: #fff;
                  font-family: "DINPro Bold";
                  font-weight: 300;
                  clear: both;
                  margin: 0 0 16px;
                  span {
                    font-family: "Open Sans";
                    height: 20px;
                    line-height: 20px;
                    font-weight: 600;
                    padding: 0 10px;
                    font-size: 11px;
                    border-radius: 4px;
                    background: $secondaryOrange;
                    margin: -4px 0 0 16px;
                    display: inline-block;
                    text-transform: uppercase;
                  }
                }
                h4 {
                  margin: 0 0 16px;
                  float: left;
                  width: 50%;
                  font-size: 18px;
                  color: #fff;
                  font-weight: 600;
                  span {
                    display: block;
                    font-size: 12px;
                    font-weight: 400;
                    color: $scaleColor200;
                    margin: 0 0 5px;
                  }
                }
                .multy-entries {
                  margin-bottom: 16px;
                  > span {
                    padding: 5px 8px;
                    display: inline-block;
                    background: $scaleColor900;
                    border-radius: 8px;
                    font-size: 13px;
                    color: #fff;
                    font-weight: 300;
                    margin-right: 10px;
                    &:last-child {
                      background: $scaleColor800;
                    }
                  }
                }
              }
              .bottom {
                clear: both;
                border-radius: 12px;
                background: $scaleColor800;
                margin: 0 -16px -16px;
                padding: 16px;
                p {
                  margin: 0;
                  height: 46px;
                  line-height: 46px;
                  border-top: 1px solid $scaleColor700;
                  font-size: 14px;
                  color: $scaleColor200;
                  &:first-child {
                    border-color: transparent;
                  }
                  span {
                    float: right;
                    font-weight: 600;
                    color: #fff;
                  }
                }
                button,
                a {
                  cursor: pointer;
                  width: 100%;
                  margin-top: 16px;
                  display: block;
                  background: $primaryColor;
                  height: 36px;
                  line-height: 36px;
                  border-radius: 12px;
                  padding: 0 12px;
                  color: #fff;
                  font-size: 15px;
                  font-weight: 600;
                  text-align: center;
                }
              }
            }
          }
          .no-results {
            text-align: center;
            color: #fff;
            background: transparent;
            > div {
              background-image: url(../../assets/images/not-found.png);
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              height: 160px;
              width: 173px;
              margin: 0 auto 30px;
            }
            h3 {
              font-size: 24px;
              font-family: "DINPro Bold";
              font-weight: 400;
              line-height: 32px;
              color: $secondaryTextColor;
            }
            span {
              font-size: 16px;
              font-weight: 400;
              color: $scaleColor200;
            }
          }
        }
        .load-more {
          height: 40px;
          line-height: 38px;
          border-radius: 12px;
          padding: 0 50px;
          color: #fff;
          font-size: 15px;
          font-weight: 600;
          text-align: center;
          margin: 30px auto;
          cursor: pointer;
          display: none;
          border: 1px solid #fff;
          background: transparent;
          &:hover {
            background: #fff;
            color: $primaryColor;
          }
          &.show {
            display: block;
          }
        }
      }
    }
  }
  .enter-password-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    background: rgba(0, 34, 43, 0.6);
    display: none;
    align-items: center;
    text-align: center;
    &.show {
      display: flex;
    }
    .inner {
      margin: 0 auto;
      background: $scaleColor800;
      border-radius: 12px;
      border: 1px solid $scaleColor700;
      position: relative;
      width: 100%;
      max-width: 360px;
      h2 {
        margin: 0 0 20px;
        border-bottom: 1px solid $scaleColor700;
        font-size: 18px;
        font-weight: 700;
        color: #fff;
        padding: 20px 24px;
        text-align: left;
      }
      .close-enter-password-modal {
        position: absolute;
        top: 20px;
        right: 24px;
        height: 18px;
        width: 18px;
        background: transparent;
        background-image: url(../../assets/images/svg/close.svg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
      }
      > div {
        height: 54px;
        text-align: left;
        padding: 0 24px 24px;
        input {
          border: none;
          border-radius: 8px;
          height: 30px;
          padding: 0 10px;
          background: $scaleColor700;
          float: left;
          width: 65%;
          color: #fff;
          font-size: 13px;
        }
        button {
          float: right;
          width: 30%;
          height: 30px;
          border-radius: 8px;
          line-height: 30px;
          background: $primaryColor;
          color: #fff;
          font-size: 12px;
          font-weight: 600;
          display: inline;
          padding: 0 12px;
          cursor: pointer;
          text-transform: uppercase;
          &:hover {
            background: #fff;
            color: $primaryColor;
          }
        }
        .error {
          padding-top: 5px;
          clear: both;
          font-size: 12px;
          color: $errorColor;
          display: none;
        }
      }
    }
  }
}
